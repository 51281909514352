<!-- User OKed with not savig data from Menu clik, which emits notifyParentForRollback -->
<app-menu *ngIf="dao?.PolicyTemplate != ''" (notifyParentForRollback)="receiveRollbackEvent($event)"></app-menu>
<div class="container-fluid" *ngIf="showBanner">
    <app-site-banner></app-site-banner>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'AccountInfo'">
    <div class="col-lg-12">
        <app-policy-holder [RollbackAccountInfoData]="notifyChidrenToRollbackPageData"></app-policy-holder>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'uw'">
    <div class="col-lg-12">
        <!-- This container emits an even to notify Chidren to Rollback Page Data, 
            chidren needs to subscribe it to Roll Back data -->
        <app-under-writer [RollbackUwData]="notifyChidrenToRollbackPageData"></app-under-writer>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'losshistory'">
    <div class="col-lg-12" *ngIf="serviceLayer.lossHistoryVersion == 1">
        <app-loss-history [RollbackLossData]="notifyChidrenToRollbackPageData"></app-loss-history>
    </div>
    <div class="col-lg-12" *ngIf="serviceLayer.lossHistoryVersion == 2">
        <app-loss-history-version-2 [RollbackLossData]="notifyChidrenToRollbackPageData"></app-loss-history-version-2>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'vehicles'">
    <div class="col-lg-12">
        <app-vehicles></app-vehicles>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'driver'">
    <div class="col-lg-12">
        <app-driver [RollbackDriverData]="notifyChidrenToRollbackPageData"></app-driver>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'product'">
    <div class="col-lg-12">
        <app-productInfo [RollbackDataBroadCast]="notifyChidrenToRollbackPageData"></app-productInfo>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == 'application'">
    <div class="col-lg-12">
        <app-appinfo [RollbackData]="notifyChidrenToRollbackPageData"></app-appinfo>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == '' && dao?.PolicySubTemplate == 'newsubmission'">
    <div class="col-lg-12">
        <app-new-submission></app-new-submission>
    </div>
</div>
<div class="row m-0" *ngIf="dao?.PolicyTemplate == '' && dao?.PolicySubTemplate == 'dot'">
    <div class="col-lg-12">
        <app-dot></app-dot>
    </div>
</div>

<p-toast position="center" key="Valitation" class="validation_toast" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
               {{message?.data}}
            </div>
            <div class="p-grid p-fluid mt-3">
                <div class="col-md-12 text-center validation_toast_btn">
                    <button type="button" pButton (click)="Ok('Valitation', message?.summary)" label="Ok" class="warnPBtnSuccess"></button>
                </div>                
            </div>
        </div>
    </ng-template>
</p-toast>
