import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { ChangeDetectionService } from '../../services/changeDetectionService';
import { DAO } from '../../modal/DAO'
import { MessageService } from 'primeng/api';
import _ from 'lodash';
import { Router } from '@angular/router';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { takeUntil } from 'rxjs/operators';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';
import { Constants } from 'src/app/modal/ProjConstants.models';

@Component({
  selector: 'app-policy-container',
  templateUrl: './policy-container.component.html',
})
export class PolicyContainerComponent extends AuthenticatedUserComponent implements OnInit {

  public showBanner:boolean = false;    
  @Output() notifyChidrenToRollbackPageData:EventEmitter<string>  = new EventEmitter<string>();
  constructor(
    private router: Router,
    public serviceLayer: ServiceLayer,
    public dao: DAO, public appConfig: AppConfig,
    private messageService: MessageService,
    protected authenticationService: AuthenticationService,
    public dataChangeService: ChangeDetectionService,
    private auth0UserService: Auth0UserService) {
        super(authenticationService);
    }

    ngOnInit(): void {
        this.auth0UserService.userLoaded$.subscribe(
          auth0User => {
            if(!!auth0User?.accessToken)
            {
              this.dao.PolicySubTemplate = 'newsubmission';
              if (!!auth0User?.agencyCode)
              {
                this.showBanner = true;
              }
            }
          });   
 
    }

  public async Ok(type, data) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
    if(data == Constants.SUBMISSION_DECLINED || data == Constants.SUBMISSION_CANCELLED){
      setTimeout(() => {
        //redirect to WIP page
        this.dao.PolicySubTemplate = 'newsubmission';
        this.dao.PolicyTemplate = '';
      });  
    }
  }

  // ===== find any change occurs in model =====
  public ngDoCheck() {    
    this.serviceLayer.restrictAutoFill();
  }

  public receiveRollbackEvent(keyword){    
    this.notifyChidrenToRollbackPageData.emit(keyword);
  }
}
