import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DAO, ProductDetails, ProductModel } from '../../modal/DAO'
import { MenuClickContext } from '../../modal/routing.models'
import { ServiceLayer } from '../../services/serviceLayer';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../../app.component';
import _ from 'lodash';
import { NgForm } from '@angular/forms';
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { ProductService } from '../../services/product.service';
import { QuestionAnswer, QuestionOption, Questionnaire, ResponseContainer } from 'src/app/modal/questionnaire.models';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StateSettings } from 'src/app/modal/state.models';
import { QuestionAnswerHelper } from 'src/app/modal/questionAnswerUtilities';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { PolicyHolderValueParser } from 'src/app/modal/PolicyHolderValueParser.models';

@Component({
  selector: 'app-productInfo',
  templateUrl: './productInfo.component.html'
})
export class ProductInfoComponent implements OnInit, OnDestroy, IBasePage {
  protected unsubscriber = new Subject();
  public showingGlTab: boolean = false;
  public questionnaire: Questionnaire;
  public umBiLimitQuestionBehaviore = new BehaviorSubject<QuestionAnswer>(null);
  public umPdPrdQuestionBehaviore = new BehaviorSubject<QuestionAnswer>(null);
  public uimBiQuestionBehaviore = new BehaviorSubject<QuestionAnswer>(null);
  accountState: string = '';
  constructor(
    public serviceLayer: ServiceLayer,
    public dao: DAO,
    private messageService: MessageService,
    public appComponent: AppComponent,
    public menuComponent: menuComponent,
    public productService: ProductService,
    public stateSettings: StateSettings) { }
    
  public deleteType: string = '';
  public isUninsuredMotoristBodilyInjuryValid: boolean = true;
  public isUnderInsuredMotoristBodilyInjuryLimitValid: boolean = true;
  public isUninsuredMotoristPropertyDamageDeductibleValid: boolean = true;
  public isAddBtnClicked: boolean = false;
  public activeTab: number = 0;
  public IsShowNFNotQualifyErrorMsg: boolean = false;
  public hideNFChild: boolean = false;
  public umReject: QuestionAnswer;
  public fireSurcharge: QuestionAnswer;
  public nfRateBasis: QuestionAnswer;
  public cargoIndicator: QuestionAnswer;
  public accountLegalEntityAnswer: string;
  public cargoPropertyLimit: QuestionAnswer;
  public cargoShipperLimit: QuestionAnswer;
  public RollbackProductDataSubscription: Subscription;
  public initialProductModel: ProductModel = new ProductModel();
  public initialProductQuestionnaireAnswers: any;

  @ViewChild('productForm') productForm: NgForm;
  @Input() RollbackDataBroadCast: EventEmitter<string>;
  deletedIndex: number;
  addedPipValidationMessage: String;
  namedIndividualValidationMessage: String;
  isNFPipLimitDisabled: boolean;

  ngOnInit(): void {
    this.loadValidationMessage();
    this.accountLegalEntityAnswer = this.productService.getAccountLegalEntityAnswer();
    this.serviceLayer.dotSubmissionDetails$.pipe(takeUntil(this.unsubscriber)).subscribe((dotSubmissionDetails) => {
      if (dotSubmissionDetails) {
        this.initializeData(dotSubmissionDetails);
      }
    });

    setTimeout(() => {
      this.serviceLayer.menuClickBehavior.pipe(takeUntil(this.unsubscriber)).subscribe((menuClickContext: MenuClickContext) => {
        if (menuClickContext == null || menuClickContext.page == "product")
          return;
        menuClickContext.currentPageIsValid = this.isPageValid();
        menuClickContext.currentPageDataChanged = this.isPageDataChanged();
        this.RollbackProductDataSubscription = this.RollbackDataBroadCast.subscribe(
          (page) => this.onRollback(page)
      )
      });
    }, 100);
  }

  get ProductModel(): ProductModel {
    return this.productService.productModel;
  }
  set ProductModel(productModel: ProductModel) {
    this.productService.productModel = productModel;
  }

  enableNFPipLimitDropDown() {
    this.accountState = this.productService.getAccountStateAnswer();
    if (this.accountState == 'TX' || this.accountState == "DE") {
      this.hideNFChild = true;
      this.isNFPipLimitDisabled = this.ProductModel.productDetails.nfPipLimits.answer == '';
    }
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.RollbackProductDataSubscription?.unsubscribe();
  }
  // ========= Open Validation Popup =====================
  public openValidPopup(errmsg: string = this.dao.toastValidation) {
    this.messageService.add({ key: 'productToastKey', severity: 'error', summary: 'Product Info', detail: errmsg });
  }
  // ======
  public AddProductLocation() {
    var tempArray = {
      'premiseStreet': ""
      , 'premiseCity': ""
      , 'premiseState': ""
      , 'premisePostalCode': ""
      , 'payrollAmount': this.ProductModel.PremiseStateDefaultPayrollAmt
      , 'premiseLocation': this.ProductModel.PremiseLocationDefaultValue
    };
    var tempObj = _.cloneDeep(this.ProductModel.AddProductAnotherLocation);
    tempObj.push(tempArray);
    this.ProductModel.AddProductAnotherLocation = _.cloneDeep(tempObj);
    this.ProductModel.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));

  }
  public AddAnotherInsured() {
    var tempArray = { 'Name': "" };
    var tempObj = _.cloneDeep(this.ProductModel.AddAnotherInsured);
    tempObj.push(tempArray);
    this.ProductModel.AddAnotherInsured = tempObj;
  }
  public AddAnotherWaiver() {
    var tempArray = { 'Name': "" };
    var tempObj = _.cloneDeep(this.ProductModel.AddAnotherWaiver);
    tempObj.push(tempArray);
    this.ProductModel.AddAnotherWaiver = tempObj;
  }
  public AddAnotherInsuredForCommercialAuto() {
    var tempArray = { 'Name': "", 'Street': '', 'City': '', 'State': '', 'PostalCode': '' };
    var tempObj = _.cloneDeep(this.ProductModel.AddAnotherInsuredForCommercialAuto);
    tempObj.push(tempArray);
    this.ProductModel.AddAnotherInsuredForCommercialAuto = tempObj;
  }
  public AddAnotherWaiverForCommercialAuto() {
    var tempArray = { 'Name': "" };
    var tempObj = _.cloneDeep(this.ProductModel.AddAnotherWaiverForCommercialAuto);
    tempObj.push(tempArray);
    this.ProductModel.AddAnotherWaiverForCommercialAuto = tempObj;
  }

  async submitForm(productForm) {
    this.serviceLayer.UpdateFiledsValuesAndValidate(productForm);
    if (productForm.valid) {
      this.saveProduct(productForm)
    } else {
      if (!this.dao.IsSavedFromMenu) {
        var errmsg;
        var glGroup = productForm.form.controls.generalLiabilityGroup;
        this.validateAndChangeTheTab(productForm);
        if (productForm.form.hasError('invalidCombinedCoverages')) {
          errmsg = productForm?.form?.errors?.errmsg;
        }
        else if (!!glGroup && glGroup.invalid) {
          errmsg = this.validateGLControls(glGroup.controls);
        }
        else {
          errmsg = this.dao.toastValidation;
        }
        this.openValidPopup(errmsg);
      } else {
        this.dao.NotAllowedToSubmit = true
      }
    }
  }
  private validateGLControls(controls: any) {
    let errmsg;
    let invalidPayrollIndexes = [];
    for (const controlName in controls) {
      const control = controls[controlName];
      if (control.hasError('required') || (control.hasError('requiredAmtOrDecline'))) {
        errmsg = this.dao.toastValidation;
        return errmsg;
      }
      else if (controlName.startsWith('payrollAmount_')) {
        if (control.hasError('minAmt')) {
          errmsg = control.errors?.errmsg;
          let index = controlName.split('_')[1];
          invalidPayrollIndexes.push(index);
        }
      }
    }
    if (invalidPayrollIndexes.length > 0)
      this.CheckPayrollAmountValidation(invalidPayrollIndexes);
    return errmsg;
  }
  private validateAndChangeTheTab(productForm: NgForm): void {
    let hasCommercialTabValid = productForm?.controls?.commercialAutoGroup?.valid;
    let hasGeneralLiabTabValid = productForm?.controls?.generalLiabilityGroup?.valid;
    let hasMotorCargoTabValid = productForm?.controls?.motorCargGroup?.valid;
    let hasCurrentTabInvalid = false;
    if ((this.activeTab == 0 && !hasCommercialTabValid) || (this.activeTab == 1 && !hasGeneralLiabTabValid) || (this.activeTab == 2 && !hasMotorCargoTabValid)) {
      hasCurrentTabInvalid = true;
    }
    if (!hasCurrentTabInvalid) {
      if (!hasCommercialTabValid) {
        this.activeTab = 0;
      } else if (!hasGeneralLiabTabValid) {
        this.activeTab = 1;
      } else if (!hasMotorCargoTabValid) {
        this.activeTab = 2;
      }
    }
  }
  // ========= Save Product =====================
  public async saveProduct(form) {
    this.ProductModel.AddAnotherNamedIndividual = this.ProductModel.AddAnotherNamedIndividual.filter(x => !x.isDeleted);
    this.dao.NotAllowedToSubmit = false;
    form = this.serviceLayer.formValidation(form);
    let isPremiseLocValid = form.valid;
    this.ProductModel.AddProductAnotherLocation.forEach(pro => {
      if (pro?.premiseLocation == 'CLICK') {
        isPremiseLocValid = false;
      }
    })
    if (isPremiseLocValid || this.ProductModel.productDetails.glIncluded.answer != 'Yes') {
      let shallCallServiceToSave = await this.ValidateUninsuredUnderinsuredMotorist();
      if (shallCallServiceToSave) {
        shallCallServiceToSave = this.validateUnderinsured();
      }
      if (shallCallServiceToSave) {
        shallCallServiceToSave = await this.validateOptionalConverages();
      }
      if (shallCallServiceToSave) {
        shallCallServiceToSave = await this.validateNumberOfEmployees();
      }
      if (shallCallServiceToSave) {
        shallCallServiceToSave = await this.validateTortLimits();
      }
      if (shallCallServiceToSave) {
        this.productService.setProductData(this.ProductModel)
        let response = await this.serviceLayer.save()
        if (response && response != '' && typeof (response) == 'string') {
          if (this.dao.IsSavedFromMenu) {
            this.dao.NotAllowedToSubmit = true;
          } else {
            this.openValidPopup();
          }
        }
        if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
          if (this.dao.IsSavedFromMenu) {
            this.dao.NotAllowedToSubmit = true;
          } else {
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission' });
          }
        }
      }
      if (!shallCallServiceToSave) {
        if (this.dao.IsSavedFromMenu) {
          this.dao.NotAllowedToSubmit = true;
        }
      }
    }
    else {
      if (!isPremiseLocValid) {
        if (this.dao.IsSavedFromMenu) {
          this.dao.NotAllowedToSubmit = true;
        } else {
          this.openValidPopup();
        }
      }
    }
  }
  public removeProduct(index, type, value) {
    this.deletedIndex = index;
    this.deleteType = type;
    if (value && value != '') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
    }
    else {
      this.confirmRemove();
    }
  }
  public confirmRemove() {
    this.removingProduct(this.deletedIndex, this.deleteType);
    this.messageService.clear('remove')
    document.body.style.pointerEvents = "visible";
  }
  private removingProduct(index, type) {
    if (type == 'location') {
      this.ProductModel.AddProductAnotherLocation.splice(index, 1);
      this.ProductModel.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));
    } else if (type == 'insured') {
      this.ProductModel.AddAnotherInsured.splice(index, 1);
    } else if (type == 'waiver') {
      this.ProductModel.AddAnotherWaiver.splice(index, 1);
    } else if (type == 'insuredForCommercial') {
      this.ProductModel.AddAnotherInsuredForCommercialAuto.splice(index, 1);
    } else if (type == 'waiverForCommercial') {
      this.ProductModel.AddAnotherWaiverForCommercialAuto.splice(index, 1);
    } else if (type == 'insuredForCargo') {
      this.ProductModel.AddAnotherInsuredForCargo.splice(index, 1);
    } else if (type == 'namedIndividual') {
      this.ProductModel.AddAnotherNamedIndividual[index].isDeleted = true;
    }
  }

  public async ValidateUninsuredUnderinsuredMotorist() {
    const umPdPrd = this.questionnaire.questionAnswer.find(x => x.key == 'umPdPrd');
    const umBiLimit = this.questionnaire.questionAnswer.find(x => x.key == 'umBiLimit');
    let shallCallServiceToSave = true;
    if (umBiLimit?.answer == "CLICK" && umPdPrd?.answer == 'CLICK' && umPdPrd?.enabled) {
      shallCallServiceToSave = false;
      this.isUninsuredMotoristBodilyInjuryValid = false;
      this.appComponent.addToast("error", "Uninsured Motorist", "Either Uninsured Motorist - Bodily Injury limits or Uninsured Motorist - Combined Bodily Injury / Property Damage Limit should be selected.");
      this.dao.isClickFromMenu = false;
    }

    return shallCallServiceToSave;
  }

  private hasSelection(question: QuestionAnswer): boolean {
    return !!question?.answer && question?.answer !== 'CLICK';
  }
  private validateTortLimits(): boolean {
    let shallCallServiceToSave = true;

    const tortLimits = this.questionnaire.questionAnswer.find(x => x.key === 'tortLimits');
    const tortLimitSelect = this.questionnaire.questionAnswer.find(x => x.key === 'tortLimitSelect');

    if (tortLimits && tortLimits.enabled && ((this.hasSelection(tortLimits) && tortLimits.answer == tortLimits.questionOptions[3].value && tortLimitSelect.answer == 'No') || !this.hasSelection(tortLimits))) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.dao.isClickFromMenu = false;
      }
    }
    return shallCallServiceToSave;

  }
  private validateUnderinsured(): boolean {
    let shallCallServiceToSave = true;

    const uimBiPrd = this.questionnaire.questionAnswer.find(x => x.key === 'uimBiPrd');
    const uimBi = this.questionnaire.questionAnswer.find(x => x.key === 'uimBi');
    const uimPrd = this.questionnaire.questionAnswer.find(x => x.key === 'uimPrd');
    const uimPrdDed = this.questionnaire.questionAnswer.find(x => x.key === 'uimPrdDed');

    if (shallCallServiceToSave && uimBiPrd?.enabled && uimBi?.enabled && !this.hasSelection(uimBiPrd) && !this.hasSelection(uimBi)) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.appComponent.addToast("error", "Underinsured Motorist", "Underinsured Motorist - Combined Bodily Injury / Property Damage Limit or Underinsured Motorist - Bodily Injury is required.");
        this.dao.isClickFromMenu = false;
      }
    }

    if (shallCallServiceToSave && uimPrd?.enabled && this.hasSelection(uimBi) && !this.hasSelection(uimPrd)) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.appComponent.addToast("error", "Underinsured Motorist", "Underinsured Motorist - Property Damage Limit is required.");
        this.dao.isClickFromMenu = false;
      }
    }
    if (shallCallServiceToSave && uimPrdDed?.enabled && this.hasSelection(uimBi) && !this.hasSelection(uimPrdDed)) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.appComponent.addToast("error", "Underinsured Motorist", "Underinsured Motorist - Property Damage Deductible is required.");
        this.dao.isClickFromMenu = false;
      }
    }
    const umBiLimit = this.questionnaire.questionAnswer.find(x => x.key == 'umBiLimit');
    // UM and UIM must match
    if (this.questionnaire?.questionAnswer?.find(x => x.key === 'tortLimitSelect') && this.questionnaire?.questionAnswer?.filter(x => x.questionLabel === 'Underinsured Motorist - Bodily Injury')[0]?.answer != umBiLimit?.answer && umBiLimit?.answer != 'CLICK' && this.hasSelection(uimBi) && this.questionnaire?.questionAnswer?.filter(x => x.questionLabel === 'Underinsured Motorist - Bodily Injury')[0]?.answer != 'DECLINE') {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.dao.isClickFromMenu = false;
      }
    }

    return shallCallServiceToSave;
  }

  public CheckPayrollAmountValidation(invalidIndexes: number[]): void {
    const minimumPayrollAmount = Number(
      parseFloat(
        this.ProductModel?.productDetails?.payrollAmount?.minimum
      ).toFixed(2)
    );

    invalidIndexes.forEach((index) => {
      const element = this.ProductModel.AddProductAnotherLocation[index];

      if (element && Number(element.payrollAmount) < minimumPayrollAmount) {
        element.payrollAmount = minimumPayrollAmount.toString();
      }
    });
  }

  public async CheckPremiseState(state, index, msg: string = '') {
    const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
    const mailState = this.dao.DOTList.accountInfoQsnr.findQuestion("mailState")?.answer;
    if (state != accounState && state != mailState) {
      this.messageService.add({ key: 'productToastKey', severity: 'error', summary: 'Premise State', detail: msg != '' ? msg : this.ProductModel.PremiseStateValidation });
      this.ProductModel.AddProductAnotherLocation[index].premiseState = "";
    }
  }
  // ========= Cancel my submission popup =====================
  public cancelMySubmissionPopUp() {
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'CancelMySubmission', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
  }
  // ========= Cancel my submission =====================
  public CancelmySubmission() {
    return this.serviceLayer.CancelmySubmission();
  }
  // ========= On reject =====================
  public onReject(type) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
  }
  public async IsShowGLTab() {
    if (this.ProductModel.productDetails.glIncluded.answer == 'No' || this.ProductModel.productDetails.glIncluded.answer == '') {
      this.ProductModel.productDetails.glIncluded.answer = 'Yes'
      setTimeout(() => {
        this.showingGlTab = true;
        this.activeTab = 1;
      }, 5);
      window.scrollTo(0, 300);
    } else if (this.ProductModel.productDetails.glIncluded.answer == 'Yes') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'RemoveGL', sticky: true, severity: 'warn', summary: ' Do you want to remove general liability ?' });
    }
  }
  public async RemoveGLTabForProduct() {
    if (this.ProductModel.productDetails.glIncluded.answer == 'Yes') {
      this.ProductModel.productDetails.glIncluded.answer = 'No'
      await this.bindGLForProduct('GLRemoved');
      this.showingGlTab = false;
      this.activeTab = 0;
      window.scrollTo(0, 300);
    }
    this.messageService.clear('RemoveGL')
    document.body.style.pointerEvents = "visible";
  }

  public UnderinsuredRuleCheck() {
    const umBiLimit = this.questionnaire.questionAnswer.find(x => x.key == 'umBiLimit')
    const uimBi = this.questionnaire.questionAnswer.find(x => x.key == 'uimBi')
    if (umBiLimit.answer == 'DECLINE') {
      if (uimBi?.answer == 'DECLINE') {
        this.isUnderInsuredMotoristBodilyInjuryLimitValid = true;
      }
    }
    else {
      if (umBiLimit.answer != 'CLICK') {
        if (uimBi?.answer == 'CLICK') {
          this.isUnderInsuredMotoristBodilyInjuryLimitValid = false;
        }
        else {
          this.isUnderInsuredMotoristBodilyInjuryLimitValid = true;
        }
      }
    }
  }
  public checkDifferentLocationInProduct(value, index) {
    const accountInfoQnnr = this.dao.DOTList.accountInfoQsnr;
    if (value != "newAddressAsPremise" && this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == value).length == 1) {
      if (value == 'businessAddressAsGLPremise') {        
        if (accountInfoQnnr != undefined) {
          this.ProductModel.AddProductAnotherLocation[index].premiseStreet = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountStreet")[0]?.answer;
          this.ProductModel.AddProductAnotherLocation[index].premiseCity = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountCity")[0]?.answer;
          this.ProductModel.AddProductAnotherLocation[index].premiseState = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountState")[0]?.answer;
          this.ProductModel.AddProductAnotherLocation[index].premisePostalCode = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountPostalCode")[0]?.answer;
        }
      }
      else if (value == 'mailingAddressAsGLPremise') {
        this.ProductModel.AddProductAnotherLocation[index].premiseStreet = accountInfoQnnr.findQuestion("mailStreet").answer;
        this.ProductModel.AddProductAnotherLocation[index].premiseCity = accountInfoQnnr.findQuestion("mailCity").answer;
        this.ProductModel.AddProductAnotherLocation[index].premiseState = accountInfoQnnr.findQuestion("mailState").answer;
        this.ProductModel.AddProductAnotherLocation[index].premisePostalCode = accountInfoQnnr.findQuestion("mailPostalCode").answer;
      }
      else {
        let vehicleAddress = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='vehiclePrefill')].questionAnswer[?(@.group == '" + value + "')]");
        if (vehicleAddress.length > 0) {
          this.ProductModel.AddProductAnotherLocation[index].premiseStreet = vehicleAddress.filter(c => c.key == 'vehicleGarageStreetAddress')[0].answer;
          this.ProductModel.AddProductAnotherLocation[index].premiseCity = vehicleAddress.filter(c => c.key == 'vehicleGarageCity')[0].answer;
          this.ProductModel.AddProductAnotherLocation[index].premiseState = vehicleAddress.filter(c => c.key == 'vehicleGarageState')[0].answer;
          this.ProductModel.AddProductAnotherLocation[index].premisePostalCode = vehicleAddress.filter(c => c.key == 'vehicleGaragePostalCode')[0].answer;
        }
      }
    } else if (value == 'newAddressAsPremise') {
      this.ProductModel.AddProductAnotherLocation[index].premiseStreet = "";
      this.ProductModel.AddProductAnotherLocation[index].premiseCity = "";
      this.ProductModel.AddProductAnotherLocation[index].premiseState = this.dao.DOTList.questionnaire.filter(x => x.key == "AccountInfo")[0].questionAnswer.filter(y => y.key == "accountState")[0].answer;
      this.ProductModel.AddProductAnotherLocation[index].premisePostalCode = "";
    } else if (value != 'CLICK') {
      var validationMsg: string = 'Premise Location Already Exists';
      this.messageService.add({ key: 'productToastKey', severity: 'error', summary: 'Premise Location', detail: validationMsg });
      this.ProductModel.AddProductAnotherLocation[index].premiseLocation = "";
    }
    this.ProductModel.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));
  }

  public async validateOptionalConverages() {
    if ((this.ProductModel.productDetails.insurableInterestParent.required && (this.ProductModel.productDetails.insurableInterestParent.answer == undefined
      || this.ProductModel.productDetails.insurableInterestParent.answer == ''))
      || (this.ProductModel.productDetails.autoWavierOfSubrogation.required && (this.ProductModel.productDetails.autoWavierOfSubrogation.answer == undefined
        || this.ProductModel.productDetails.autoWavierOfSubrogation.answer == ''))) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        this.appComponent.addToast("error", "Product Info", this.dao.toastValidation);
        this.dao.isClickFromMenu = false;
        return false;
      }
    }
    if (this.ProductModel.productDetails.glIncluded.answer == 'Yes' && ((this.ProductModel.productDetails.additionalInsuredIncludedParent.required && (this.ProductModel.productDetails.additionalInsuredIncludedParent.answer == undefined
      || this.ProductModel.productDetails.additionalInsuredIncludedParent.answer == ''))
      || (this.ProductModel.productDetails.glWavierOfSubrogation.required && (this.ProductModel.productDetails.glWavierOfSubrogation.answer == undefined
        || this.ProductModel.productDetails.glWavierOfSubrogation.answer == '')))) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        this.appComponent.addToast("error", "General Liability", this.dao.toastValidation);
        this.dao.isClickFromMenu = false;
        return false;
      }
    }
    return true;
  }
  // ========= Numbers only =====================
  public numbersOnly(e) {
    return this.serviceLayer.numbersOnly(e);
  }

  public async validateNumberOfEmployees() {
    if (this.ProductModel?.productDetails?.empBenefitsLiab?.answer != 'DECLINE' && this.ProductModel?.productDetails?.glIncluded?.answer === "Yes") {
      let numOfEmployees = this.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer;
      let minimum = this.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.minimum;
      if (isNaN(Number(numOfEmployees)) || Number(this.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer) < Number(minimum)) {
        if (this.dao.IsSavedFromMenu) {
          this.dao.NotAllowedToSubmit = true;
        } else {
          this.appComponent.addToast("error", "General Liability", "Number of Employees must be greater than 0.");
          this.dao.isClickFromMenu = false;
          return false;
        }
      }
    }
    else {
      this.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer = '';
    }
    return true;
  }
  // ========= On Back =====================
  public onBack(page) {
    this.dao.IsBackButtonClicked = true;
    return this.menuComponent.menuClick(page);
  }

  // Validate address
  public ValidateAddress(event, value, type, index) {
    let result = this.serviceLayer.ValidateAddress(event, value);
    if (result != null) {
      if (type == "premiseStreet") {
        this.ProductModel.AddProductAnotherLocation[index].premiseStreet = result;
      }
    }
  }
  public async IsShowCargoTab() {
    if (this.ProductModel.productDetails.cargoIncluded.answer == 'No' || this.ProductModel.productDetails.cargoIncluded.answer == '') {
      this.ProductModel.productDetails.cargoIncluded.answer = 'Yes';
      let accountCargoIndicatorQ = this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator");
      if (!!accountCargoIndicatorQ?.answer) {
        accountCargoIndicatorQ.answer = "Yes"
      }
      if (!!this.cargoIndicator) {
        this.cargoIndicator.answer = "Yes";
      }
      setTimeout(() => {
        this.activeTab = 2;
      }, 5);
      window.scrollTo(0, 300);
    } else if (this.ProductModel.productDetails.cargoIncluded.answer == 'Yes') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'RemoveCargo', sticky: true, severity: 'warn', summary: ' Are you sure that you want to remove Motor Truck Cargo coverage from this submission? When yes is selected, we will remove all related responses from this submission.' });
    }
  }

  public async IsShowCargoTabForProduct() {
    if (this.ProductModel.productDetails.cargoIncluded.answer == 'Yes') {
      this.ProductModel.productDetails.cargoIncluded.answer = 'No'
      let accountCargoIndicatorQ = this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator");
      if (!!accountCargoIndicatorQ?.answer) {
        accountCargoIndicatorQ.answer = "No";
      }
      if (!!this.cargoIndicator) {
        this.cargoIndicator.answer = "No";
      }
      await this.bindcargoForProduct('CargoRemoved');
      this.activeTab = 0;
      window.scrollTo(0, 300);
    }
    this.messageService.clear('RemoveCargo')
    document.body.style.pointerEvents = "visible";
  }

  public async AddAnotherInsuredForCargo() {
    this.isAddBtnClicked = false;
    var tempArray = { 'Name': '', 'Street': '', 'City': '', 'State': '', 'PostalCode': '' };
    var tempObj = _.cloneDeep(this.ProductModel.AddAnotherInsuredForCargo);
    tempObj.push(tempArray);
    this.ProductModel.AddAnotherInsuredForCargo = _.cloneDeep(tempObj);
  }
  setselectedIndex(event) {
    if (!this.showingGlTab && event.index == 1) {
      setTimeout(() => {
        this.activeTab = 2;
      }, 5);
    }
    else {
      this.activeTab = event.index;
    }
  }

  public checkNFIsNotQualify(response, value) {
    if (this.ProductModel.productDetails.nfPipLimits.answer == response
      && ((this.ProductModel.productDetails[value + 1].answer == 'No' && this.ProductModel.productDetails[value + 2].answer != '')
        || (this.ProductModel.productDetails[value + 2].answer == 'No' && this.ProductModel.productDetails[value + 1].answer != ''))) {
      this.ProductModel.productDetails.nfPipLimits.answer = 'CLICK'
      this.ProductModel.productDetails[value + 1].answer = '';
      this.ProductModel.productDetails[value + 2].answer = '';
      this.IsShowNFNotQualifyErrorMsg = true;
      this.hideNFChild = true;
    } else {
      this.hideNFChild = false;
    }
  }
  public nfLimitOnChange() {
    if (this.ProductModel.productDetails.nfPipLimits.answer == 'CLICK' && this.hideNFChild) {
      this.IsShowNFNotQualifyErrorMsg = true;
    } else {
      this.hideNFChild = false;
      this.IsShowNFNotQualifyErrorMsg = false;
    }
    const keyList: Array<string> = new Array<string>();
    keyList.push("NF_250kWithPIPME_Child1");
    keyList.push("NF_250kWithPIPME_Child2");
    keyList.push("NF_50K_Child1");
    keyList.push("NF_50K_Child2");
    keyList.push("NF_PIPMedOptOut_Child1");
    keyList.push("NF_PIPMedOptOut_Child2");
    keyList.forEach(x => {
      if (this.ProductModel.productDetails) {
        this.ProductModel.productDetails[x].answer = '';
      }
    });
  }

  onChangeNfPipDed(nfPipDed) {
    if (this.questionnaire?.questionAnswer?.find(x => x.key === 'nfBrdDed')?.enabled)
      this.questionnaire.questionAnswer.find(x => x.key === 'nfBrdDed').answer = nfPipDed;
  }
  public AddNamedIndividual() {
    this.productService.AddNamedIndividual();
  }
  public get getNamedIndividualCount() {
    if (this.ProductModel.AddAnotherNamedIndividual.filter(x => (!x.isDeleted)).length < 3) {
      return true;
    }
    return false;
  }
  private loadValidationMessage(): void {
    this.addedPipValidationMessage = this.productService.getValidationMessage('addedPIP') ?? "";
    this.namedIndividualValidationMessage = this.productService.getValidationMessage('namedIndividual') ?? "";
  }
  getAndSetValuesFromQuestionnaire() {
    
    this.umReject = this.questionnaire?.questionAnswer?.find(x => x.key === "umReject");
    this.fireSurcharge = this.questionnaire?.questionAnswer?.find(x => x.key === "fireSurcharge");
    this.nfRateBasis = this.questionnaire?.questionAnswer?.find(x => x.key === "nfRateBasis");

    //Cargo Indicator Details
    let accountQuestionnaire = this.dao.DOTList?.questionnaire?.find(q => q.key === 'AccountInfo');
    if (!!accountQuestionnaire) {
      this.cargoIndicator = accountQuestionnaire.questionAnswer?.find(x => x.key === "cargoIndicator");
    }

     // Property Limit
     this.cargoPropertyLimit = this.dao.DOTList.productQsnr?.findQuestion("propertyLimit");
     if(!!this.cargoPropertyLimit){
      QuestionAnswerHelper.useDefaultValue.apply(this.cargoPropertyLimit);  
     }
     // Cargo Shipper Limit
     this.cargoShipperLimit = this.dao.DOTList.productQsnr?.findQuestion("cargoShipperLimit");
     if(!!this.cargoShipperLimit){
      QuestionAnswerHelper.useDefaultValue.apply(this.cargoShipperLimit);
     }
  }

  // ====== Check UW question for GL enable======
  private checkUWQusForProduct() {
    let UWAnswerForGL = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key == "operateMobleEquip" || @.key == "contractsAssumeLiabilityParent")]');
    let UWAnswerForCargo = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key == "unattendedLoadedTrailers" || @.key == "actualDamageInLossEvent" || @.key == "IsDOTBillIssued")]');

    if (!!UWAnswerForGL && UWAnswerForGL.length > 0) {
      this.ProductModel.IsGLShow = true;
      if (UWAnswerForGL.filter(c => c.answer == "true" || c.answer == true || c.answer == "Y")?.length > 0) {
        this.ProductModel.IsGLShow = false;
        this.ProductModel.productDetails.glIncluded.answer = 'No';
      }
    }
    if (!!UWAnswerForCargo && UWAnswerForCargo.length > 0) {
      this.ProductModel.IsCargoShow = true;
      UWAnswerForCargo.forEach(c => {
        if ((c.answer == "Y" && c.key == "unattendedLoadedTrailers") || (c.answer == "Y" && c.key == "actualDamageInLossEvent") || (c.answer == "N" && c.key == "IsDOTBillIssued")) {
          this.ProductModel.IsCargoShow = false;
          let accountCargoIndicatorQ = this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator");
          if (!!accountCargoIndicatorQ?.answer) {
            accountCargoIndicatorQ.answer = "Yes"
          }
        }
      });
    }
  }

  public async changingFlatDeductibleDropdownValues() {
    //Change CargoFlatDeductible options and default answer from 1000 to 2500, based on refrigerationTrailersParent.answer
    this.ProductModel.productDetails.flatDeductible.answer = this.ProductModel.productDetails.flatDeductible.questionDefault[0].code;
    let UWAnswerForCargoFlatDeductible = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key =="refrigerationTrailersParent")].answer');
    if (UWAnswerForCargoFlatDeductible && UWAnswerForCargoFlatDeductible.length > 0) {
      let dropdownValues = _.cloneDeep(this.ProductModel.productDetails.flatDeductible.questionOptions);
      if (UWAnswerForCargoFlatDeductible == "Y") {
        _.remove(dropdownValues, function (n) {
          return n.sequence == 1
        })
        if (this.ProductModel.productDetails.flatDeductible.answer == '1,000') {
          this.ProductModel.productDetails.flatDeductible.answer = '2,500';
        }
      }
      this.ProductModel.productDetails.flatDeductible.questionOptions = dropdownValues;
    }
  }

  public onCargoNameInsuredClick() : void{
    this.ProductModel.AddAnotherInsuredForCargo = [];
    this.AddAnotherInsuredForCargo();
    this.changingPropertyLimitDropdown()
  }

  public changingPropertyLimitDropdown(){
    if(!!this.cargoPropertyLimit && !!this.cargoShipperLimit){
      this.cargoShipperLimit.answer = this.cargoPropertyLimit.answer;
      this.setCargoShipperLimitDropdownOptions();
    }
  }
  public setCargoShipperLimitDropdownOptions() : void{
    if(!!this.cargoPropertyLimit && !!this.cargoShipperLimit){
      let propertyLimitInNumber : number = Number(this.cargoPropertyLimit.answer.replace(/,/g, ''));
      let cargoShipperLimitOptions : Array<QuestionOption> = [];
      this.cargoPropertyLimit.questionOptions.forEach(item => {
        let cargoShipperLimitInNumber : number= Number(item.code.replace(/,/g, ''));
        if(cargoShipperLimitInNumber <= propertyLimitInNumber){
          cargoShipperLimitOptions.push(item);
        }
      })
      this.cargoShipperLimit.questionOptions = cargoShipperLimitOptions;
    }
  }
  private initializeProductModel(responseContainer): void{
    let ProductModelObj = '$..questionnaire[?(@.key =="product")]'
    let getProductData = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, ProductModelObj)[0];
    this.ProductModel.productDetails = new ProductDetails();
    let tempProductModel = new ProductDetails();
    if (getProductData) {
      Object.keys(tempProductModel).forEach(key => {
        let arrayData = _.cloneDeep(this.serviceLayer.getObjUsingJpath(getProductData, '$..[?(@.key =="' + key + '")]'));
        if (arrayData && arrayData.length > 0) {
          tempProductModel[key] = _.cloneDeep(arrayData[0]);
        }
        if (tempProductModel[key].answer && tempProductModel[key].questionDefault && tempProductModel[key].questionDefault.length > 0 && tempProductModel[key].questionDefault[0].code && tempProductModel[key].questionOptions && tempProductModel[key].questionOptions.length > 0) {
          if (!tempProductModel[key].questionOptions.find(x => x?.code == tempProductModel[key].answer)) {
            tempProductModel[key].answer = tempProductModel[key].questionDefault[0].code;
          }
        }
        if ((tempProductModel[key].answer == null || tempProductModel[key].answer == "") && tempProductModel[key].questionDefault && tempProductModel[key].questionDefault.length > 0 && tempProductModel[key].questionDefault[0].code && tempProductModel[key].questionDefault[0].code != '') {
          tempProductModel[key].answer = tempProductModel[key].questionDefault[0].code;
        }
        this.ProductModel.productDetails[key] = tempProductModel[key];
      });
    }
    this.showingGlTab = this.ProductModel.productDetails.glIncluded.answer == 'Yes' ? true : false;
  }

    private bindGLForProduct(type) {
        let ProductModelObj = '$..questionnaire[?(@.key =="product")]'
        let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
        let getproductdataList = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getproductdata));
      
        this.ProductModel.AddProductAnotherLocation = [];
        this.ProductModel.AddAnotherInsured = [];
        this.ProductModel.AddAnotherWaiver = [];
    
        if (type == 'GLRemoved') {
    
          this.ProductModel.productDetails.premiseStreet.answer = '';
          this.ProductModel.productDetails.premiseCity.answer = '';
          this.ProductModel.productDetails.premiseState.answer = '';
          this.ProductModel.productDetails.premisePostalCode.answer = '';
          this.ProductModel.productDetails.payrollAmount.answer = '';
          this.ProductModel.productDetails.additionalInsuredName.answer = '';
          this.ProductModel.productDetails.glWavierOfSubrogationName.answer = '';
          this.ProductModel.productDetails.empBenefitsLiab.answer = '';
          this.ProductModel.productDetails.glWavierOfSubrogation.answer = '';
          this.ProductModel.productDetails.additionalInsuredIncludedParent.answer = '';
    
          let empBenefitsLiabDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="empBenefitsLiab")].questionDefault[0].code'
          this.ProductModel.productDetails.empBenefitsLiab.answer = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, empBenefitsLiabDefault)[0];  
        }
  
        let getLocationMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseStreet")].rowIndex';
        let getLocationMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getLocationMaxRow));
        //Populate AddProductAnotherLocation from Product questionnaire
        for (var i = 1; i <= getLocationMaxRowIndex; i++) {
          if (getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == i)[0]?.answer == "" && type != 'GLRemoved') {
            this.ProductModel.AddProductAnotherLocation.push({
              'premiseStreet': getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == i)[0]?.answer
              , 'premiseCity': getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == i)[0]?.answer
              , 'premiseState': getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == i)[0]?.answer
              , 'premisePostalCode': getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == i)[0]?.answer
              // Only the first payrollAmount will have the QuestionDefault, so we consider rowIndex = 1 to have the code value
              , 'payrollAmount': Number(getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == 1)[0]?.questionDefault[0].code.toString())
              , 'premiseLocation': ''
            })
          } else if (type == 'GLRemoved') {
            this.ProductModel.AddProductAnotherLocation.push({
              'premiseStreet': getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == i)[0]?.answer
              , 'premiseCity': getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == i)[0]?.answer
              , 'premiseState': getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == i)[0]?.answer
              , 'premisePostalCode': getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == i)[0]?.answer
              // Only the first payrollAmount will have the QuestionDefault, so we consider rowIndex = 1 to get the code value
              , 'payrollAmount': Number(getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == 1)[0]?.questionDefault[0].code.toString())
              , 'premiseLocation': ''
            })
            i = getLocationMaxRowIndex;
          }
          else {
            this.ProductModel.AddProductAnotherLocation.push({
              'premiseStreet': getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == i)[0]?.answer
              , 'premiseCity': getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == i)[0]?.answer
              , 'premiseState': getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == i)[0]?.answer
              , 'premisePostalCode': getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == i)[0]?.answer
              , 'payrollAmount': Number(getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == i)[0]?.answer.toString())
              , 'premiseLocation': getproductdataList?.filter(f => f.key == 'premiseLocation' && f.rowIndex == i)[0]?.answer
            })
          }  
        //PremiseStateValidation
        let premiseStateValidation = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseState")].questionInfoText'
        this.ProductModel.PremiseStateValidation = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, premiseStateValidation)[0];
        //PremiseStateDefaultPayrollAmt
        let PremiseStateDefaultPayrollAmt = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="payrollAmount")].questionDefault[0].code'
        this.ProductModel.PremiseStateDefaultPayrollAmt = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, PremiseStateDefaultPayrollAmt)[0];
        if (this.ProductModel.PremiseStateDefaultPayrollAmt != undefined && this.ProductModel.PremiseStateDefaultPayrollAmt != '') {
          this.ProductModel.PremiseStateDefaultPayrollAmt = Number(this.ProductModel.PremiseStateDefaultPayrollAmt).toLocaleString()
        }
  
        let PremiseLocationDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseLocation")].questionDefault[0].code'
        this.ProductModel.PremiseLocationDefaultValue = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, PremiseLocationDefault)[0];
        this.ProductModel.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));
  
      }
      //AddAnotherInsured
      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="additionalInsuredName")].rowIndex';
      let getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.ProductModel.AddAnotherInsured.push({
          'Name': getproductdataList?.filter(f => f.key == 'additionalInsuredName' && f.rowIndex == i)[0]?.answer
        });
      }
      //AddAnotherWaiver
      this.ProductModel.AddAnotherWaiver = [];
      getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="glWavierOfSubrogationName")].rowIndex';
      getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.ProductModel.AddAnotherWaiver.push({
          'Name': getproductdataList?.filter(f => f.key == 'glWavierOfSubrogationName' && f.rowIndex == i)[0]?.answer
        })
      }
  
      // binding garging address to product premise location, if vehicle is not loaded before from WIP. 
      if (this.dao.vehicleModel.VehiclesList.length == 0 && this.dao.IsVehicleBreadcrumpClicked == false && this.dao.IsDOTExistsInWIP == true) {
        let getGaragingAddress = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseLocation")].answer';
        let getGaragingAddressIndex = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getGaragingAddress);
        if (getGaragingAddressIndex && getGaragingAddressIndex.filter(x => x != 'businessAddressAsGLPremise' && x != 'newAddressAsPremise').length > 0) {
          let getVehicleQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="vehicleType" && @.answer != "Unknown")]';
          let vehicleDetail = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getVehicleQuery);
          let getTrailerQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="vehicleType" && @.answer == "Unknown")]';
          let TrailerDetail = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getTrailerQuery);
          this.serviceLayer.GetPremiseAddressFromVehicle(vehicleDetail, 'Vehicles');
          this.serviceLayer.GetPremiseAddressFromVehicle(TrailerDetail, 'trailer');
        }
      }
  
      this.constructGaragingAddress();
    }

      // ===== Change loss question based UW cargo question

  public bindcargoForProduct(type) {
    let ProductModelObj = '$..questionnaire[?(@.key =="product")]'
    let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
    let getproductdataList = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getproductdata));
    this.ProductModel.AddAnotherInsuredForCargo = [];

    if (type == 'CargoRemoved') {
      this.ProductModel.productDetails.cargoNameInsuredParent.answer = '';
      let flatDeductibleDefaultValue = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="flatDeductible")].questionDefault[0].code'
      this.ProductModel.productDetails.flatDeductible.answer = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, flatDeductibleDefaultValue)[0];
    }
    else {      
      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="cargoInsuredName")].rowIndex';
      let getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.ProductModel.AddAnotherInsuredForCargo.push({
          'Name': getproductdataList?.filter(f => f.key == 'cargoInsuredName' && f.rowIndex == i)[0]?.answer,
          'Street': getproductdataList?.filter(f => f.key == 'cargoInsuredStreet' && f.rowIndex == i)[0]?.answer,
          'City': getproductdataList?.filter(f => f.key == 'cargoInsuredCity' && f.rowIndex == i)[0]?.answer,
          'State': getproductdataList?.filter(f => f.key == 'cargoInsuredState' && f.rowIndex == i)[0]?.answer,
          'PostalCode': getproductdataList?.filter(f => f.key == 'cargoInsuredPostalCode' && f.rowIndex == i)[0]?.answer
        });
      }      
    }
  }
  
  public bindCommercialAutoForProduct()
  {
      let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
      let getproductdataList = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getproductdata));
    
      let umPedDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="umPdDed")].questionDefault[0].code'
      this.ProductModel.umPdDedDefaultValue = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, umPedDefault)[0];
    
      let uimbiDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="uimBi")].questionDefault[0].code'
      this.ProductModel.uimBiDefaultValue = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, uimbiDefault)[0];
    
      this.ProductModel.AddAnotherInsuredForCommercialAuto = [];
      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="insurableInterestName")].rowIndex';
      let getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.ProductModel.AddAnotherInsuredForCommercialAuto.push({
          'Name': getproductdataList?.filter(f => f.key == 'insurableInterestName' && f.rowIndex == i)[0]?.answer,
          'Street': getproductdataList?.filter(f => f.key == 'insurableInterestStreet' && f.rowIndex == i)[0]?.answer,
          'City': getproductdataList?.filter(f => f.key == 'insurableInterestCity' && f.rowIndex == i)[0]?.answer,
          'State': getproductdataList?.filter(f => f.key == 'insurableInterestState' && f.rowIndex == i)[0]?.answer,
          'PostalCode': getproductdataList?.filter(f => f.key == 'insurableInterestPostalCode' && f.rowIndex == i)[0]?.answer,
        });
      }

      this.ProductModel.AddAnotherWaiverForCommercialAuto = [];      
      getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="autoWavierOfSubrogationName")].rowIndex';
      getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.ProductModel.AddAnotherWaiverForCommercialAuto.push({
          'Name': getproductdataList?.filter(f => f.key == 'autoWavierOfSubrogationName' && f.rowIndex == i)[0]?.answer
        })
      }
      
      this.ProductModel.AddAnotherNamedIndividual = [];
      getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="namedIndividual")].rowIndex';
      getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.ProductModel.AddAnotherNamedIndividual.push({
          'Name': getproductdataList?.filter(f => f.key == 'namedIndividual' && f.rowIndex == i)[0]?.answer
        })
      }
  }
      // consruct Garaging address dropdown ===
  public constructGaragingAddress() {
        let garagingAddressList = _.cloneDeep(this.ProductModel?.productDetails?.premiseLocation?.questionOptions);
        if (garagingAddressList && garagingAddressList.length > 0) {
          if (!!this.dao.DOTList.vehiclePrefillQsnr.questionAnswer && this.dao.DOTList.vehiclePrefillQsnr.questionAnswer.length > 0) {
              const groups = _.uniqBy(this.dao.DOTList.vehiclePrefillQsnr.questionAnswer, 'group')?.map(x=>x.group);
              groups.forEach(grp=>{
                if (!!!grp){
                  return;
                }
                const vehicleQAs: Array<QuestionAnswer> = this.dao.DOTList.vehiclePrefillQsnr.questionAnswer.filter( x=> !!grp && x.group == grp)
                let tempGaragingAddress: any = {};
                const vehicleGarageStreetAddress = vehicleQAs.filter(x=>x.key == "vehicleGarageStreetAddress")[0]?.answer;
                const vehicleGarageCity = vehicleQAs.filter(x=>x.key == "vehicleGarageCity")[0]?.answer;
                const vehicleGarageState = vehicleQAs.filter(x=>x.key == "vehicleGarageState")[0]?.answer;
                const vehicleGaragePostalCode = vehicleQAs.filter(x=>x.key == "vehicleGaragePostalCode")[0]?.answer;
                const vehicleIsCorrectGaragingAddress = vehicleQAs.filter(x=>x.key == "vehicleIsCorrectGaragingAddress")[0]?.answer;                
                tempGaragingAddress.code = grp;
                tempGaragingAddress.helpText = "";
                tempGaragingAddress.label = `${vehicleGarageStreetAddress} ${vehicleGarageCity} ${vehicleGarageState} ${vehicleGaragePostalCode}`;
                tempGaragingAddress.sequence = 2;
                tempGaragingAddress.value = grp;                
                if (vehicleIsCorrectGaragingAddress == 'No' && garagingAddressList.filter(c => c.label == tempGaragingAddress.label).length == 0) {
                  garagingAddressList.push(tempGaragingAddress);
                }
            }
            );
          }
          this.ProductModel.productDetails.premiseLocation.questionOptions = _.cloneDeep(_.sortBy(garagingAddressList, "sequence"));
          if (this.ProductModel.AddProductAnotherLocation && this.ProductModel.AddProductAnotherLocation.length > 0) {
            this.ProductModel.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));
          }
        }
  }

      // Location dropdown option build
  private changeLocationDropdown(index) {
        let optionList = _.cloneDeep(this.ProductModel?.productDetails?.premiseLocation?.questionOptions);
        if (this.ProductModel.AddProductAnotherLocation && this.ProductModel.AddProductAnotherLocation.length > 0) {
          this.ProductModel.AddProductAnotherLocation.forEach((loc, i) => {
            if (index != i) {
              _.remove(optionList, function (n) {
                return n.code == loc.premiseLocation && n.code != 'CLICK' && n.code != 'newAddressAsPremise';
              })
            }
          });
        }
        return optionList;
    }
    // ====== Set initial Data=====
  private setInitialData() {
      
        this.initialProductModel = _.cloneDeep(this.ProductModel);
        this.initialProductQuestionnaireAnswers = _.cloneDeep(this.dao.DOTList?.productQsnr.questionAnswer);
  }

  initializeData(responseContainer: any): void {
      this.initializeProductModel(responseContainer);
      this.bindGLForProduct('');
      this.bindcargoForProduct('');
      this.bindCommercialAutoForProduct()
      this.questionnaire = responseContainer.productQsnr;
      this.checkUWQusForProduct(); //show or hide GL and Cargo tab based on UW questionnaire questions
      this.changingFlatDeductibleDropdownValues(); //change flatdeductible question answers and drop down based on UW questionnaire questions
      this.getAndSetValuesFromQuestionnaire();
      this.enableNFPipLimitDropDown();
      this.setCargoShipperLimitDropdownOptions();        
      //let sub component finish their init events
      setTimeout(()=>this.setInitialData());
  }
  saveAndContinue(evt: any, ...rest: any[]) {
    throw new Error('Method not implemented.');
  }

  isPageValid(): boolean {
    this.serviceLayer.UpdateFiledsValuesAndValidate(this.productForm);
    //also need to validate if other invisible pages are valid or not
    return this.productForm.valid;
  }

  onRollback(page: string) {
    if (page != "product") {
      return;
    }
    this.ProductModel = _.cloneDeep(this.initialProductModel);
    var productQuestions = this.dao.DOTList.questionnaire.find(q => q.key === 'product');
    if (!!productQuestions) {
      productQuestions.questionAnswer = this.initialProductQuestionnaireAnswers;
    }
  }
  menuClicked(menuClickContext: MenuClickContext) { 
    if (menuClickContext != null && menuClickContext.page != "product") {
        menuClickContext.currentPageIsValid = this.isPageValid();
        menuClickContext.currentPageDataChanged = this.isPageDataChanged() ;
    }
  }

  
  public async SetAddressInVehicleAndProduct() {
      if (this.dao.IsProductBreadcrumpClicked == true) {
        if (this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise').length > 0) {
          let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
          let accountAddress = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;
          this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseStreet = accountAddress.street;
          this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseCity = accountAddress.city;
          this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseState = accountAddress.state;
          this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premisePostalCode = accountAddress.postalCode;
          this.ProductModel.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseList.filter(l => l.code == 'businessAddressAsGLPremise')[0].label = accountAddress.toString();
  
          this.ProductModel.productDetails.premiseLocation.questionOptions =
            this.dao.DOTList.questionnaire?.filter(x => x.key == 'product')[0].questionAnswer.filter(p => p.key == 'premiseLocation')[0].questionOptions;
        }
      }
  }

      // bind updated premise address
  async SetPremiseAddress(DOTList) {
    let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
    let getproductdataList = _.max(this.serviceLayer.getObjUsingJpath(DOTList, getproductdata));
    let businessAddIndex = getproductdataList.filter(c => c.key == 'premiseLocation' && c.answer == 'businessAddressAsGLPremise')[0].rowIndex;
    let tempbusiness = this.ProductModel.AddProductAnotherLocation.filter(c => c.premiseLocation == 'businessAddressAsGLPremise');
    if (tempbusiness && tempbusiness.length > 0 && businessAddIndex) {
      tempbusiness[0].premiseStreet = getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premiseCity = getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premiseState = getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premisePostalCode = getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premiseList = getproductdataList?.filter(f => f.key == 'premiseLocation' && f.rowIndex == businessAddIndex)[0]?.questionOptions;
      this.ProductModel.productDetails.premiseLocation.questionOptions = tempbusiness[0].premiseList;
    }

  }

  private isPageDataChanged():boolean{
    let ProductQuestionnaireAnswers = this.dao?.DOTList?.productQsnr.questionAnswer;
    let result = !_.isEqual(this.ProductModel.productDetails, this.initialProductModel.productDetails) ||
    !_.isEqual(this.ProductModel.AddProductAnotherLocation, this.initialProductModel.AddProductAnotherLocation) ||
    !_.isEqual(this.ProductModel.AddAnotherInsured, this.initialProductModel.AddAnotherInsured) ||
    !_.isEqual(this.ProductModel.AddAnotherWaiver, this.initialProductModel.AddAnotherWaiver) ||
    !_.isEqual(this.ProductModel.AddAnotherInsuredForCommercialAuto, this.initialProductModel.AddAnotherInsuredForCommercialAuto) ||
    !_.isEqual(this.ProductModel.AddAnotherWaiverForCommercialAuto, this.initialProductModel.AddAnotherWaiverForCommercialAuto) ||
    !_.isEqual(this.ProductModel.AddAnotherInsuredForCargo, this.initialProductModel.AddAnotherInsuredForCargo) ||
    !_.isEqual(this.ProductModel.AddAnotherNamedIndividual, this.initialProductModel.AddAnotherNamedIndividual) ||
    !_.isEqual(ProductQuestionnaireAnswers, this.initialProductQuestionnaireAnswers);      
    return result;
  }
}
