import { Injectable, Injector } from '@angular/core';
import { CommonService } from '../services/commonService';
import { FileuploadService } from '../services/fileupload.service';
import { DAO, EditAllUnits, emptyModal, ProductDetails, ProductModel, RegistrationModal, vehicleDetails, VehicleModel } from '../modal/DAO';
import { HttpResults, MenuClickContext, workInProgress } from '../modal/routing.models';
import { ChangeDetectionService } from '../services/changeDetectionService';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import _ from 'lodash';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as $ from "jquery";
import { NgForm } from '@angular/forms';
import { ResponseContainer, QuestionAnswer, Questionnaire } from '../modal/questionnaire.models';
import { Observable } from 'rxjs-compat';
import { PolicyHolderValueParser } from '../modal/PolicyHolderValueParser.models';
import { DateService } from './date.service';
import { Constants } from '../modal/ProjConstants.models';
declare var require: any;
declare var jQuery: any;

@Injectable()
export class ServiceLayer {

  public dotSubmissionDetails = new BehaviorSubject<ResponseContainer>(null);

  public get dotSubmissionDetails$(): Observable<ResponseContainer> {
    return this.dotSubmissionDetails.asObservable();
  }

  public menuClickBehavior = new BehaviorSubject<MenuClickContext>(null);

  public saveDatas = [{ 'order': 1, 'template': 'AccountInfo', 'component': 'policy-holder', 'functionality': 'savePolicyHolder' },
  { 'order': 2, 'template': 'uw', 'component': 'under-writer', 'functionality': 'saveUnderwriter' },
  { 'order': 3, 'template': 'losshistory', 'component': 'loss-history', 'functionality': 'saveLossHistory' },
  { 'order': 4, 'template': 'vehicles', 'component': 'vehicle', 'functionality': 'saveVehicle' },
  { 'order': 5, 'template': 'driver', 'component': 'driver', 'functionality': 'saveDriver' },
  { 'order': 6, 'template': 'product', 'component': 'product', 'functionality': 'saveProduct' },
  { 'order': 7, 'template': 'application', 'component': 'application', 'functionality': 'saveAppInfo' },
  ]

  constructor(private commonService: CommonService,
    public dao: DAO,
    public appComponent: AppComponent,
    private messageService: MessageService,
    public datepipe: DatePipe,
    public inject: Injector,
    public dataChangeService: ChangeDetectionService,
    public fileuploadService: FileuploadService,
    public dateService: DateService,
  ) { }
  // ========= Get DOT # =====================
  public async getDOTNumber(isDOTExists: boolean = false) {
    let payload = { "urlData": [this.dao.userCompany] }
    var clientData: any = await this.commonService.get('policy-holder', 'getDOTNumber', payload).toPromise();
    this.dao.DOTList = ResponseContainer.fromJSON(clientData);
    return this.dao.DOTList;
  }

  // ========= Get DOT service =====================
  public async getDOTDetailsService(payload) {
    return await this.commonService.post('policy-holder', 'getDOTDetails', payload)
      .pipe(
        map((detail: ResponseContainer | any) => {
          if (detail != null && detail.isStatusReceived) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
            detail = null;
          }
          else {
            detail = ResponseContainer.fromJSON(detail);
            this.dao.DOTList = detail;
            this.dao.finalSubmitPage = detail.finalSubmitPage;
          }
          this.dotSubmissionDetails.next(detail);
          return detail;
        })
      )
      .toPromise();
  }


  // ========= Get DOT details =====================
  public async getDOTDetails(IsDOTExists: boolean = false, page: string = 'AccountInfo', IsDeclined: boolean = false) {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, IsDOTExists, page, 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    if (this.dao.DOTList != null && this.dao.DOTList.questionnaire != undefined) {
      return await this.getDOTDetailsService(payload).then(async (data: any) => {
        if (!data) {
          return;
        }
        this.dao.navigatePage = this.dao.finalSubmitPage;

        if (this.dao.DOTList != null) {
          if (!IsDeclined) {
            await this.checkEvaluatequestionaire('LookUpDot');
          }                    
          return data;
        }
      });

    }
  }
  // ========= Check the questionaire =====================
  public async checkEvaluatequestionaire(page, isWIP: boolean = false) {
    //====================get DOT look up decline messages=======================
    this.dao.DOTLookupStatusMsg = [];
    let jsonPathRef = require('jsonpath');
    let IsCancelledDOT = false;
    let IsDeclinedDOT = false;
    if (this.dao.DOTList != null) {
      this.dao["Is" + page + "Entered"] = true;

      let notifyData = jsonPathRef.query(this.dao.DOTList, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
      if (notifyData && notifyData.length > 0 && notifyData.filter(c => c == 'Decline').length > 0) {
        IsDeclinedDOT = true;
      }
      if (notifyData && notifyData.length > 0 && notifyData.filter(c => c == 'Cancel').length > 0) {
        IsCancelledDOT = true;
      }

      let searchQueryForDeclineObj = '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusDesc';
      let declientArrayList = jsonPathRef.query(this.dao.DOTList, searchQueryForDeclineObj);

      let declineObjPath;
      if (declientArrayList && declientArrayList.length > 0) {
        declineObjPath = declientArrayList.filter(C => C.includes('EvaluateSubmission')).length > 0 ? declientArrayList.filter(C => C.includes('EvaluateSubmission'))[0] : declientArrayList[0];
      }
      // declientArrayList.forEach(declineObjPath => {
      if (declineObjPath != null) {
        let declinePathArray = declineObjPath.split(".");
        if (declinePathArray != null && declinePathArray.length > 1) {
          let declineMsgQuery = "$..[?(@.key=='" + declinePathArray[0] + "')].questionAnswer[?(@.key=='" + declinePathArray[1] + "')].answers[?(@.datasource=='Agent')].answer"
          let declineMsgArray = jsonPathRef.query(this.dao.DOTList, declineMsgQuery);
          if (declinePathArray[1] == 'Cab Not Dot Found') {
            declineMsgArray = [];
            declineMsgArray.push(this.dao.DOTList.questionnaire[0].questionAnswer[0].answers.filter(c => c.datasource == 'Agent')[0].answer);
          }
          if (declineMsgArray != null && declineMsgArray.length > 0) {
            this.dao.IsPolicyDateNotified = false;
            if (this.dao.DOTList.questionnaire?.filter(c => c.key == 'EvaluatePolicyHolder')[0]?.questionAnswer?.filter(s => s.key == 'PolicyEffectiveDate' && s.status == 'Notify')?.length > 0) {
              this.dao.IsPolicyDateNotified = true;
            }
            this.dao.DOTLookupStatusMsg.push(declineMsgArray[0]);
            this.dao["Is" + page + "Entered"] = false;
          }
        }
      }
      // });
    }
    //====set template name============
    this.dao.isDOTValid = true;
    if (this.dao.DOTLookupStatusMsg.length == 0 && !isWIP) {
      if (page === 'AccountInfo') {
        this.dao.PolicyTemplate = 'AccountInfo';
      } else if (page === 'uw') {
        this.dao.PolicyTemplate = 'uw';
      } else if (page === 'LossHistory') {
        this.dao.PolicyTemplate = 'losshistory';
      }
      else if (page === 'vehicles') {
        this.dao.PolicyTemplate = 'vehicles';
      }
      else if (page === 'driver') {
        this.dao.PolicyTemplate = 'driver';
      }
      else if (page === 'product') {
        this.dao.PolicyTemplate = 'product';
      }
      else if (page === 'application') {
        this.dao.PolicyTemplate = 'application';
      }
    }
    else if (this.dao.DOTLookupStatusMsg.length > 0 && isWIP) {
      if (page === 'AccountInfo' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'AccountInfo';
      } else if (page === 'UW' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'uw';
      } else if (page === 'LossHistory' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'losshistory';
      }
      else if (page === 'vehicles' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'vehicles';
      }
      else if (page === 'driver' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'driver';
      }
      else if (page === 'product' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'product';
      }
      else if (page === 'application' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'application';
      }
      if (IsCancelledDOT) {
        await this.CancelmySubmission();
      }

      if (!this.dao.IsPolicyDateNotified) {
        document.body.style.pointerEvents = "none";
        let getpopUpVerbiage = 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission';
        if (this.dao.PolicyTemplate == 'AccountInfo'){
          getpopUpVerbiage = this.dao.DOTLookupStatusMsg[0];
        }          
        else if(this.dao.DOTLookupStatusMsg[0].indexOf('decline')>=0)
        {
          getpopUpVerbiage = this.dao.DOTLookupStatusMsg[0];
        }
        let summaryMsg = "";
        if (getpopUpVerbiage.indexOf("declined")>=0)
          summaryMsg = Constants.SUBMISSION_DECLINED;

        //Constants.SUBMISSION_DECLINED is used in PolicyContainer.OK() to redirect to home page
        this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: getpopUpVerbiage, summary: summaryMsg});
        return true;
      }
    }
    else if (this.dao.DOTLookupStatusMsg.length > 0 && !isWIP && (IsDeclinedDOT || IsCancelledDOT)) {
      document.body.style.pointerEvents = "none";
      let msg = this.dao.DOTLookupStatusMsg;
      this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: msg[0], summary: IsCancelledDOT ? Constants.SUBMISSION_CANCELLED : Constants.SUBMISSION_DECLINED });
      this.dao.DOTLookupStatusMsg = [];
      this.dao.isDOTValid = false;
      return true;
    }
    if (page == 'LookUpDot') {
      //code moved to policy holder component
    }
    window.scrollTo(0, 300);
    return true;
  }

  // ======== before save =============
  public async setQuestionnairesData() {
    //set policy holder data has moved to policy holder component
    if (this.dao.PolicyTemplate == 'vehicles') {
      await this.setUnitData();
    }
  }

  // ========== Set vehicle json ===============
  public async setUnitData() {
    let unitJSON = [];
    const uniqueAddInsured: { type: string, name: string }[] = [];
    if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0) {
      this.dao.vehicleModel.VehiclesList.forEach((vehicle, i) => {
        if (vehicle.zoneList && vehicle.zoneList.length > 0) {
          let tempZone = _.cloneDeep(vehicle.vehicleToZone);
          let tempZonePercent = _.cloneDeep(vehicle.vehicleApplicableZonePercent);
          vehicle.zoneList.forEach((z, j) => {
            if (j == 0) {
              vehicle.vehicleToZone.answer = z.zone;
              vehicle.vehicleApplicableZonePercent.answer = z.percentage;
            }
            else {
              let maxindex = j + 1;
              tempZone.answer = z.zone;
              tempZone.rowIndex = maxindex;
              tempZonePercent.answer = z.percentage;
              tempZonePercent.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempZone)
              tempArray.push(tempZonePercent);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        if (vehicle.interestList && vehicle.interestList.length > 0) {
          let tempVehicle = _.cloneDeep(vehicle);
          let tempaddInsuredInterestType = tempVehicle.addInsuredInterestType;
          let tempaddInsuredName = tempVehicle.addInsuredName;
          let tempaddInsuredStreetAddress = tempVehicle.addInsuredStreetAddress;
          let tempaddInsuredCity = tempVehicle.addInsuredCity;
          let tempaddInsuredState = tempVehicle.addInsuredState;
          let tempaddInsuredPostalCode = tempVehicle.addInsuredPostalCode;
          let tempaddInsuredLoanNumber = tempVehicle.addInsuredLoanNumber;
          vehicle.interestList.forEach((z, j) => {
            let rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            //each additional insured provider has its own unique row index
            if (rIndex == 0) {
              uniqueAddInsured.push({ type: z.Type, name: z.Name });
              rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            }
            if (j == 0) {
              vehicle.addInsuredInterestType.answer = z.Type;
              vehicle.addInsuredInterestType.rowIndex = rIndex;
              vehicle.addInsuredName.answer = z.Name;
              vehicle.addInsuredName.rowIndex = rIndex;
              vehicle.addInsuredStreetAddress.answer = z.streetAddress;
              vehicle.addInsuredStreetAddress.rowIndex = rIndex;
              vehicle.addInsuredCity.answer = z.city;
              vehicle.addInsuredCity.rowIndex = rIndex;
              vehicle.addInsuredState.answer = z.state;
              vehicle.addInsuredState.rowIndex = rIndex;
              vehicle.addInsuredPostalCode.answer = z.zip;
              vehicle.addInsuredPostalCode.rowIndex = rIndex;
              vehicle.addInsuredLoanNumber.answer = z.loanNo;
              vehicle.addInsuredLoanNumber.rowIndex = rIndex;
            }
            else {
              tempaddInsuredInterestType.answer = z.Type;
              tempaddInsuredInterestType.rowIndex = rIndex;
              tempaddInsuredName.answer = z.Name;
              tempaddInsuredName.rowIndex = rIndex;
              tempaddInsuredStreetAddress.answer = z.streetAddress;
              tempaddInsuredStreetAddress.rowIndex = rIndex;
              tempaddInsuredCity.answer = z.city;
              tempaddInsuredCity.rowIndex = rIndex;
              tempaddInsuredState.answer = z.state;
              tempaddInsuredState.rowIndex = rIndex;
              tempaddInsuredPostalCode.answer = z.zip;
              tempaddInsuredPostalCode.rowIndex = rIndex;
              tempaddInsuredLoanNumber.answer = z.loanNo;
              tempaddInsuredLoanNumber.rowIndex = rIndex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempaddInsuredInterestType);
              tempArray.push(tempaddInsuredName);
              tempArray.push(tempaddInsuredStreetAddress);
              tempArray.push(tempaddInsuredCity);
              tempArray.push(tempaddInsuredState);
              tempArray.push(tempaddInsuredPostalCode);
              tempArray.push(tempaddInsuredLoanNumber);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        Object.keys(vehicle).forEach(key => {
          if (key != 'group' && key != 'zoneList' && key != 'interestList' && key != 'isReviewed' && typeof (vehicle[key]) == 'object') {
            if (key != 'vehicleToZone' && key != 'vehicleApplicableZonePercent' && key != 'addInsuredInterestType' && key != 'addInsuredName' && key != 'addInsuredStreetAddress' && key != 'addInsuredCity' && key != 'addInsuredState' && key != 'addInsuredPostalCode' && key != 'addInsuredLoanNumber') {
              vehicle[key].rowIndex = i + 1;
            }
            unitJSON.push(vehicle[key]);
          }
          // setting the isreviewed and save to db for showing and hiding Save and Continue button in the screen 
          if (key == 'isReviewed') {
            vehicle.isReviewedModal = new emptyModal();
            vehicle.isReviewedModal.answer = vehicle.isReviewed;
            vehicle.isReviewedModal.group = vehicle['vehicleYear'].group;
            vehicle.isReviewedModal.key = 'isReviewedModal';
            unitJSON.push(vehicle.isReviewedModal)
          }
        });
      });
    }
    let trailerStartingIndex = this.dao.vehicleModel.VehiclesList.length + 1;
    if (this.dao.vehicleModel.trailerList && this.dao.vehicleModel.trailerList.length > 0) {
      this.dao.vehicleModel.trailerList.forEach((trailer, i) => {
        let tempTrailer = _.cloneDeep(trailer);
        if (trailer.zoneList && trailer.zoneList.length > 0) {
          let tempZone = tempTrailer.vehicleToZone;
          let tempZonePercent = tempTrailer.vehicleApplicableZonePercent;
          trailer.zoneList.forEach((z, j) => {
            if (j == 0) {
              trailer.vehicleToZone.answer = z.zone;
              trailer.vehicleApplicableZonePercent.answer = z.percentage;
            }
            else {
              let maxindex = j + 1;
              tempZone.answer = z.zone;
              tempZone.rowIndex = maxindex;
              tempZonePercent.answer = z.percentage;
              tempZonePercent.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempZone);
              tempArray.push(tempZonePercent);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        if (trailer.interestList && trailer.interestList.length > 0) {
          let tempaddInsuredInterestType = tempTrailer.addInsuredInterestType;
          let tempaddInsuredName = tempTrailer.addInsuredName;
          let tempaddInsuredStreetAddress = tempTrailer.addInsuredStreetAddress;
          let tempaddInsuredCity = tempTrailer.addInsuredCity;
          let tempaddInsuredState = tempTrailer.addInsuredState;
          let tempaddInsuredPostalCode = tempTrailer.addInsuredPostalCode;
          let tempaddInsuredLoanNumber = tempTrailer.addInsuredLoanNumber;
          trailer.interestList.forEach((z, j) => {
            let rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            //each additional insured provider has its own unique row index
            if (rIndex <= 0) {
              uniqueAddInsured.push({ type: z.Type, name: z.Name });
              rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            }
            if (j == 0) {
              trailer.addInsuredInterestType.answer = z.Type;
              trailer.addInsuredInterestType.rowIndex = rIndex;
              trailer.addInsuredName.answer = z.Name;
              trailer.addInsuredName.rowIndex = rIndex;
              trailer.addInsuredStreetAddress.answer = z.streetAddress;
              trailer.addInsuredStreetAddress.rowIndex = rIndex;
              trailer.addInsuredCity.answer = z.city;
              trailer.addInsuredCity.rowIndex = rIndex;
              trailer.addInsuredState.answer = z.state;
              trailer.addInsuredState.rowIndex = rIndex;
              trailer.addInsuredPostalCode.answer = z.zip;
              trailer.addInsuredPostalCode.rowIndex = rIndex;
              trailer.addInsuredLoanNumber.answer = z.loanNo;
              trailer.addInsuredLoanNumber.rowIndex = rIndex;
            }
            else {

              tempaddInsuredInterestType.answer = z.Type;
              tempaddInsuredInterestType.rowIndex = rIndex;
              tempaddInsuredName.answer = z.Name;
              tempaddInsuredName.rowIndex = rIndex;
              tempaddInsuredStreetAddress.answer = z.streetAddress;
              tempaddInsuredStreetAddress.rowIndex = rIndex;
              tempaddInsuredCity.answer = z.city;
              tempaddInsuredCity.rowIndex = rIndex;
              tempaddInsuredState.answer = z.state;
              tempaddInsuredState.rowIndex = rIndex;
              tempaddInsuredPostalCode.answer = z.zip;
              tempaddInsuredPostalCode.rowIndex = rIndex;
              tempaddInsuredLoanNumber.answer = z.loanNo;
              tempaddInsuredLoanNumber.rowIndex = rIndex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempaddInsuredInterestType);
              tempArray.push(tempaddInsuredName);
              tempArray.push(tempaddInsuredStreetAddress);
              tempArray.push(tempaddInsuredCity);
              tempArray.push(tempaddInsuredState);
              tempArray.push(tempaddInsuredPostalCode);
              tempArray.push(tempaddInsuredLoanNumber);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        Object.keys(trailer).forEach(key => {
          if (key != 'group' && key != 'zoneList' && key != 'interestList' && key != 'isReviewed' && typeof (trailer[key]) == 'object') {
            if (key != 'vehicleToZone' && key != 'vehicleApplicableZonePercent' && key != 'addInsuredInterestType' && key != 'addInsuredName' && key != 'addInsuredStreetAddress' && key != 'addInsuredCity' && key != 'addInsuredState' && key != 'addInsuredPostalCode' && key != 'addInsuredLoanNumber') {
              trailer[key].rowIndex = i + trailerStartingIndex;
            }
            unitJSON.push(trailer[key]);
          }

          // setting the isreviewed and save to db for showing and hiding Save and Continue button in the screen 
          if (key == 'isReviewed') {
            trailer.isReviewedModal = new emptyModal();
            trailer.isReviewedModal.answer = trailer.isReviewed;
            trailer.isReviewedModal.group = trailer['vehicleYear'].group;
            trailer.isReviewedModal.key = 'isReviewedModal';
            unitJSON.push(trailer.isReviewedModal)
          }
        });
      });
    }
    if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill').length > 0) {
      let bulkdata = this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer?.filter(x => x.key == 'bulkEditRadiusCheckbox' || x.key == 'bulkEditCompCoverageCheckbox' || x.key == 'bulkEditCollCoverageCheckbox'
        || x.key == 'bulkEditGaragingCheckbox' || x.key == 'bulkEditCustomPartsCheckbox' || x.key == 'bulkEditInsuredLossPayeeCheckbox');
      if (bulkdata != null) {
        bulkdata.forEach(element => {
          unitJSON.push(element);
        });
      }
      this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer = _.cloneDeep(unitJSON);
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditRadiusCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditRadiusCheckbox')[0].answer = this.dao.VehicleBulkEditRadiusCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCompCoverageCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCompCoverageCheckbox')[0].answer = this.dao.VehicleBulkEditCompCoverageCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCollCoverageCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCollCoverageCheckbox')[0].answer = this.dao.VehicleBulkEditCollCoverageCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditGaragingCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditGaragingCheckbox')[0].answer = this.dao.VehicleBulkEditGaragingCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCustomPartsCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCustomPartsCheckbox')[0].answer = this.dao.VehicleBulkEditCustomPartsCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditInsuredLossPayeeCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditInsuredLossPayeeCheckbox')[0].answer = this.dao.VehicleBulkEditInsuredLossPayeeCheckbox;
      }
    }
  }
  // ========= Bind Account Info ================
  public async bindAccountInfo(IsDeclined: boolean = false) {
    this.dao.IsAccountDOTBreadcrumpClicked = true;
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    await this.getDOTDetails(true, 'AccountInfo', IsDeclined);
    if (!IsDeclined) {
      this.checkEvaluatequestionaire('AccountInfo', true);
    }
  }
  // ========= Bind UW info ==============
  public async bindUW() {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    //will not save json to database
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, true, 'uw', 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    return await this.getDOTDetailsService(payload).then((data: any) => {
      if (!data) {
        return;
      }
      this.dao.navigatePage = this.dao.finalSubmitPage;

      this.checkEvaluatequestionaire('AccountInfo', true);

      window.scrollTo(0, 300);

      if (this.dao.IsBackButtonClicked == true) {
        this.dao.IsBackButtonClicked = false;
      }
      return data;
    });
  }
  // ========= Bind Loss info ==============
  public async bindLossInfo() {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    //will not save json to database
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, true, 'losshistory', 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    return await this.getDOTDetailsService(payload).then(async (data: any) => {
      if (!data) {
        return;
      }
      this.dao.navigatePage = this.dao.finalSubmitPage;
      if (this.dao.IsBackButtonClicked == true) {
        this.dao.IsBackButtonClicked = false;
      }
      return this.checkEvaluatequestionaire('LossHistory', true);

    });
  }
  // ========= Bind Vehicles =========
  public async bindVehicles() {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    //will not save json to database
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, true, 'vehicles', 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    return await this.getDOTDetailsService(payload).then(async (data: any) => {
      if (!data) {
        return;
      }
      this.dao.navigatePage = this.dao.finalSubmitPage;     
      await this.checkEvaluatequestionaire(this.dao.PolicyTemplate, true);      
      this.dao.initialvehicleModel = _.cloneDeep(this.dao.vehicleModel);
      if (this.dao.IsBackButtonClicked == true) {
        this.dao.IsBackButtonClicked = false;
      }

    });
  }
  // ========= Save MGB ================
  public async bindDatafromWIP(event) {
    this.dao.IsWIPCall = true;
    this.dao.IsDOTExistsInWIP = true;    
    this.dotSubmissionDetails.next(null);
    this.menuClickBehavior.next(null);
    //Clean up Cached ResponseContainer    
    this.dao.PolicyTemplate = event.data.page;
    this.dao.vehicleModel.vehicleItem = new vehicleDetails();
    this.dao.DOTList = await this.getDOTNumber(true);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("accountDot", event.data.dotNumber);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("number", event.data.referenceNumber);
    if (this.dao.PolicyTemplate == 'AccountInfo') {
      await this.bindAccountInfo();
      this.dao.IsAccountDOTBreadcrumpClicked = true;
      // this.dao.IsUWBreadcrumpClicked = true;
      this.dao.IsLossHistoryBreadcrumpClicked = true;
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'uw') {
      await this.bindUW();
      // this.dao.IsUWBreadcrumpClicked = true;
      this.dao.IsLossHistoryBreadcrumpClicked = true;
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
    }
    else if (this.dao.PolicyTemplate == 'losshistory') {
      await this.bindLossInfo();
      if (this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator").answer == 'No') {
        this.dao.IsLossHistoryBreadcrumpClicked = true;
      }
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'vehicles') {
      await this.bindVehicles();
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'driver') {
      await this.bindDriver();
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'product') {
      this.dao.IsAccountStateFreezed = true;
      await this.bindProduct(true);
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'application') {
      this.dao.IsAccountStateFreezed = true;
      await this.bindApplicationInfo(true);
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    this.dao.finalSubmitPage = this.dao.PolicyTemplate;
    this.dao.navigatePage = this.dao.finalSubmitPage;    
    if (this.dao.IsPolicyDateNotified) {
      await this.bindAccountInfo(true);
      this.dao.PolicyTemplate = 'AccountInfo';
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: this.dao.DOTLookupStatusMsg[0] });
    }
    this.setActiveMenu();
  }
  //======== save the policy details ====
  public async save(isFromKarma: boolean = false) {    
      await this.setQuestionnairesData();
      let getTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate) && this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate)[0].order : 0;
      let getFinalTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage) && this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage)[0].order : 0;
      let DotExists: boolean = false;
      if (getTemplateOrder <= getFinalTemplateOrder) {
        DotExists = true;
      }
      let currentTemplate = this.dao.PolicyTemplate;
      let navPage = this.dao.navigatePage;
      this.dao.MenuSavedDOTList = _.cloneDeep(this.dao.DOTList);
      if (currentTemplate == 'losshistory') {
        currentTemplate = 'loss';
      }
      else if (currentTemplate == 'vehicles') {
        currentTemplate = 'vehiclePrefill';
      }
      if (navPage == 'losshistory') {
        navPage = 'loss';
      }
      else if (navPage == 'vehicles') {
        navPage = 'vehiclePrefill';
      }
      if (this.dao?.DOTList?.finalSubmitPage == "AccountInfo") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "uw") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "losshistory") {
        if (currentTemplate == 'vehiclePrefill') {
          _.remove(this.dao.DOTList['questionnaire'], function (n) {
            return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle';
          })
        }
        else {
          _.remove(this.dao.DOTList['questionnaire'], function (n) {
            return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss';
          })
        }
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "vehicles") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle' && n.key != 'vehiclePrefill';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "driver") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle' && n.key != 'vehiclePrefill' && n.key != 'driver';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "product") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle' && n.key != 'vehiclePrefill' && n.key != 'driver' && n.key != 'product';
        })
      }
      //will save json to database
      let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, DotExists, this.dao.PolicyTemplate, 'dot', false, true], "payloadData": this.dao.DOTList }
      const response = await this.SaveDetails(payload);
      this.setActiveMenu();
      return response;
   
    
  }


  public checkApplicationResult(data): void {
    let jsonPathRef = require('jsonpath');
    let hasDeclinedNotification = false;
    let notifyData = jsonPathRef.query(data, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
    if (!!notifyData && notifyData.filter(c => c == 'Decline').length > 0) {
      hasDeclinedNotification = true;
    }
    if (hasDeclinedNotification) {
      let searchQueryForDeclineObj = '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusDesc';
      let declineArrayList = jsonPathRef.query(data, searchQueryForDeclineObj);
      let declineObjPath = declineArrayList.find(c => c.includes('EvaluateSubmission'))
      let validationMessage = "";
      if (declineObjPath != null) {
        let declinePathArray = declineObjPath.split(".");
        if (!!declinePathArray && declinePathArray.length > 1) {
          let declineMsgQuery = "$..[?(@.key=='" + declinePathArray[0] + "')].questionAnswer[?(@.key=='" + declinePathArray[1] + "')].answers[?(@.datasource=='Agent')].answer"
          let declineMsgArray = jsonPathRef.query(data, declineMsgQuery);
          if (declineMsgArray != null && declineMsgArray.length > 0) {
            validationMessage = declineMsgArray[0];
          }
        }
      }
      if (!!!validationMessage) {
        this.appComponent.displayErrorPopupifAnyHitFails();
      } else {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: validationMessage, summary: Constants.SUBMISSION_DECLINED });
      }
    } else {
      let submissionNo = jsonPathRef.query(data, '$..questionnaire[?(@.key =="application")].questionAnswer[?(@.key =="submissionNumber")].answer')[0];
      if (!!submissionNo) {
        let details = 'Thank you for choosing Berkley Small Business Solutions for your transportation needs. This submission has been cleared for your agency. You will be receiving cleared submission confirmation email with the trucking application attached.'
        this.messageService.add({ key: 'confirmation', sticky: true, severity: 'success', data: details });
      } else {
        if (data.status == '500') {
          this.appComponent.displayErrorPopupifAnyHitFails();
        }
      }
    }
  }

  // ========= Get Loss History =====================
  public async getAgencyBranchCode(payload) {
    return await this.commonService.get('application', 'getAgencyandBranchCode', payload).toPromise();
  }
  // ============== Upload Document =============
  public async uploadDocument(ClientID, guid, fileName, docType) {
    let payload = { "urlData": [ClientID, guid, fileName, docType] }
    return await this.commonService.post('application', 'uploadDocument', payload).toPromise();
  }
  // ============ Save Datas===============
  public async SaveDetails(payload) {
    if (this.dao.NotAllowedToSubmit) {
      return false;
    }
    if (this.dao.IsPolicyDateNotified) {
      this.dao.IsPolicyDateNotified = false;
    }
    this.modifyDateObject(payload);
    this.dao.IsValidationCheck = false;
    let getTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate) && this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate)[0].order : 0;
    let getFinalTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage) && this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage)[0].order : 0;
    if ((getTemplateOrder >= getFinalTemplateOrder)
      || (this.saveDatas[getTemplateOrder].template == 'AccountInfo' && !this.dao.IsAccountDOTBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'uw' && !this.dao.IsUWBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'losshistory' && !this.dao.IsLossHistoryBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'vehicles' && !this.dao.IsVehicleBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'driver' && !this.dao.IsDriverBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'product')
      || (this.saveDatas[getTemplateOrder].template == 'application')
    ) {
      this.dao.isSubmitForm = false;
      if (this.dao.PolicyTemplate == "AccountInfo") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data) {
            if (data.isStatusReceived != "" && data.isStatusReceived != null) {
              this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
            } else {
              this.dao.DOTList = data;
              this.dao.finalSubmitPage = data.finalSubmitPage;
              this.dao.navigatePage = this.dao.finalSubmitPage;
              await this.checkEvaluatequestionaire('uw');
              if (this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator")?.answer === 'Yes') {
                let isProductExists = this.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="product")]');
                if (isProductExists?.length > 0 && !!!this.dao["isBusinessClassChanged"]) {
                  // need to confirm what to do
                }
              }
              window.scrollTo(0, 300);
              if (this.dao.isClickFromMenu && this.dao.DOTList.currentPage != 'Decline') {
                this.dao.navigatePage = this.dao.finalSubmitPage;
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              }
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;                            
              if (this.dao.IsBackButtonClicked == true) {
                this.dao.IsBackButtonClicked = false;
              }
              return data;
            }
          }
        });
      }
      else if (this.dao.PolicyTemplate == "uw") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data) {
            if (data.isStatusReceived != "" && data.isStatusReceived != null) {
              this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
            } else {
              this.dao.DOTList = data;
              this.dao.finalSubmitPage = data.finalSubmitPage;
              this.dao.navigatePage = this.dao.finalSubmitPage;
              let nextPage = 'LossHistory';
              if (this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == "AccountInfo")
                nextPage = "AccountInfo"; 
              await this.checkEvaluatequestionaire(nextPage);
              if (this.dao.isClickFromMenu && !this.dao.IsBackButtonClicked) {
                this.dao.navigatePage = this.dao.finalSubmitPage;
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              }
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;              
              if (this.dao.IsBackButtonClicked == true) {
                this.dao.IsBackButtonClicked = false;
                this.dao.navigatePage = this.dao.finalSubmitPage;                
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
                this.dao.IsAccountDOTBreadcrumpClicked = true;
              }
            }
          }
        });
      }
      else if (this.dao.PolicyTemplate == "losshistory") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            
            // this.dao.PolicyTemplate = "vehicles"
            this.dao.DOTList = data;
            this.dao.finalSubmitPage = data.finalSubmitPage;
            this.dao.navigatePage = this.dao.finalSubmitPage;
            let nextPage = 'vehicles';
            if (this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == "uw")
              nextPage = "losshistory";
            await this.checkEvaluatequestionaire(nextPage);            
            window.scrollTo(0, 300);
            // this.dao.finalSubmitPage = this.dao.PolicyTemplate;
            if (this.dao.isClickFromMenu) {
              this.dao.navigatePage = this.dao.finalSubmitPage;
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
            }
            this.dao.isSubmitForm = false;
            this.dao.isClickFromMenu = false;                        

            if (this.dao.IsBackButtonClicked == true) {
              this.dao.IsBackButtonClicked = false;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.IsUWBreadcrumpClicked = true;
            }
          }

        });
      }
      else if (this.dao.PolicyTemplate == "vehicles") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            this.dao.DOTList = data;
            this.dao.finalSubmitPage = data.finalSubmitPage;
            this.dao.navigatePage = this.dao.finalSubmitPage;
            await this.checkEvaluatequestionaire('driver');
            if (this.dao.isClickFromMenu) {
              this.dao.navigatePage = this.dao.finalSubmitPage;
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
            }
            this.dao.isSubmitForm = false;
            this.dao.isClickFromMenu = false;            
            if (this.dao.IsBackButtonClicked == true) {
              this.dao.IsBackButtonClicked = false;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.IsLossHistoryBreadcrumpClicked = true;
            }
          }

        });
      }
      else if (this.dao.PolicyTemplate == "driver") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            this.dao.DOTList = data;
            this.dao.finalSubmitPage = data.finalSubmitPage;
            this.dao.navigatePage = this.dao.finalSubmitPage;
            await this.bindProduct(false);
            let nextPage = 'product';
            if (this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == "vehicles")
              nextPage = "driver";
            await this.checkEvaluatequestionaire(nextPage);
            if (this.dao.isClickFromMenu) {
              this.dao.navigatePage = this.dao.finalSubmitPage;
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
            }
            this.dao.isSubmitForm = false;
            this.dao.isClickFromMenu = false;            

            if (this.dao.IsBackButtonClicked == true) {
              this.dao.IsBackButtonClicked = false;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.IsVehicleBreadcrumpClicked = true;
            }
          }
        });
      }
      else if (this.dao.PolicyTemplate == "product") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            this.dao.IsAccountStateFreezed = true;
            if (data != null) {
              this.dao.DOTList = data;
              this.dao.finalSubmitPage = data.finalSubmitPage;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              await this.checkEvaluatequestionaire('application');
              if (this.dao.isClickFromMenu) {
                this.dao.navigatePage = this.dao.finalSubmitPage;
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              }
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;              
              if (this.dao.IsBackButtonClicked == true) {
                this.dao.IsBackButtonClicked = false;
                this.dao.navigatePage = this.dao.finalSubmitPage;                
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
                this.dao.IsDriverBreadcrumpClicked = true;
              }
            }
          }
        });
      }
      else {
        window.scrollTo(0, 300);
        return true;
      }
    }
    else {
      if ((this.dao.PolicyTemplate == 'AccountInfo') || this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == 'AccountInfo')
         {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (MGBdata: any) => {
          if (MGBdata) {
            let IsDeclinedDOT = false;
            this.dao.DOTList.msgStatus = MGBdata.msgStatus;
            let jsonPathRef = require('jsonpath');
            let searchQueryForDeclineObj = '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusDesc';
            let declientArrayList = jsonPathRef.query(MGBdata, searchQueryForDeclineObj);
            let declineObjPath;
            let notifyData = jsonPathRef.query(MGBdata, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
            if (notifyData && notifyData.length > 0 && notifyData.filter(c => c == 'Decline').length > 0) {
              IsDeclinedDOT = true;
            }
            if (declientArrayList && declientArrayList.length > 0) {
              declineObjPath = declientArrayList.filter(C => C.includes('EvaluateSubmission')).length > 0 ? declientArrayList.filter(C => C.includes('EvaluateSubmission'))[0] : declientArrayList[0];
            }
            if (declineObjPath != null) {
              let declinePathArray = declineObjPath.split(".");
              if (declinePathArray != null && declinePathArray.length > 1) {
                let declineMsgQuery = "$..[?(@.key=='" + declinePathArray[0] + "')].questionAnswer[?(@.key=='" + declinePathArray[1] + "')].answers[?(@.datasource=='Agent')].answer"
                let declineMsgArray = jsonPathRef.query(MGBdata, declineMsgQuery);
                if (declinePathArray[1] == 'Cab Not Dot Found') {
                  declineMsgArray = [];
                  declineMsgArray.push(MGBdata.questionnaire[0].questionAnswer[0].answers.filter(c => c.datasource == 'Agent')[0].answer);
                }
                if (declineMsgArray != null && declineMsgArray.length > 0) {
                  this.dao.IsPolicyDateNotified = false;
                  if (MGBdata.questionnaire?.filter(c => c.key == 'EvaluatePolicyHolder')[0]?.questionAnswer?.filter(s => s.key == 'PolicyEffectiveDate' && s.status == 'Notify')?.length > 0) {
                    this.dao.IsPolicyDateNotified = true;
                  }
                  this.dao.DOTLookupStatusMsg.push(declineMsgArray[0]);
                }
              }
            }
            if (this.dao.DOTLookupStatusMsg.length > 0 && IsDeclinedDOT) {
              document.body.style.pointerEvents = "none";
              let msg = this.dao.DOTLookupStatusMsg;
              this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: msg[0] });
              this.dao.DOTLookupStatusMsg = [];
              this.dao.isDOTValid = false;
              this.dao.DOTList = MGBdata;
            }
            else if (this.dao.isClickFromMenu) {
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;
            }
            else {
              this.dao.PolicyTemplate = 'uw';
            }
            return true;
          }
        });
      }
      if (this.dao.isClickFromMenu) {
        this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
        this.dao.isSubmitForm = false;
        this.dao.isClickFromMenu = false;
      }
      else {
        this.dao.PolicyTemplate = this.saveDatas[getTemplateOrder].template;
      }
      this.dao.DOTList = _.cloneDeep(this.dao.MenuSavedDOTList);      
      window.scrollTo(0, 300);
      return true;
    }
  }
  public modifyDateObject(payload): void {
    let dateFields = this.getObjUsingJpath(payload, '$..[?(@.type =="Date" || @.key == "dob")]');
    if (!!dateFields && dateFields.length > 0) {
      dateFields.forEach(date => {
        if (!!date.answer && !isNaN(new Date(this.datepipe.transform((date.answer), 'MM-dd-yyyy')).getTime())) {
          date.answer = this.datepipe.transform(new Date(this.datepipe.transform((date.answer), 'MM-dd-yyyy')), 'yyyy-MM-dd');
        }
      })
    }
  }
  //========get object based on query=======
  public getObjUsingJpath(obj, query) {
    let jsonPathRef = require('jsonpath');
    if (obj != null) {
      let jPathObj = jsonPathRef.query(obj, query);
      return jPathObj;
    }
    return;
  }
  // ========= Get MGB Info service =====================
  public async savePayLoadAndGetMGBInfoFromService(payload) {
    return await this.commonService.post('policy-holder', 'getDOTDetails', payload)
      .pipe(
        map(
          (detail: ResponseContainer | any) => {
            let response = ResponseContainer.fromJSON(detail);
            this.dao.DOTList = response;
            this.dotSubmissionDetails.next(response);
            return response;
          })
      ).toPromise();
  }
  // ========= Save Details =====================
  public async SavePayloadToAPI(payload) {
    let Details = this.saveDatas.filter(c => c.template == this.dao.PolicyTemplate)[0];
    return await this.commonService.post(Details.component, Details.functionality, payload).toPromise();
  }
  // ========= Check date validation =====================
  public checkDateValidation() {
    let effectiveDateQ: QuestionAnswer = (<ResponseContainer>this.dao.DOTList).accountInfoQsnr.findQuestion("policyEffectiveDate");
    let minEffDate: Date = this.getCorrectDate(effectiveDateQ.minimum);
    let maxEffDate: Date = this.getCorrectDate(effectiveDateQ.maximum);
    let effDate = this.getCorrectDate(effectiveDateQ.answer);
    let minExpDate: Date = new Date(minEffDate.getFullYear() + 1, minEffDate.getMonth(), minEffDate.getDate());
    let maxExpDate: Date = new Date(maxEffDate.getFullYear() + 1, maxEffDate.getMonth(), maxEffDate.getDate());
    let expirationDateQ: QuestionAnswer = (<ResponseContainer>this.dao.DOTList).accountInfoQsnr.findQuestion("policyExpirationDate");
    let expDate = this.getCorrectDate(expirationDateQ.answer);
    if (minEffDate <= effDate && maxEffDate >= effDate &&
      minExpDate <= expDate && maxExpDate >= maxExpDate) {
      return true;
    }
    return false;
  }


  // ========= Numbers only input =====================
  public numbersOnly(e, pageFrom: string = '') {
    const pattern = /^[0-9]/;
    var key = e.keyCode || e.which;
    if (key == 8 || key == 9 || key == 11 || key == 27
      || (key == 39 && pageFrom != 'loss' && (e.currentTarget.name != 'txtVCYear' && e.currentTarget.name != 'fiftyRadiusPercentage' && e.currentTarget.name != 'fifty200RadiusPercentage' && e.currentTarget.name != 'liabIndividualLosses'))
      || (key == 46 && pageFrom != 'loss' && pageFrom != 'vehicle' && pageFrom != 'dot' && e.currentTarget.name != 'liabIndividualLosses')) {
      return true;
    }
    let inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  }

  // ========= Validate mail id =====================
  public validateEmails(string) {
    if (!(string == null || string == undefined || string.trim() == "")) {
      var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      var result = string.replace(/\s/g, "").split(/,|;/);
      for (var i = 0; i < result.length; i++) {
        if (!regex.test(result[i])) {
          return false;
        }
      }
      return true;
    }
    else {
      return false;
    }
  }

  // ============= Get Work in Progress
  public async getWorkInProgress() {
    let payload = { "urlData": [this.dao.userMail] }
    if (this.dao.userMail != null && this.dao.userMail != '') {
      return await this.commonService.get('workInProgress', 'getWorkInProgress', payload).toPromise().then((data: any) => {
        this.dao.SubmissionList = data as workInProgress[];
      });
    }
  }
  // ============= Get Garaging Address ==========
  public createNewGaragingAddressFromAccountAddress(group: string = ''): emptyModal {
    let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
    let accountInfoQsnrValue = new PolicyHolderValueParser(accountInfoQsnr);
    let garagingAddress: emptyModal = new emptyModal();
    garagingAddress.key = 'vehicleGaragingAddress';
    garagingAddress.answer = accountInfoQsnrValue.accountAddress.toString();
    garagingAddress.group = group != '' ? group : 'vehicle';
    return garagingAddress;
  }

  // ========= Cancel my submission=====================
  public async CancelmySubmission() {
    let dotNumber = this.dao.DOTList.dotQsnr.findQuestion("accountDot")?.answer;
    let dotOrRefNumber = this.dao.DOTList.dotQsnr.findQuestion("number")?.answer;
    if (!!!dotOrRefNumber)
      dotOrRefNumber = dotNumber
    let payload = { "urlData": [dotOrRefNumber, this.dao.userMail, 'Cancelled'] }
    return await this.commonService.get('Policy', 'CancelPolicy', payload).toPromise().then((data: any) => {
      this.dao.PolicyTemplate = '';
      this.dao.PolicySubTemplate = 'newsubmission';
      this.dao.vehicleModel = new VehicleModel();     
      window.scrollTo(0, 0);
    });
  }
  // ========= Add the vehicle=====================
  public async addVehicle(type, from: string = ''): Promise<vehicleDetails> {
    var lastVehicleIndex = 0
    this.dao.vehicleModel.VehiclesList.forEach((vehicle, index) => {
      Object.keys(vehicle).forEach(tempKey => {
        if (typeof (vehicle[tempKey]) == 'object') {
          vehicle[tempKey].group = ('VEH-' + (index + 1));
        }
      });
      lastVehicleIndex = index + 1;
    });
    var lastTrailerIndex = 0
    this.dao.vehicleModel.trailerList.forEach((trailer, index) => {
      Object.keys(trailer).forEach(tempKey => {
        if (typeof (trailer[tempKey]) == 'object') {
          trailer[tempKey].group = ('TRA-' + (index + 1));
        }
      });
      lastTrailerIndex = index + 1;
    });

    let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
    let accountInfoQsnrValue = new PolicyHolderValueParser(accountInfoQsnr);

    var newVehicle: vehicleDetails = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);
    newVehicle.vehicleYear.answer = "";
    newVehicle.vehicleMake.answer = "";
    newVehicle.vehicleModel.answer = "";
    newVehicle.vehicleVin.answer = "";
    newVehicle.vehicleGaragingAddress = this.createNewGaragingAddressFromAccountAddress('VEH-' + (this.dao.vehicleModel.VehiclesList.length + 1));
    newVehicle.vehicleGarageState.answer = accountInfoQsnrValue.accountAddress.state;
    newVehicle.vehicleGarageStreetAddress.answer = accountInfoQsnrValue.accountAddress.street;
    newVehicle.vehicleGarageCity.answer = accountInfoQsnrValue.accountAddress.city;
    newVehicle.vehicleGarageState.answer = accountInfoQsnrValue.accountAddress.state;
    newVehicle.vehicleGaragePostalCode.answer = accountInfoQsnrValue.accountAddress.postalCode;
    newVehicle.vehicleType.answer = type != 'vehicle' ? 'Unknown' : '';
    newVehicle.zoneList = [{ 'zone': '', 'percentage': '', 'helpText': '' }];
    newVehicle.interestList = [{ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' }];
    Object.keys(newVehicle).forEach(tempKey => {
      if (typeof (newVehicle[tempKey]) == 'object') {
        newVehicle[tempKey].group = type == 'vehicle' ? ('VEH-' + (lastVehicleIndex + 1)) : ('TRA-' + (lastTrailerIndex + 1));
        if (newVehicle[tempKey].questionOptions != null && newVehicle[tempKey].questionOptions.length > 0) {
          newVehicle[tempKey].questionOptions = []; //empty question options to reduce payload size
        }
        if(tempKey == 'vehicleToZone')
        {
            newVehicle[tempKey].questionOptions = [];
        }
      }
    });
    if (type == 'trailer') {
      this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerList.length;
      if (this.dao.vehicleModel.trailerList.length > 0 && this.dao.vehicleModel.trailerList.every(item => item.isChecked)) {
        newVehicle.isChecked = true;
      }
      this.dao.vehicleModel.trailerList.push(newVehicle)
      this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex + (!this.dao.isTrailer && this.dao.vehicleModel.vehiclePage == 3 ? 1 : 0);
    }
    else {
      this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.VehiclesList.length;
      if (this.dao.vehicleModel.VehiclesList.length > 0 && this.dao.vehicleModel.VehiclesList.every(item => item.isChecked)) {
        newVehicle.isChecked = true;
      }
      this.dao.vehicleModel.VehiclesList.push(newVehicle)
      this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex + (this.dao.isTrailer && this.dao.vehicleModel.vehiclePage == 3 ? 1 : 0);
    }
    this.dao.vehicleModel.vehiclePage = 1
    this.dao.vehicleModel.vehicleItem = type == 'vehicle' ? this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] : this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex];
    this.dao.isTrailer = type != 'vehicle';
    return newVehicle;
  }
  // ========= Get Post Registration =====================
  public async postRegistration() {
    this.dao.RegistrationModal.adminEmail = this.dao.userMail;
    this.dao.RegistrationModal.email = this.dao.RegistrationModal.email.trim();
    let payload = { "urlData": [this.dao.userCompany, this.dao.RegistrationModal.agentCode], "payloadData": this.dao.RegistrationModal }
        this.registration(payload).then(async (event: any) => {
          if (event && event.isSuccess == true) {
            this.appComponent.registrationSuccessPopup(event?.responseText);
            await this.GetAllUsers();
            return true;
          }
          else {
            this.appComponent.registrationMsgPopup(event?.responseText);

          }
          return event.responseText;
        });
  }
  // ========= register the agent ===========
  public async registration(payload) {
    return await this.commonService.post('Registration', 'PostRegistration', payload).toPromise();
  }
  // ========= Check User Claims =====================
  public async CheckUserClaims(mail) {
    let payload = { "urlData": [this.dao.userCompany, mail] };
    let event: HttpResults = await this.commonService.get('Login', 'CheckUser', payload).toPromise();
    if (event && event.isSuccess == false) {
      this.appComponent.registrationMsgPopup(event?.responseText, true);
    }
    else {
      this.dao.UserRole = event?.role;
      this.dao.IsEndorsementActive = event?.isEndorsementActive;
      this.dao.IsInternalUser = event?.isInternalUser;
      this.dao.userAgencyCode = event?.agencyCode;
    }

  }
  // ============= Get Agency List ==========
  public async GetAgencyList(query) {
    let payload = { "urlData": [query] };
    return await this.commonService.get('Registration', 'GetAgencyName', payload).subscribe((event: any) => {
      if (event && event.status != 500) {
        this.dao.RegistrationModal.AgencyCodeList = [];
        this.dao.RegistrationModal.AgencyList = event;
        if (this.dao.RegistrationModal.AgencyList.code != null && this.dao.RegistrationModal.AgencyList.code != undefined) {
          this.dao.RegistrationModal.AgencyCodeList.push(this.dao.RegistrationModal.AgencyList.code);
        }
      }
    });
  }

  public async bindDriver(from: string = '') {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    if (from == '') {
      await this.getDOTDetails(true, 'driver');
      this.checkEvaluatequestionaire(this.dao.PolicyTemplate, from == '');
    }
    if (this.dao.IsBackButtonClicked == true) {
      this.dao.IsBackButtonClicked = false;
    }
  }
  public async bindProduct(isWIP: boolean = false) {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    console.log(isWIP, 'isWIP')
    if (isWIP == true) {
      await this.getDOTDetails(true, 'product');
      this.checkEvaluatequestionaire(this.dao.PolicyTemplate, isWIP);
    }

 
    if (this.dao.IsBackButtonClicked == true) {
      this.dao.IsBackButtonClicked = false;
    }
    
  }

  public async bindApplicationInfo(isWIP: boolean = false) {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    if (isWIP == true) {
      await this.getDOTDetails(true, 'application');
    }
    this.dao.IsBackButtonClicked = false;
  }


  // ====== Set Drodown options for view PDF =======
  public async setDropdownOptions() {
    let dropdownsList = ['vehicleSize', 'trailerSize', 'vehicleBodyType', 'vehicleSecondaryClass', 'vehicleToZone', 'vehicleComp', 'vehicleColl', 'addInsuredInterestType', 'addInsuredState']
    dropdownsList.forEach(key => {
      let OptionsList = this.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='vehiclePrefill')].questionAnswer[?(@.key == '" + key + "')]");
      if (OptionsList && OptionsList?.length > 0) {
        OptionsList.forEach(zone => {
          let questionOpt = _.cloneDeep(this.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='vehicle')].questionAnswer[?(@.key == '" + key + "')]"));
          if (questionOpt && questionOpt?.length > 0) {
            zone.questionOptions = questionOpt[0].questionOptions;
            zone.type = "Select";
          }
        });
      }
    });
    let payrollAmountList = this.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='product')].questionAnswer[?(@.key == 'payrollAmount')]");
    if (payrollAmountList && payrollAmountList.length > 0) {
      payrollAmountList.forEach(c => {
        if (!isNaN(Number(c.answer))) {
          c.answer = Number(c.answer).toLocaleString()
        }
      });
    }
  }
  // ===== Get Date ===========
  public getCorrectDate(date) {
    let correctDate = date;
    if (date && date != '' && typeof (date) == 'string' && date.split('-').length > 0) {
      let year = Number(date.split('-')[0]);
      let month = Number(date.split('-')[1]);
      let day = Number(date.split('-')[2]);
      if (year != undefined && !isNaN(year) && month != undefined && !isNaN(month) && day != undefined && !isNaN(day)) {
        correctDate = new Date(year, month - 1, day);
      }
    }
    return correctDate;
  }


  // get Screen Order
  public getPageOrder(page) {
    let getTemplateOrder = (this.saveDatas.filter(s => s.template == page) && this.saveDatas.filter(s => s.template == page).length > 0) ? this.saveDatas.filter(s => s.template == page)[0].order : 0;
    return getTemplateOrder;
  }

  // check form valid
  formValidation(form) {
    let formData = _.cloneDeep(form);
    if (formData && formData._directives && formData._directives.length > 0) {
      formData._directives.forEach(f => {
        if (f && f.value && typeof (f.value) == "string" && f.value.trim() == '' && f.validator("required") && f.validator("required").required) {
          f.reset();
        }
      })
    }
    return formData;
  }
  validateRequiredFieldAnsweredAllForms(forms: NgForm[]): boolean {
    let i = 0;
    for (i = 0; i < forms.length; i++) {
      if (!this.validateRequiredFieldAnswered(forms[i]))
        return false;
    }
    return true;
  }

  validateRequiredFieldAnswered(form): boolean {
    let formData = _.cloneDeep(form);
    let returnval: boolean = true;
    if (formData && formData._directives && formData._directives.length > 0) {
      for (const f of formData._directives) {
        if (typeof (f.value) == "string" && f.value.trim() == ''
          && f.control.validator != null && f.validator("required")
          && f.validator("required").required) {
          f.reset();
          returnval = false;
        }
      }
    }
    return returnval;
  }

  public UpdateFiledsValuesAndValidate(form): boolean {
    if (form == undefined)
      return true;
    if (form && form._directives && form._directives.length > 0) {
      for (const f of form._directives) {
        f.control?.updateValueAndValidity();
      }
    }
    return form?.valid;
  }

  // Restrict the Auto fill
  public restrictAutoFill() {
    if (Object.keys($('input'))) {
      Object.keys($('input')).map(con => {
        if ($('input')[con].name == 'vehicleStatedAmount') {
          $('input')[con].setAttribute("autocomplete", "none")
          $('input')[con].setAttribute("autocomplete", "off")
        }
        if (con != 'length' && con != 'prevObject' && $('input')[con].type == 'text' && $('input')[con].id.includes('calendarIcon') != true
          && $('input')[con].id != 'txtCityID') { // it's should change type property only for text controls not calendar and file upload
          $('input')[con].setAttribute("autocomplete", "off")
          $('input')[con].setAttribute("type", "search")
        }

      });
    }
  }
  public async menuClick(menuContext) {
    this.dao.IsSavedFromMenu = false;
    let jsonPathRef = require('jsonpath');
    let dotNumber = this.dao.DOTList.dotQsnr.findQuestion("accountDot")?.answer;
    let CurrentSubmissionStatus = jsonPathRef.query(this.dao, '$..SubmissionList[?(@.dotNumber =="' + dotNumber + '")].status')[0];
    let page: string = menuContext.page;
    if(!!menuContext["hasInvalidPowerUnitAge"] || !!menuContext["hasInvalidPowerUnitAgeInSaveAs"])
      {
          this.messageService.add({
            key: 'brownWarningPopupWithOk',
            closable: true, sticky: true,
            severity: 'warn',
            data: 'hasInvalidPowerUnitAge',
            detail: 'Your submission will be declined because of vehicle age profile.<br>Click <strong>Save & Continue</strong> button to confirm your changes.' 
          });
          return false; //Shall not move backward when InvalidPowerUnitAge
      }
    if (menuContext.currentPageIsValid && !menuContext.currentPageDataChanged){
      //Page is valid and no data change, go to clicked page without saving
      this.dao.PolicyTemplate = page;
      this.dao.IsBackButtonClicked = false;
      this.setActiveMenu(page);
      window.scrollTo(0, 300);
      return false;
    }
    else if (menuContext.currentPageDataChanged)
    {
      /*
        page has data change, but we do not know page is valid or not
        Call Save and Continue button, and try to save the data
        1)If page is saved, then this.dao.NotAllowedToSubmit = false, redirect to clicked page
        2)If page is not allowed to save, then 
        -- this.dao.NotAllowedToSubmit = true,
        -- Ask user to confirm data loss
        -- Continue to clicked page if user answer Yes, or stay at the current page if user answers No
     */
        this.dao.clickedPage = page;
        this.dao.ClickedPageFromMenuPage = page;
        this.dao.isClickFromMenu = true;
        let currentPageOrder = this.getPageOrder(this.dao.PolicyTemplate);
        let clickedPage = this.getPageOrder(page);
        this.dao.IsSavedFromMenu = currentPageOrder >= clickedPage;
        if (this.dao.PolicyTemplate != "AccountInfo")
          this.dao.NotAllowedToSubmit = false;

        let submitButton = document.getElementById("btnsaveContinueID") as HTMLElement;
        if (!!submitButton) {
          submitButton.click();
          setTimeout(() => {
            this.validateSubmittedData();
          }, 500);
        } else {
          if (this.dao.PolicyTemplate == 'vehicles') {
            this.throwVehicleValidationPopup();
          }
        }
        return false;
    }    
    else{
      //No data change, and page is invalid. May only happen when the page is loaded from Work in Progress
      this.dao.NotAllowedToSubmit = true;
      this.dao.clickedPage = page;
      this.dao.ClickedPageFromMenuPage = page;
      this.dao.isClickFromMenu = true;
      let currentPageOrder = this.getPageOrder(this.dao.PolicyTemplate);
      let clickedPage = this.getPageOrder(page);
      this.dao.IsSavedFromMenu = currentPageOrder >= clickedPage;
      if(this.dao.PolicyTemplate == 'vehicles' && !menuContext.currentPageDataChanged )
        {
            this.dao.NotAllowedToSubmit = false;// vehicle page is saved as it is, and users can move back without not saved warning
            if (clickedPage > currentPageOrder )
            {
              // users cannot move forward, click the Save and Continue button to validate page data.
              let submitButton = document.getElementById("btnsaveContinueID") as HTMLElement;
              if (!!submitButton) {
                submitButton.click();
                setTimeout(() => {
                  this.validateSubmittedData();
                }, 500);
              } 
            }            
        }
      this.validateSubmittedData();
    } 
  }
  public async validateSubmittedData() {
    if (this.dao.NotAllowedToSubmit) {
      this.dao.NotAllowedToSubmit = false;
      this.dao.IsSavedFromMenu = false;
      this.dao.isEmptyListFromMenu = true;
      this.dao.clickedPage = this.dao.ClickedPageFromMenuPage;
      document.body.style.pointerEvents = "none";
      //Each page's onRollback event will be fired if user answer Yes.
      this.messageService.add({ key: 'madeChange', sticky: true, severity: 'warn', summary: "If you go back, any data entered will be lost.  Are you sure you would like to go back?" });
    }
    else {
      if (this.dao.IsSavedFromMenu) {
        this.dao.IsSavedFromMenu = false;
        this.setActiveMenu(this.dao.ClickedPageFromMenuPage);
        this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;       
      }
    }
  }

  public openStreamPDF(streamString) {
    var arrrayBuffer = this.base64ToArrayBuffer(streamString);
    var blob = new Blob([arrrayBuffer], { type: "application/pdf" });
    var link = window.URL.createObjectURL(blob);
    window.open(link, '', 'height=650,width=840');
  }
  public base64ToArrayBuffer(streamString) {
    var binaryString = window.atob(streamString);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  // To Add Space after the number in Address field.
  public ValidateAddress(event, value) {
    let IsvalueHasnum = /^\d+$/.test(value.trim());
    if (value && value != '' && (!this.hasWhiteSpace(value) || value.trim().length == 1) && IsvalueHasnum) {
      let inputChar = String.fromCharCode(event.charCode);
      // To check the string has only numbers
      let isnum = /^\d+$/.test(value + inputChar);
      if (!isnum) {
        if (inputChar != ' ') {
          value = value + ' ';
          return value;
        }
      }
    }
    return null;
  }

  // To check the string has empty space, if yes it returns true else false.
  public hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  // bind vehicle garaging address
  async bindVehicleGaragingAddress() {
    let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
    let accountAddress = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;
    this.dao?.vehicleModel?.VehiclesList?.forEach((vehicle, index) => {
      let garagingAddressChecked = vehicle.vehicleIsCorrectGaragingAddress.answer;
      Object.keys(vehicle).forEach(tempKey => {
        if (typeof (vehicle[tempKey]) == 'object') {
          vehicle[tempKey].group = ('VEH-' + (index + 1));
          if (tempKey == "vehicleGaragingAddress") {
            vehicle[tempKey].answer = accountAddress.toString();
          }
          else if (tempKey == 'vehicleGarageStreetAddress' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.street;
          }
          else if (tempKey == 'vehicleGarageCity' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.city;
          }
          else if (tempKey == 'vehicleGarageState' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.state;
          }
          else if (tempKey == 'vehicleGaragePostalCode' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.postalCode;
          }
        }
      });
    });

    this.dao?.vehicleModel?.trailerList?.forEach((trailer, index) => {
      let garagingAddressChecked = trailer.vehicleIsCorrectGaragingAddress.answer;
      Object.keys(trailer).forEach(tempKey => {
        if (typeof (trailer[tempKey]) == 'object') {
          trailer[tempKey].group = ('TRA-' + (index + 1));
          if (tempKey == "vehicleGaragingAddress") {
            trailer[tempKey].answer = accountAddress.toString();
          }
          else if (tempKey == 'vehicleGarageStreetAddress' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.street;
          }
          else if (tempKey == 'vehicleGarageCity' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.city;
          }
          else if (tempKey == 'vehicleGarageState' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.state;
          }
          else if (tempKey == 'vehicleGaragePostalCode' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.postalCode;
          }
        }
      });
    });

    if (this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == null || this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == undefined) {
      this.dao.editAllUnits.vehicleGarageStreetAddress.answer = accountAddress.street;
      this.dao.editAllUnits.vehicleGarageState.answer = accountAddress.state;
      this.dao.editAllUnits.vehicleGarageCity.answer = accountAddress.city;
      this.dao.editAllUnits.vehicleGaragePostalCode.answer = accountAddress.postalCode;
    }
  }
  public async breadcrumpClick(page = "") {
    if (page == 'product') {
      await this.bindProduct(true);
    }
    this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
    await this.setActiveMenu(page);
    return true;
  }
  // get Screen Order
  public getPageNamebyOrder(orderNumber) {
    let getTemplateOrder = (this.saveDatas.filter(s => s.order == orderNumber) && this.saveDatas.filter(s => s.order == orderNumber).length > 0) ? this.saveDatas.filter(s => s.order == orderNumber)[0].template : '';
    return getTemplateOrder;
  }

  public GetPremiseAddressFromVehicle(UnitDetail, type) {
    if (UnitDetail) {
      Promise.all(UnitDetail.map(async (v) => 
        {
        let vehicleData = this.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.group =="' + v.group + '")]');
        let TempVehicle = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);
        let TempGroup = '';
        let zoneDataIndex = _.max(this.getObjUsingJpath(vehicleData, '$..[?(@.key =="vehicleToZone" || @.key =="vehicleApplicableZonePercent")].rowIndex'));
        let zoneDatas = this.getObjUsingJpath(vehicleData, '$..[?(@.key =="vehicleToZone" || @.key =="vehicleApplicableZonePercent")]');
        let interestIndexAll = this.getObjUsingJpath(vehicleData, '$..[?(@.key =="addInsuredInterestType" || @.key =="addInsuredName" || @.key =="addInsuredStreetAddress" || @.key =="addInsuredCity" || @.key =="addInsuredState" || @.key =="addInsuredPostalCode" || @.key =="addInsuredLoanNumber")].rowIndex');
        var interestIndex = [...new Set(interestIndexAll)]?.sort();
        let interestDatas = this.getObjUsingJpath(vehicleData, '$..[?(@.key =="addInsuredInterestType" || @.key =="addInsuredName" || @.key =="addInsuredStreetAddress" || @.key =="addInsuredCity" || @.key =="addInsuredState" || @.key =="addInsuredPostalCode" || @.key =="addInsuredLoanNumber")]');
        vehicleData.forEach(c => {
          TempVehicle[c.key] = c;
          TempGroup = c.group;
          if ((TempVehicle[c.key].answer == undefined || TempVehicle[c.key].answer == '') && TempVehicle[c.key].questionDefault && TempVehicle[c.key].questionDefault.length > 0) {
            TempVehicle[c.key].answer = TempVehicle[c.key].questionDefault[0].code;
          }
        });
        Object.keys(TempVehicle).forEach(tempKey => {
          if (typeof (TempVehicle[tempKey]) == 'object') {
            TempVehicle[tempKey].group = TempGroup;
          }
        });

        TempVehicle.interestList = [];
        if (interestIndex !== undefined) {
          interestIndex.forEach((j, i) => {
            if (interestDatas && interestDatas?.length > 0 && interestDatas.filter(z => z.rowIndex == j).length > 0) {
              TempVehicle.interestList.push({
                'Type': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredInterestType')[0].answer,
                'Name': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredName')[0].answer,
                'streetAddress': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredStreetAddress')[0].answer,
                'city': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredCity')[0].answer,
                'state': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredState')[0].answer,
                'zip': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredPostalCode')[0].answer,
                'loanNo': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredLoanNumber')[0].answer
              });
            }
          });
        }
        else {
          TempVehicle.interestList.push({ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' });
        }
        TempVehicle.vehicleGaragingAddress = TempVehicle.vehicleGaragingAddress != '' ? TempVehicle.vehicleGaragingAddress : this.createNewGaragingAddressFromAccountAddress(TempVehicle?.vehicleGaragingAddress?.group);
        TempVehicle.isReviewed = (this.dao.finalSubmitPage == 'vehicles' || this.dao.finalSubmitPage == 'driver' || this.dao.finalSubmitPage == 'product' || this.dao.finalSubmitPage == 'application') ? true : false;
        TempVehicle[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].helpText = this.dao.vehicleModel.vehicleDefault[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].questionOptions.filter(c => c.value == TempVehicle[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].answer)[0]?.helpText;
        this.dao.vehicleModel[type + 'List'].push(TempVehicle);
      }));
    }
  }

  public async ChangeVehicleGaragingAddress() {
    if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer != '') {
      let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
      let accountAddress = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;
      this.dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer = accountAddress.street;
      this.dao.vehicleModel.vehicleItem.vehicleGarageCity.answer = accountAddress.city;
      this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer = accountAddress.state;
      this.dao.vehicleModel.vehicleItem.vehicleGaragePostalCode.answer = accountAddress.postalCode;
    }
  }
  public async GetAuthToken() {

    let AuthTokenDetails: any = await this.commonService.get('Registration', 'GetAuthToken', '').toPromise();
    if (AuthTokenDetails) {
      let karmaConfig: any = {};
      karmaConfig.userAuthtoken = AuthTokenDetails?.access_token;
      karmaConfig.userMail = 'karmauser@mailinator.com';
      karmaConfig.userCompany = 'BSB';
      karmaConfig.agencyCode = '70001';
      window.localStorage.setItem("karmaConfig", JSON.stringify(karmaConfig));

      // setting localstorage values
      karmaConfig = JSON.parse(localStorage.getItem('karmaConfig'));
      this.dao.userAuthtoken = karmaConfig.userAuthtoken;
      this.dao.userMail = karmaConfig.userMail;
      this.dao.userCompany = karmaConfig.userCompany;
      this.dao.RegistrationModal.email = karmaConfig.userMail;
      this.dao.RegistrationModal.agentCode = karmaConfig.agencyCode;

      return karmaConfig;
    }
  }
  // =============== Save Radius for all units ===============
  // Called from Edit All Units form
  public async saveRadiusForAllUnits(sourceV: vehicleDetails|EditAllUnits, type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {        
          this.saveRadiusForOneUnit(sourceV, data) 
      }
    });

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {        
        this.saveRadiusForOneUnit(sourceV, data) 
      }
    });
  }

  public saveRadiusForOneUnit(sourceV: vehicleDetails|EditAllUnits, targetV: vehicleDetails) {      
      if (targetV === sourceV) 
      {
        sourceV.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 = true;
        return;
      }
      targetV.vehicleTotalMilesUpto50.answer = sourceV.vehicleTotalMilesUpto50.answer;
      targetV.vehicleTotalMilesUpto200.answer = sourceV.vehicleTotalMilesUpto200.answer;
      targetV.vehicleTravelOver200.answer = sourceV.vehicleTravelOver200.answer;
      targetV.zoneList = _.cloneDeep(sourceV.zoneList);
      targetV.vehicleToZone.answer = sourceV.vehicleToZone.answer;
      targetV.vehicleApplicableZonePercent.answer = sourceV.vehicleApplicableZonePercent.answer;
      targetV.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 = true;
      targetV.farthestApplicableZonePercent.answer = sourceV.farthestApplicableZonePercent.answer;
      targetV.farthestToZone.answer = sourceV.farthestToZone.answer;      
  }

  // =============== Save Comp Deductible for all units ===============
  // Called from Edit All Units form
  public async saveCompDeductibleForAllUnits(sourceV: vehicleDetails|EditAllUnits,type) {    
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {        
        this.saveCompDeductibleForOneUnit(sourceV, data, type)
      }
     });

    if (this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {      
      this.saveCompDeductibleForOneUnit(sourceV, this.dao.vehicleModel.vehicleItem, type)
    }

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveCompDeductibleForOneUnit(sourceV, data, type)
      }
    });
  }
  
  public saveCompDeductibleForOneUnit(sourceV: vehicleDetails|EditAllUnits, targetV: vehicleDetails, type: string ) {
    if (sourceV === targetV) 
      {
        sourceV.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
        return;
      }
    //if the call is from Edut All, vehicleCompOTCCoverage.answer must be Yes
    targetV.vehicleCompOTCCoverage.answer = type == 'bulkEdit' ? 'Yes': sourceV.vehicleCompOTCCoverage.answer;
    targetV.vehicleComp.answer = sourceV.vehicleComp.answer;
    targetV.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
    if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
      targetV.vehicleColl.answer = sourceV.vehicleColl.answer;
    }
    if (this.dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled) {
      targetV.vinEtched.answer = sourceV.vinEtched.answer;
    }      
  }

  // =============== Save Coll Deductible for all units ===============
  // Called from Edit All Units form
  public async saveCollDeductibleForAllUnits(sourceV: vehicleDetails|EditAllUnits, type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') 
        {        
          this.saveCollDeductibleForOneUnit(sourceV, data, type);
        }
    });
    if (this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {      
      this.saveCollDeductibleForOneUnit(sourceV, this.dao.vehicleModel.vehicleItem, type);
    }
    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveCollDeductibleForOneUnit(sourceV, data, type);
      }
    });
  }

  public saveCollDeductibleForOneUnit(sourceV: vehicleDetails|EditAllUnits, targetV: vehicleDetails, type: string) {
      if (targetV === sourceV) 
      {
        sourceV.vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
        return;
      }
      //if the call is from Edut All, vehicleCollCoverage.answer must be Yes
      targetV.vehicleCollCoverage.answer = type == 'bulkEdit' ? 'Yes': sourceV.vehicleCompOTCCoverage.answer;
      targetV.vehicleColl.answer = sourceV.vehicleColl.answer;
      targetV.vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
      if (this.dao?.vehicleModel?.vehicleItem?.Q_WhichCollCoverage?.enabled) {
        targetV.vehicleCollCoverage.answer = sourceV.vehicleCollCoverage.answer;
        targetV.Q_WhichCollCoverage.answer = sourceV.Q_WhichCollCoverage.answer;
        targetV.broadenedCollision.answer = sourceV.broadenedCollision.answer;
        targetV.Q_limitedCollision.answer = sourceV.Q_limitedCollision.answer;
        targetV.limitedCollision.answer = sourceV.limitedCollision.answer;
      }
  }
  // =============== Save Garaging information for all units ===============
  // Called from Edit All Units form
  public async saveGaragingInformationForAllUnits(sourceV: vehicleDetails|EditAllUnits, type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {        
        this.saveGaragingInformationForOneUnit(sourceV, data);
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.saveGaragingInformationForOneUnit(sourceV, this.dao.vehicleModel.vehicleItem);
    }

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveGaragingInformationForOneUnit(sourceV, data);
      }
    });
  }

  public saveGaragingInformationForOneUnit(sourceV: vehicleDetails|EditAllUnits, targetV: vehicleDetails) {
    
      if (targetV === sourceV) 
      {
        sourceV.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;
        return;
      }
      targetV.vehicleIsCorrectGaragingAddress.answer = sourceV.vehicleIsCorrectGaragingAddress.answer;
      targetV.vehicleGarageStreetAddress.answer = sourceV.vehicleGarageStreetAddress.answer;
      targetV.vehicleGarageState.answer = sourceV.vehicleGarageState.answer;
      targetV.vehicleGarageCity.answer = sourceV.vehicleGarageCity.answer;
      targetV.vehicleGaragePostalCode.answer = sourceV.vehicleGaragePostalCode.answer;
      targetV.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;        
  }

  // =============== Save Custom Parts for all units ===============
  // Called from Edit All Units form
  public async saveCustomPartsForAllUnits(sourceV: vehicleDetails|EditAllUnits, type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveCustomPartsForOneUnit(sourceV, data);
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.saveCustomPartsForOneUnit(sourceV, this.dao.vehicleModel.vehicleItem);
    }

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveCustomPartsForOneUnit(sourceV, data);
      }
    });
  }

  public saveCustomPartsForOneUnit(sourceV: vehicleDetails|EditAllUnits, targetV: vehicleDetails) { 
      if (targetV === sourceV) 
      {
        sourceV.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
        return;
      }  
      targetV.vehicleCustomPartsParent.answer = sourceV.vehicleCustomPartsParent.answer;
      targetV.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
  }

  // =============== Save Additional Insured / Loss Payee for all units ===============
  // Called from Edit All Units form
  public async saveInsuredLossPayeeForAllUnits(sourceV: vehicleDetails|EditAllUnits, type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveInsuredLossPayeeForOneUnit(sourceV, data);
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.saveInsuredLossPayeeForOneUnit(sourceV, this.dao.vehicleModel.vehicleItem);
    }

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        this.saveInsuredLossPayeeForOneUnit(sourceV, data);
      }
    });
  }

  public saveInsuredLossPayeeForOneUnit(sourceV: vehicleDetails|EditAllUnits, targetV: vehicleDetails) {
  
      if (targetV === sourceV) 
      {
        sourceV.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
        return;
      }
      targetV.vehicleAddInsuredOrLossPayee.answer = sourceV.vehicleAddInsuredOrLossPayee.answer;
      targetV.interestList = _.cloneDeep(sourceV.interestList);
      targetV.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
  }

  public setRadiasAndCoverageForNewVehicle(targetVehicle: vehicleDetails)
  {
      if (this.dao.VehicleBulkEditRadiusCheckbox)
      {
        this.saveRadiusForOneUnit(this.dao.vehicleModel.VehiclesList[0], targetVehicle);
      }

      if(this.dao.VehicleBulkEditCompCoverageCheckbox)
        this.saveCompDeductibleForOneUnit(this.dao.vehicleModel.VehiclesList[0], targetVehicle, "");

      if(this.dao.VehicleBulkEditCompCoverageCheckbox)
         this.saveCollDeductibleForOneUnit(this.dao.vehicleModel.VehiclesList[0], targetVehicle, "");
    
      if(this.dao.VehicleBulkEditGaragingCheckbox)
        this.saveGaragingInformationForOneUnit(this.dao.vehicleModel.VehiclesList[0], targetVehicle);

      if(this.dao.VehicleBulkEditCustomPartsCheckbox)
        this.saveCustomPartsForOneUnit(this.dao.vehicleModel.VehiclesList[0], targetVehicle);

      if(this.dao.VehicleBulkEditInsuredLossPayeeCheckbox)
        this.saveInsuredLossPayeeForOneUnit(this.dao.vehicleModel.VehiclesList[0], targetVehicle);
  }


  public async GetAgencyWIPData() {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail] }
    await this.commonService.get('workInProgress', 'GetAgencyWIPData', payload).subscribe((event: any) => {
      if (event) {
        this.dao.AgencySubmissionList = event as workInProgress[];
      }
    });
  }

  public async GetAllUsers() {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail] }
    await this.commonService.get('Agent', 'GetAllUsers', payload).subscribe(async (event: any) => {
      if (event) {
        this.dao.UserManagement.UsersList = event;
        this.dao.UserManagement.tempUsersList = _.cloneDeep(this.dao.UserManagement.UsersList);
        this.dao.RegistrationModal = new RegistrationModal();
        if (this.dao.UserManagement.UsersList.length > 0) {
          if (this.dao.IsInternalUser != true) {
            const curUserProfile = event.filter(x => x.email == this.dao.userMail);
            if (curUserProfile != null) {
              curUserProfile.forEach(element => {
                // If claims does not exist get agency code directly from response
                if(!!element.claims){
                    const curClaim = element.claims.filter(x => x.applicationName == 'BSBAgentPortal' && x.claimName == 'agencycode' && x.company == 'BSB')
                    if (curClaim != null && curClaim.length > 0) {
                        this.dao.UserManagement.userSearch.agencyCode = curClaim[0].claimValue;
                    }
                }
                else {
                    this.dao.UserManagement.userSearch.agencyCode = element.agencyCode;
                }
                this.dao.RegistrationModal.agentCode = this.dao.UserManagement.userSearch.agencyCode;
              });
            }
          }
        } else {
          let userclaimdetails: any = await this.CheckUserClaims(this.dao.userMail);
          this.dao.UserManagement.userSearch.agencyCode = userclaimdetails?.agencyCode;
          this.dao.RegistrationModal.agentCode = this.dao.UserManagement.userSearch.agencyCode;
        }

      }
    });
  }
  public async DeleteUserByClaimId(userCLaimID) {
    let payload = { "urlData": [userCLaimID] }
    return await this.DeleteUserByClaimIdData(payload).then(async (data: any) => {
      if (data) {
        return data;
      }
    });

  }
  public async DeleteUserByClaimIdData(payload) {
    return await this.commonService.get('Agent', 'DeleteUser', payload).toPromise();
  }
  public async postAgencyRights(isCheckboxChecked) {
    if (this.dao.IsInternalUser) {
      if (isCheckboxChecked == true) {
        if (this.dao.UserManagement.userSearch.inactiveUser == false) {
          this.dao.BSBAgencyList.forEach(element => {
            element.isEndorsementAccessed = false;
          });
          this.dao.EndorsementList = this.dao.BSBAgencyList;
          this.dao.EndorsementTargetList = [];

          let payload = { "urlData": [this.dao.userCompany, this.dao.userMail], "payloadData": this.dao.BSBAgencyList }
          return await this.postAgencyRightsData(payload).then(async (data: any) => {
            if (data) {
              return data;
            }
          });
        }
      } else {
        if (this.dao.UserManagement.userSearch.inactiveUser == false) {
          this.dao.BSBAgencyList.forEach(element => {
            element.isEndorsementAccessed = false;
          });
        } else {
          if (this.dao.EndorsementTargetList != null) {
            this.dao.EndorsementTargetList.forEach(element => {
              this.dao.BSBAgencyList.filter(x => x.code == element.code)[0].isEndorsementAccessed = true;
            });
          }
          if (this.dao.EndorsementList != null) {
            this.dao.EndorsementList.forEach(element => {
              this.dao.BSBAgencyList.filter(x => x.code == element.code)[0].isEndorsementAccessed = false;
            });
          }
        }

        let payload = { "urlData": [this.dao.userCompany, this.dao.userMail], "payloadData": this.dao.BSBAgencyList }

        return await this.postAgencyRightsData(payload).then((data: any) => {
          if (data) {
            return data;
          }
        });
      }
    }
  }
  public async postAgencyRightsData(payload) {
    return await this.commonService.post('Agent', 'postAgencyRights', payload).toPromise();
  }

  public async throwVehicleValidationPopup() {
    this.dao.NotAllowedToSubmit = false;
    this.dao.IsSavedFromMenu = false;
    this.dao.isEmptyListFromMenu = true;

    document.body.style.pointerEvents = "none";
    if (this.dao.PolicyTemplate == 'vehicles') {
      this.messageService.add({ key: 'madeChange', sticky: true, severity: 'warn', summary: "If you leave this page without saving, your data will be lost. Are you sure you want to leave this page?" });
    }
  }

  public setActiveMenu(page = '') {
    let response: boolean = false;
    if (this.dao.MenuList != null) {
      let id = this.dao.MenuList.filter(x => x.text == (page == '' ? this.dao.PolicyTemplate : page))[0]?.id;
      let finalSubmitPageId = this.dao.MenuList.filter(x => x.text == this.dao.navigatePage)[0]?.id;
      this.dao.MenuList.forEach(x => {
        if (x.id == id) {
          x.isActive = true;
          x.cssClass = 'active current_menu_Size'
          x.arrowCss = 'arrow_active'
        } else if (x.id <= id + 1 && x.id <= finalSubmitPageId) {
          x.isActive = true;
          x.cssClass = 'active';
          x.arrowCss = 'arrow_active'
          response = true;
        } else if (x.id != id + 1 && x.id <= finalSubmitPageId) {
          x.isActive = false;
          x.cssClass = 'active active_grey';
          x.arrowCss = 'active active_grey';
        } else {
          x.isActive = false;
          x.cssClass = '';
        }

        if (x.id == id + 1) {
          x.arrowCss = 'arrow_active arrow_next_to_current_tab'
        }
      });
      if (id == 1) {
        response = true;
      }
    }
    return response;
  }

  public async downloadSampleTemplate(id, fileName) {
    let payload = { "urlData": [id] }
    let response: any = {};
    await this.commonService.get('SampleExcelTemplate', 'GetSampleExcelTemplate', payload).subscribe((event: any) => {
      if (event != null) {
        response = event;
        var arrrayBuffer = this.base64ToArrayBuffer(response.pdfStream);
        var blob = new Blob([arrrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    });
  }
  public async postResponseContainer(componentName: string, functionality: string, payload?: any): Promise<ResponseContainer> {
    var dataObject: any = await this.commonService.post(componentName, functionality, payload).toPromise();
    return Promise.resolve(ResponseContainer.fromJSON(dataObject));
  }
  public async getResponseContainer(componentName: string, functionality: string, payload?: any): Promise<ResponseContainer> {
    var dataObject: any = await this.commonService.get(componentName, functionality, payload).toPromise();
    return Promise.resolve(ResponseContainer.fromJSON(dataObject));
  }

  public isPolicyEffectiveOnJulyAndCorrectState(policyEffectiveDate, accountState): boolean {
    if (isNaN(new Date(policyEffectiveDate)?.getTime())) {
      return false;
    }

    const effectiveDate = this.dateService.getCorrectDate(policyEffectiveDate);
    let clearancePhase1 = ["AZ", "IL", "MN"].includes(accountState) && effectiveDate.getTime() >= new Date("07/01/2024").getTime();
    let clearancePhase2 = !["AZ", "IL", "MN"].includes(accountState) && effectiveDate.getTime() >= new Date("08/01/2024").getTime();
    return clearancePhase1 || clearancePhase2;
  }

  public getPolicyHolderVersion(policyEffectiveDate, accountState): boolean {
    const hasVehicleTravel = !!this.dao.DOTList.accountInfoQsnr.findQuestion('vehicleTravel');
    if (!hasVehicleTravel) {
      return false;
    }

    if (this.isPolicyEffectiveOnJulyAndCorrectState(policyEffectiveDate, accountState)) {
      return true;
    }

    return false;
  }

  /**
   * Determines if policy holder meets the New Trucking Model Logic based on policy effective date and valuation date.
   */
  public getPolicyHolderVersionNTM(policyEffectiveDate, accountState): boolean {
    if (!this.getPolicyHolderVersion(policyEffectiveDate, accountState)) {
      return false;
    }
    if (!!!this.dao.DOTList.accountInfoQsnr.findQuestion('doesFileIfta')) {
      return false;
    }
    const ntmEnableSettings = this.dao.DOTList.accountInfoQsnr.findQuestion('ntm_Enable_Settings');
    if (!!!ntmEnableSettings) {
      return false;
    }

    const effectiveDate = this.dateService.getCorrectDate(policyEffectiveDate);
    return ntmEnableSettings.questionOptions.some((option) => option.code === accountState && effectiveDate.getTime() >= new Date(option.label).getTime());
  }

  /**
   * Determines which loss version to use based on state, policy effective date, and valuation date.
   */
  get lossHistoryVersion(): number {
    const lossQuestionnaire: Questionnaire = this.dao.DOTList?.losssHistoryQuestionnaire;
    var valuationDateQuestionAnswers = lossQuestionnaire?.findQuestions("valuationDate");
    if (!!!valuationDateQuestionAnswers || valuationDateQuestionAnswers.length === 0) {
      return 1;
    }

    const policyEffectiveDate = this.dao.DOTList.accountInfoQsnr.findQuestion('policyEffectiveDate')?.answer;
    const accountState = this.dao.DOTList.accountInfoQsnr.findQuestion('accountState')?.answer;
    if (this.isPolicyEffectiveOnJulyAndCorrectState(policyEffectiveDate, accountState)) {
      return 2;
    }

    return 1;
  }
}
