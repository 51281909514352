import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO, emptyModal, vehicleDetails, VehicleModel} from '../../modal/DAO'
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { MenuClickContext } from '../../modal/routing.models'
import { PolicyHolderValueParser } from '../../modal/PolicyHolderValueParser.models';
import * as $ from "jquery";
import { MessageService } from 'primeng/api';
import _ from 'lodash';
import { AppComponent } from '../../app.component';
import { NgForm } from '@angular/forms';
import { ProductService } from '../../services/product.service';
import { StateSettings } from 'src/app/modal/state.models';
import * as XLSX from 'xlsx';
import { UserInputService } from 'src/app/services/userInput.service'
import { CommonService } from 'src/app/services/commonService';
import { Subscription } from 'rxjs';
import { ResponseContainer, QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { Address } from 'src/app/modal/address.models';
import { IBasePage } from 'src/app/modal/IBasePage.modules';


@Component({
    selector: 'app-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit, OnDestroy, IBasePage, AfterContentChecked {
    public ViewMsg: string = 'view more >>';
    @ViewChild('firstForm') firstForm: NgForm;
    @ViewChild('secondForm') secondForm: NgForm;
    @ViewChild('thirdForm') thirdForm: NgForm;
    @ViewChild('insuredForm') insuredForm: NgForm;
    @ViewChild('radiusForm') radiusForm: NgForm;
    @ViewChild('collisionDeductibleForm') collisionDeductibleForm: NgForm;
    @ViewChild('compDeductibleForm') compDeductibleForm: NgForm;
    @ViewChild('garagingInformationForm') garagingInformationForm: NgForm;
    @ViewChild('customPartsForm') customPartsForm: NgForm;
    @ViewChild('powerUnitList') powerUnitList: NgForm;
    @ViewChild('trailerList') trailerList: NgForm;
    @ViewChild('vehicleForm') vehicleForm: NgForm;

    public ViewMsg1: string = 'view more >>';
    public actionType: string = '';
    public isEditAllVisible: boolean = false;
    public isUpdateUnit: boolean = false;
    public editTabIndex: boolean = true;
    public EditUnitactiveIndex: number = 0;    
    public isShowCollisionCoverageCheckBox: boolean = false;
    masterDeleteCheckBoxValue: boolean = false;
    masterTrailerDeleteCheckBoxValue: boolean = false;
    excelPowerUnits: any;
    excelTrailers: any;
    excelEvent: any;
    powerUnitsLengthBeforeUpload: number;
    trailerLengthBeforeUpload: number;
    areVinNumbersDuplicated: boolean = false;
    vehicleAgeWarning: boolean = false;
    private savedAsVehicleModel: VehicleModel = null;
    private apiCallSubscription: Subscription;
    private uploadedExcelWorkBook: any;
    private menuClickSubscription: Subscription;    
    
    constructor(public serviceLayer: ServiceLayer, public dao: DAO, private messageService: MessageService,
        public appComponent: AppComponent, public bulkeditCheckbox: ChangeDetectorRef,
        private productService: ProductService, public stateSettings: StateSettings, private userInputService: UserInputService,
        private commonService: CommonService, private menuComponent: menuComponent,) { }

    isPageValid(): boolean {
        if (( !!this.firstForm && this.firstForm.invalid)
                || (!!this.secondForm && this.secondForm.valid) 
                ||( !!this.thirdForm && this.thirdForm.invalid))
            return false;

        let validationResults = this.validateVehicleListAndDups();
        if (validationResults.invalidPowerUnitList.size > 0 || validationResults.invalidTrailerList.size > 0) 
            return false;
        
        let duplicateVehicleListIndexes = this.checkDuplicateVinNumberExists('save');
        if (duplicateVehicleListIndexes.duplicatePowerUnitIndex.length> 0 || duplicateVehicleListIndexes.duplicateTrailerIndex.length > 0)
            return false;

        if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length == 0)
            return false;

        return true;
    }

    onRollback(page: string) {
        throw new Error('Method not implemented.');
    }

    menuClicked(menuClickContext: MenuClickContext) {
        if (menuClickContext != null && menuClickContext.page != "vehicles") {
            menuClickContext.currentPageIsValid = this.isPageValid();
            menuClickContext.currentPageDataChanged = this.hasDataChanged();
            // Vehicle Age validation
            delete menuClickContext["hasInvalidPowerUnitAge"];
            delete menuClickContext["hasInvalidPowerUnitAgeInSaveAs"];
            if (this.fleetAged) {
                menuClickContext["hasInvalidPowerUnitAge"] = true;
            }
            if (this.CheckIsFleetAged(this.dao.initialvehicleModel.VehiclesList)) {
                menuClickContext["hasInvalidPowerUnitAgeInSaveAs"] = true;
            }            
        }
    }
    
    private hasDataChanged():boolean{
        return  !_.isEqual(this.vehicleModel.VehiclesList, this.dao.initialvehicleModel.VehiclesList)
        || !_.isEqual(this.vehicleModel.trailerList, this.dao.initialvehicleModel.trailerList);
    }

    // ========= Page Load =====================
    ngOnInit(): void {
        setTimeout(() => {
            this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
                (apiResponseContainer) => {
                    if(!!!apiResponseContainer) {
                        return;
                    }

                    this.initializeData(apiResponseContainer);
                });
            this.menuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
                    (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
                );            
        }, 100);
    }

    ngAfterContentChecked(): void {
        this.bulkeditCheckbox.detectChanges();
    }

    // ========= Continue =====================
    public async Continue(step: number, ngform: NgForm, key: string) {        
        let errmsg = this.validateVehicleData(ngform, step, false);

        if (errmsg != '') {
            ngform.form.setErrors({ 'invalid': true });
            this.appComponent.addToast('error', 'Unit Info', errmsg);
            return;
        }
        if (ngform.form.valid) {
            this.vehicleContinue(step, key);
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', 'Please answer the required questions.');
        }
    }
    public AddZone() {
        var tempArray = { 'zone': '', 'percentage': '', 'helpText': '' };
        let validationStr = this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
        if (validationStr == '') {
            var tempObj = _.cloneDeep(this.dao.vehicleModel.vehicleItem.zoneList);
            tempObj.push(tempArray);
            this.dao.vehicleModel.vehicleItem.zoneList = tempObj;
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', validationStr);
        }
    }
    public zoneChange(i) {
        if (this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions && this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem.zoneList[i].zone) && this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem.zoneList[i].zone).length > 0) {
            var hoverText = this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem.zoneList[i].zone)[0].helpText;
            this.dao.vehicleModel.vehicleItem.zoneList[i].helpText = hoverText;
        }
    }
    // ========= Save vehicle =====================
    public async SaveUnit(evt: Event) {
        this.areVinNumbersDuplicated = false;
        this.vehicleForm?.form.setErrors(null);
        let validationResults = this.validateVehicleListAndDups();
        if (validationResults.invalidPowerUnitList.size > 0 || validationResults.invalidTrailerList.size > 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                this.dao.isClickFromMenu = false;
                this.openToast('Must review and complete each vehicle details highlighted in red');
            }
            return;
        }        
        if (validationResults.duplicatePowerUnitList.length > 0 || validationResults.duplicateTrailerList.length > 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                this.dao.isClickFromMenu = false;
                this.openToast('Duplicate VIN numbers have been identified, please correct the vehicles highlighted in red');
            }
            return;
        }
        if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length == 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                this.dao.isClickFromMenu = false;
                this.appComponent.addToast('error', 'Unit Info', 'At least one vehicle must be entered before continuing.');
            }
            return;
        }

        let fleetSizeAnswer = this.dao.DOTList.accountInfoQsnr.findQuestion('selectedFleetSize')?.answer;
        if (!!fleetSizeAnswer && this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length != parseInt(fleetSizeAnswer)) {
            this.dao.DOTList.accountInfoQsnr.findQuestion('selectedFleetSize').answer = this.dao.vehicleModel.VehiclesList.length;
        }
        
        // Vehicle Age validation        
        if (this.vehicleAgeWarning == false && this.fleetAged) {
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'vehicle_age_validation', sticky: true, severity: 'warn', data: 'Your submission will be declined because of the vehicle age profile' });
            return;
        }
        this.dao.NotAllowedToSubmit = false;
        let questionnaire = this.productService.getQuestionnaire();
        let municipalLocation = questionnaire?.questionAnswer?.find(x => x?.key === 'municipalLocation');
        if (municipalLocation) {
            municipalLocation.questionOptions = [];
            if (!this.checkGaragingAddress()) {
                municipalLocation.answer = "CLICK";
            }
        }
        this.dao.IsDriverBreadcrumpClicked = false;
        await this.serviceLayer.save();
        if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                document.body.style.pointerEvents = "none";
                this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: this.dao.DOTLookupStatusMsg });
            }
        }
    }

    private ValidateFleetAgeAndPopUp(): boolean
    {
        // Vehicle Age validation        
        if (this.vehicleAgeWarning == false && this.fleetAged) {
                document.body.style.pointerEvents = "none";
                this.messageService.add({ key: 'brownWarningPopupWithOk', sticky: true, severity: 'warn', summary: 'Your submission will be declined because of the vehicle age profile' });
                return true;
        }
        return false;
    }

    atleastOneTrailerSelected() {
        let isAtleastOneVehicleSelected = false;
        for (let i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
            if (this.dao.vehicleModel.trailerList[i].isChecked) {
                isAtleastOneVehicleSelected = true;
            }
        }
        if (!isAtleastOneVehicleSelected) {
            this.openToast('At least one trailer must be selected');
        }
        else {
            this.actionType = 'multipletrailer';
            this.removeSelectedVehicles('trailer');
        }

    }

    atleastOnePowerUnitSelected() {
        let isAtleastOneVehicleSelected = false;
        for (let i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
            if (this.dao.vehicleModel.VehiclesList[i].isChecked) {
                isAtleastOneVehicleSelected = true;
            }
        }
        if (!isAtleastOneVehicleSelected) {
            this.openToast('At least one power unit must be selected');
        }
        else {
            this.actionType = 'multiplevehicle';
            this.removeSelectedVehicles('vehicle');
        }
    }

    onVehicleCheckBoxChange(event) {

        if (this.dao.vehicleModel.trailerList.some(item => item.isChecked)) {
            this.dao.vehicleModel.trailerList.forEach(item => item.isChecked = false);
            this.masterTrailerDeleteCheckBoxValue = false;
        }
        if (event.checked) {
            if (this.dao.vehicleModel.VehiclesList.every(item => item.isChecked == true)) {
                this.masterDeleteCheckBoxValue = true;
            }
        }
        else {
            this.masterDeleteCheckBoxValue = false;
        }
    }

    onTrailerCheckBoxChanged(event) {
        if (this.dao.vehicleModel.VehiclesList.some(item => item.isChecked)) {
            this.dao.vehicleModel.VehiclesList.forEach(item => item.isChecked = false);
            this.masterDeleteCheckBoxValue = false;
        }
        if (event.checked) {
            if (this.dao.vehicleModel.trailerList.every(item => item.isChecked)) {
                this.masterTrailerDeleteCheckBoxValue = true;
            }
        }
        else {
            this.masterTrailerDeleteCheckBoxValue = false;
        }
    }

    onChangeMasterTrailerCheckBox(event) {
        for (let i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
            if (event.checked) {
                this.dao.vehicleModel.trailerList[i].isChecked = true;
                this.masterTrailerDeleteCheckBoxValue = true;

                this.dao.vehicleModel.VehiclesList.forEach(item => item.isChecked = false);
                this.masterDeleteCheckBoxValue = false;
            }
            else {
                this.dao.vehicleModel.trailerList[i].isChecked = false;
                this.masterTrailerDeleteCheckBoxValue = false;
            }
        }
    }

    onChangeMasterPowerUnitCheckBox(event) {
        for (let i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
            if (event.checked) {
                this.dao.vehicleModel.VehiclesList[i].isChecked = true;
                this.masterDeleteCheckBoxValue = true;

                this.dao.vehicleModel.trailerList.forEach(item => item.isChecked = false);
                this.masterTrailerDeleteCheckBoxValue = false;
            }
            else {
                this.dao.vehicleModel.VehiclesList[i].isChecked = false;
                this.masterDeleteCheckBoxValue = false;
            }
        }
    }

    // ======== Check for Any Address Changes ==============
    public checkGaragingAddress(): boolean {

        // Vehicle Address
        const NewVehicleGaragingAddress = this.dao.vehicleModel.VehiclesList.map((vehicle) => {
            return {
                vehicleGarageStreetAddress: vehicle.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: vehicle.vehicleGaragePostalCode,
                vehicleGarageCity: vehicle.vehicleGarageCity,
                vehicleGaragingAddress: vehicle.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: vehicle.vehicleIsCorrectGaragingAddress
            }
        });
        const initialVehicleGaragingAddress = this.dao.initialvehicleModel.VehiclesList.map((vehicle) => {
            return {
                vehicleGarageStreetAddress: vehicle.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: vehicle.vehicleGaragePostalCode,
                vehicleGarageCity: vehicle.vehicleGarageCity,
                vehicleGaragingAddress: vehicle.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: vehicle.vehicleIsCorrectGaragingAddress
            }
        });

        // Trailer Address
        const trailerListGaragingAddress = this.dao.vehicleModel.trailerList.map((trailer) => {
            return {
                vehicleGarageStreetAddress: trailer.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: trailer.vehicleGaragePostalCode,
                vehicleGarageCity: trailer.vehicleGarageCity,
                vehicleGaragingAddress: trailer.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: trailer.vehicleIsCorrectGaragingAddress
            }
        });
        const initialtrailerListGaragingAddress = this.dao.initialvehicleModel.trailerList.map((trailer) => {
            return {
                vehicleGarageStreetAddress: trailer.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: trailer.vehicleGaragePostalCode,
                vehicleGarageCity: trailer.vehicleGarageCity,
                vehicleGaragingAddress: trailer.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: trailer.vehicleIsCorrectGaragingAddress
            }
        });

        return _.isEqual(NewVehicleGaragingAddress, initialVehicleGaragingAddress) && _.isEqual(trailerListGaragingAddress, initialtrailerListGaragingAddress);
    }

    // ========= Remove vehicle =====================
    remove(item, i, actionType) {
        this.dao.deletedItem = item;
        this.dao.deletedIndex = i;
        this.actionType = actionType;
        const delData = {
            deletedItem: item,
            deletedIndex: i,
            deleteType: actionType
        }
        const strData = JSON.stringify(delData);
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: ' Do you want to delete ?', data: strData });
    }

    // ========= Confirm Remove vehicle =====================
    public async confirmRemove() {
        if (this.actionType == 'vehicle') {
            await this.removeVehicle(this.dao.deletedItem);           
            await this.VehicleSaveDraft();
            if (!this.dao.isTrailer) {
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex];
            }
        }
        if (this.actionType == 'multiplevehicle') {
            await this.confirmRemoveSelectedVehicles();
            if (this.dao.vehicleModel.VehiclesList.length == 0) {
                this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                this.serviceLayer.addVehicle('vehicle');
            }
        }
        if (this.actionType == 'trailer' || this.actionType == 'multipletrailer') {
            if (this.actionType == 'trailer') {
                await this.removeTrailer(this.dao.deletedItem);
                await this.VehicleSaveDraft();
            }
            else {
                await this.confirmRemoveSelectedTrailers();
            }
            if (this.dao.isTrailer) {
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex];
            }
        }
        if (this.actionType == 'interest') {
            this.dao.vehicleModel.vehicleItem.interestList.splice(this.dao.deletedIndex, 1);
        }
        if (this.actionType == 'zone') {
            this.dao.vehicleModel.vehicleItem.zoneList.splice(this.dao.deletedIndex, 1);
        }
        if (this.actionType == 'Editzone') {
            this.dao.editAllUnits.zoneList.splice(this.dao.deletedIndex, 1)
        }
        if (this.actionType == 'removeEditInterest') {
            this.dao.editAllUnits.interestList.splice(this.dao.deletedIndex, 1);
            this.insuredForm.form.markAsDirty();
        }
        if (this.actionType == 'confirmExcel') {
            this.ensureTrailerDetails();
        }
        this.messageService.clear('remove')
        document.body.style.pointerEvents = "visible";
    }
    // ========= Numbers only =====================
    public numbersOnly(e) {
        return this.serviceLayer.numbersOnly(e, 'vehicle');
    }
    // ========= Get Total Percent=====================
    public getTotalPercent(vehicleTotalMilesUpto50Answer, vehicleTotalMilesUpto200Answer, zoneList, farthestApplicableZonePercent) {
        let totalvalue = 0;
        if (this.dao.vehicleModel.vehicleDefault.farthestApplicableZonePercent.enabled) {
            totalvalue += (isNaN(Number(farthestApplicableZonePercent)) ? 0 : Number(farthestApplicableZonePercent));
        } else if (zoneList.length > 0) {
            zoneList.forEach(z => {
                totalvalue = totalvalue + (isNaN(Number(z.percentage)) ? 0 : Number(z.percentage));
            });
        }
        totalvalue = totalvalue + (isNaN(Number(vehicleTotalMilesUpto50Answer)) ? 0 : Number(vehicleTotalMilesUpto50Answer)) + (isNaN(Number(vehicleTotalMilesUpto200Answer)) ? 0 : Number(vehicleTotalMilesUpto200Answer));
        return totalvalue == 100;
    }
    // ========= Cancel my submission =====================
    public CancelmySubmission() {
        this.messageService.clear('c');
        document.body.style.pointerEvents = "visible";
        if (this.actionType == 'ensureTrailerDetails') {
            this.validateTemplateVersion();
        }
        else {
            return this.serviceLayer.CancelmySubmission();
        }
    }
    // ========= View click =====================
    viewClick(type) {
        if (type == 'Vehicles') {
            this.ViewMsg = (this.ViewMsg == 'view more >>' ? 'view less <<' : 'view more >>');
            if (this.ViewMsg == 'view less <<') {
                $((type == 'Vehicles') ? '.menulen' : '.tra .menulen').addClass('ovrflvsbl');
            } else {
                $((type == 'Vehicles') ? '.menulen' : '.tra .menulen').removeClass('ovrflvsbl');
            }
        }
    }
    viewClick1(type) {
        if (type == 'Trailers') {
            this.ViewMsg1 = (this.ViewMsg1 == 'view more >>' ? 'view less <<' : 'view more >>');
            if (this.ViewMsg1 == 'view less <<') {
                $((type == 'Trailers') ? '.menulen1' : '.tra .menulen1').addClass('ovrflvsbl');
            } else {
                $((type == 'Trailers') ? '.menulen1' : '.tra .menulen1').removeClass('ovrflvsbl');
            }
        }
    }
    // ========= Add vehicle=====================

    public validateVehicleData(frm: NgForm, step: number, validateAllForms: boolean) {
        let errmsg = "";
        let forms: NgForm[] = [this["firstForm"], this["secondForm"], this["thirdForm"]];

        if (validateAllForms && !this.serviceLayer.validateRequiredFieldAnsweredAllForms(forms))
            errmsg = 'Please answer the required questions.\n';
        else if (!validateAllForms && !this.serviceLayer.validateRequiredFieldAnswered(frm))
            errmsg = 'Please answer the required questions.\n';

        if (step == 1 || validateAllForms) {
            if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer != null && this.dao.vehicleModel.vehicleItem.vehicleVin.answer != null && this.dao.vehicleModel.vehicleItem.vehicleType.group.indexOf('VEH') > -1
                && this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length > 0) {
                if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer < 1981
                    && (this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length > 13 || this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length < 5)) {
                    this["firstForm"].form.controls['txtVCVIN'].setErrors({ 'incorrect': true });
                    errmsg += 'Vehicle VIN # must be minimum of 5 and maximum of 13 characters.';
                }
                else if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer >= 1981 && this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length != 17) {
                    this["firstForm"].form.controls['txtVCVIN'].setErrors({ 'incorrect': true });
                    errmsg += 'Vehicle VIN # must be equal to 17 characters.\n';
                }
                
            }
            this.validateVehicleListAndDups();
            if(this.hasDuplicatedVins)
                {
                    errmsg += 'Vehicle VIN # can not be duplicated.\n';
                }
        }
        if (step == 2 || validateAllForms) {

            let zoneValidation = "";
            if (step != 2) {
                //No need to pop up because the following error has already been displayed in the page
                zoneValidation = this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer) ? '' : 'Total radius percentage must equal 100%\n';
                if (zoneValidation != null && zoneValidation != "")
                    errmsg += zoneValidation + "\n";
                zoneValidation = '';
                zoneValidation = this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
                if (zoneValidation != null && zoneValidation != "")
                    errmsg += zoneValidation + "\n";
            }

            if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == null || this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == '')
                errmsg += 'Is this the correct garaging address is not answered.\n';
            if ((this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == null || this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == '') && this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.enabled)
                 errmsg += 'Does the vehicle travel over 200 miles from the garaging address is not answered.\n';

        }
        if (step == 3 || validateAllForms) {
            if (this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == 'Yes'
                && Number(this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer) == 0) {
                //do not pop the stated amount error as it shows next to the textbox
                if (step != 3)
                    errmsg += 'Stated Amount cannot be $0.\n';
            }
            if (this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes'
                && (this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == null
                    || this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == '0'
                    || this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == '')) {
                errmsg += 'Custom parts and equipment amount cannot be $0.\n';
            }
            if (this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == null
                || this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == '')
                errmsg += 'Does this unit have an additional insured or loss payee is not anwered.\n';
        }

        return errmsg;
    }

    public async addVehicle(type, evt: Event) {        
        let formName = this.dao?.vehicleModel?.vehiclePage == 1 ? 'firstForm' : this.dao?.vehicleModel?.vehiclePage == 2 ? 'secondForm' : 'thirdForm';
        let ngform: NgForm = this[formName];
        ngform.onSubmit(evt);
        let errmsg = this.validateVehicleData(ngform, this.dao?.vehicleModel?.vehiclePage, true);

        if ("" != errmsg) {
            ngform.form.setErrors({ 'invalid': true });
            this.appComponent.addToast('error', 'Unit Info', errmsg);
            return;
        }

        if (ngform.valid) {
            let newVehicle = await this.serviceLayer.addVehicle(type);
            await this.serviceLayer.setRadiasAndCoverageForNewVehicle(newVehicle);
            this.validateVehicleListAndDups();
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', this.dao.toastValidation);
        }
    }
    
    public AddInterest(type) {
        if (type == '') {
            var tempArray = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
            var tempObj = _.cloneDeep(this.dao.vehicleModel.vehicleItem.interestList);
            tempObj.push(tempArray);
            this.dao.vehicleModel.vehicleItem.interestList = tempObj;
        } else {
            var tempArray = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
            var tempObj = _.cloneDeep(this.dao.editAllUnits.interestList);
            tempObj.push(tempArray);
            this.dao.editAllUnits.interestList = tempObj;
        }
    }
    // ========= Cancel my submission popup =====================
    public cancelMySubmissionPopUp() {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
    }
    // ========= On reject =====================
    public onReject(type) {
        if (this.actionType == 'ensureTrailerDetails') {
            this.openToast('Please update Trailer information in Trailer tab and upload again');
        }
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
        if(this.actionType == 'validateTemplateVersion') {
            this.validateAndUploadExcel();
        }
    }
    // ========== isShowBtn =========
    public isShowActinBtn(): any {
        if(this.vehicleForm?.errors?.invalidPowerUnitList?.size > 0 
            || this.vehicleForm?.errors?.duplicatePowerUnitList.length > 0)
        {
            this.dao.IsShowVehicleDraftBtn = true;            
        }
        else{
            this.dao.IsShowVehicleDraftBtn = false;       
        }        
        return "checked";//Must return a truly result
    }

    public get showAddVehilceBtn(): boolean {
        if (this.dao.vehicleModel.VehiclesList.length >= this.dao.MaximumVehicle)
        {
            return false;
        }
        return (this.dao?.vehicleModel?.VehiclesList?.length == 0 
            || (this.dao?.vehicleModel?.vehiclePage == 3 
                && (
                  (!this.dao?.isTrailer && this.dao?.vehicleModel?.VehiclesList?.length - 1 == this.dao?.vehicleModel?.vehicleIndex) ||
                  (this.dao?.isTrailer && this.dao?.vehicleModel?.trailerList?.length - 1 == this.dao.vehicleModel.trailerIndex)))
                );
    }

    public get hasDuplicatedVins(): boolean {
        return  (this.dao?.vehicleModel?.vehiclePage == 1 
                && (
                  (!this.dao?.isTrailer && this.vehicleForm?.errors?.duplicatePowerUnitList?.includes(this.dao?.vehicleModel?.vehicleIndex))) ||
                  (this.dao?.isTrailer && this.vehicleForm?.errors?.duplicateTrailerList?.includes(this.dao?.vehicleModel?.trailerIndex))
                );
    }

    public get showAddTrailorBtn(): boolean {
        if (this.dao.vehicleModel.trailerList.length >= this.dao.MaximumTrailer)
            return false;
        return (this.dao?.vehicleModel?.vehiclePage == 3 
            && (
                  (!this.dao?.isTrailer && this.dao?.vehicleModel?.VehiclesList?.length - 1 == this.dao?.vehicleModel?.vehicleIndex) 
                  || (this.dao?.isTrailer && this.dao?.vehicleModel?.trailerList?.length - 1 == this.dao.vehicleModel.trailerIndex)
               )
          )
    }

    public get showNextBtn(): boolean {
        return (!this.dao?.isTrailer&& (this.dao?.vehicleModel?.VehiclesList?.length - 1 != this.dao.vehicleModel.vehicleIndex)) 
        || (this.dao?.isTrailer && this.dao?.vehicleModel?.trailerList?.length - 1 != this.dao.vehicleModel.trailerIndex)
    }
    //==========vehicle size Change ====//
    public vehicleSizeChange() {
        let type = this.dao.isTrailer ? 'trailer' : 'vehicle';
        if (this.dao.vehicleModel.vehicleDefault[type + 'Size'] && this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions && this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem[type + 'Size'].answer) && this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem[type + 'Size'].answer).length > 0) {
            var hoverText = this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem[type + 'Size'].answer)[0].helpText;
            this.dao.vehicleModel.vehicleItem[type + 'Size'].helpText = hoverText;
        }
    }
    // check vehicle name blank
    public checkVehicleBlank(item) {
        if ((item?.vehicleYear?.answer && item?.vehicleYear?.answer != '') || (item?.vehicleMake?.answer && item?.vehicleMake?.answer != '') || (item?.vehicleModel?.answer && item?.vehicleModel?.answer != '')) {
            return true;
        }
        return false;
    }
    // ========= On Back =====================
    public async onBack(page) {         
        this.dao.IsBackButtonClicked = true;
        this.menuComponent.menuClick(page);
    }

    StatedAmountinput() {
        if (this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == null || this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == '' || this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == '0') {
            setTimeout(() => {
                this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer = 0;
            }, 10);
        }
    }
    // Validate address
    public ValidateAddress(event, value, type) {
        let result = this.serviceLayer.ValidateAddress(event, value);
        if (result != null) {
            if (type == "txtvehicleGarageStreetAddress") {
                this.dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer = result;
            }
        }
    }
    public async menuClick(page) {
        let result: any;
        let currentPageOrder = this.serviceLayer.getPageOrder(this.dao.PolicyTemplate);
        let finalSavePageOrder = this.serviceLayer.getPageOrder(this.dao.navigatePage);
        let clickedPage = this.serviceLayer.getPageOrder(page);

        if (clickedPage <= finalSavePageOrder
            || (clickedPage < currentPageOrder && (page == 'AccountInfo' || page != 'AccountInfo'))) {
            let menuContext: MenuClickContext = new MenuClickContext();
            menuContext.currentPageIsValid = this.isPageValid();
            menuContext.page = page;
            result = await this.serviceLayer.menuClick(menuContext);
            if (result != false) {
                this.dao.PolicyTemplate = page;
            }
        }
    }
    public async ChangeVehicleGaragingAddress() {
        this.serviceLayer.ChangeVehicleGaragingAddress();
    }
    public async popUpEditAllUnits() {
        this.isEditAllVisible = true;
        this.EditUnitactiveIndex = 0;
    }
    public async closeEditAllUnits() {
        this.isUpdateUnit = false;
    }
    // ============== UpdateAllUnits ======================
    public async updateAllUnits() {
        var areAllFormsCloseable = true;

        if (this.radiusForm.dirty) {
            if (this.radiusForm.valid) {
                await this.saveRadiusForAllUnits();
                this.radiusForm.form.markAsPristine();

                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.compDeductibleForm.dirty) {
            if (this.compDeductibleForm.valid) {
                await this.saveCompDeductibleForAllUnits();
                this.compDeductibleForm.form.markAsPristine();
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.collisionDeductibleForm.dirty) {
            if (this.collisionDeductibleForm.valid) {
                await this.saveCollDeductibleForAllUnits();
                this.collisionDeductibleForm.form.markAsPristine();
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.garagingInformationForm.dirty) {
            if (this.garagingInformationForm.valid) {
                await this.saveGaragingInformationForAllUnits();
                this.garagingInformationForm.form.markAsPristine();
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.customPartsForm.dirty) {
            if (this.customPartsForm.valid) {
                await this.saveCustomPartsForAllUnits();
                this.customPartsForm.form.markAsPristine();
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.insuredForm.dirty) {
            if (this.insuredForm.valid) {
                await this.saveInsuredLossPayeeForAllUnits();
                this.insuredForm.form.markAsPristine();
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (areAllFormsCloseable) {            
          this.isEditAllVisible = false;
          this.validateVehicleListAndDups();
        }
    }
    // ================= Save Radius for all units ================ 
    public async saveRadiusForAllUnits() {
        if (this.radiusForm.invalid) return;
        let totalPercent = this.getTotalPercentForEditAllUnits();
        if (totalPercent == true) {
            if (this.dao.editAllUnits.vehicleTravelOver200.answer != undefined && this.dao.editAllUnits.vehicleTravelOver200.answer !== '') {
                if (this.dao.editAllUnits.vehicleTravelOver200.answer == 'No' || (this.dao.editAllUnits.vehicleTravelOver200.answer == 'Yes' && this.dao.editAllUnits.zoneList.length > 0)) {
                    await this.serviceLayer.saveRadiusForAllUnits(this.dao.editAllUnits, 'bulkEdit');
                    this.dao.VehicleBulkEditRadiusCheckbox = true;                                     
                    this.isEditAllVisible = false;
                }

            } else if (this.dao.editAllUnits.zoneList.length === 1 && (this.dao.editAllUnits.zoneList[0].zone !== '' || this.dao.editAllUnits.zoneList[0].percentage !== '')) {
                await this.serviceLayer.saveRadiusForAllUnits(this.dao.editAllUnits, 'bulkEdit');
                this.dao.VehicleBulkEditRadiusCheckbox = true;                
                this.isEditAllVisible = false;
            } else if (this.dao.vehicleModel.vehicleDefault.farthestApplicableZonePercent.enabled) {
                await this.serviceLayer.saveRadiusForAllUnits(this.dao.editAllUnits, 'bulkEdit');
                this.dao.VehicleBulkEditRadiusCheckbox = true;                
                this.isEditAllVisible = false;
            }

        } else {            
            this.appComponent.addToast('error', 'Unit Info', "Total radius percentage must equal 100%");
        }
    }
    // ========= Get Total Percent For Edit All Units=====================
    public getTotalPercentForEditAllUnits() {
        let totalvalue = 0;
        if (this.dao.vehicleModel.vehicleDefault.farthestApplicableZonePercent.enabled) {
            totalvalue += (isNaN(Number(this.dao.editAllUnits.farthestApplicableZonePercent.answer)) ? 0 : Number(this.dao.editAllUnits.farthestApplicableZonePercent.answer));
        } else if (this.dao.editAllUnits && this.dao.editAllUnits.zoneList && this.dao.editAllUnits.zoneList.length > 0) {
            this.dao.editAllUnits.zoneList.forEach(z => {
                totalvalue = totalvalue + (isNaN(Number(z.percentage)) ? 0 : Number(z.percentage));
            });
        }
        totalvalue = totalvalue + (isNaN(Number(this.dao.editAllUnits.vehicleTotalMilesUpto50.answer)) ? 0 : Number(this.dao.editAllUnits.vehicleTotalMilesUpto50.answer)) + (isNaN(Number(this.dao.editAllUnits.vehicleTotalMilesUpto200.answer)) ? 0 : Number(this.dao.editAllUnits.vehicleTotalMilesUpto200.answer));
        return totalvalue == 100;
    }

    // ================= Save Comp Deductible for all units ================ 
    public async saveCompDeductibleForAllUnits() {
        if (this.compDeductibleForm.invalid) {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
            return
        }
        await this.serviceLayer.saveCompDeductibleForAllUnits(this.dao.editAllUnits, 'bulkEdit');
        this.dao.VehicleBulkEditCompCoverageCheckbox = true;
        this.isEditAllVisible = false;        

    }
    // ================= Save Coll Deductible for all units ================ 
    public async saveCollDeductibleForAllUnits() {
        if (this.collisionDeductibleForm.invalid) {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
            return
        }
        if (this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
            if (this.dao.vehicleModel.trailerList.length > 0) {
                this.dao.vehicleModel.trailerList.forEach(trailer => {
                    if (trailer.floorTrailer.answer == '') {
                        trailer.floorTrailer.answer = 'No';
                    }
                })
            }
        }
        await this.serviceLayer.saveCollDeductibleForAllUnits(this.dao.editAllUnits, 'bulkEdit');
        this.dao.VehicleBulkEditCollCoverageCheckbox = true;
        this.isEditAllVisible = false;        

    }
    // ================= Save Garaging Information for all units ================ 
    public async saveGaragingInformationForAllUnits() {
        if (this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'Yes' || this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'No') {
            if ((this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'No' && this.dao.editAllUnits.vehicleGarageStreetAddress.answer != null && this.dao.editAllUnits.vehicleGarageStreetAddress.answer != ''
                && this.dao.editAllUnits.vehicleGarageCity.answer != null && this.dao.editAllUnits.vehicleGarageCity.answer != ''
                && this.dao.editAllUnits.vehicleGarageState.answer != null && this.dao.editAllUnits.vehicleGarageState.answer != ''
                && this.dao.editAllUnits.vehicleGaragePostalCode.answer != null && this.dao.editAllUnits.vehicleGaragePostalCode.answer != '')
                || this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'Yes') {

                await this.serviceLayer.saveGaragingInformationForAllUnits(this.dao.editAllUnits, 'bulkEdit');
                this.dao.VehicleBulkEditGaragingCheckbox = true;
                this.isEditAllVisible = false;
            }
            else {
                this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
            }
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
        }
  }

    // ================= Save Custome parts for all units ================ 
    public async saveCustomPartsForAllUnits() {
        if (this.dao.editAllUnits.vehicleCustomPartsParent.answer == 'Yes' || this.dao.editAllUnits.vehicleCustomPartsParent.answer == 'No') {
            await this.serviceLayer.saveCustomPartsForAllUnits(this.dao.editAllUnits, 'bulkEdit');
            this.dao.VehicleBulkEditCustomPartsCheckbox = true;
            this.isEditAllVisible = false;
        } else {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
        }
    }
    // ================= Save Additional Insured / Loss Payee for all units ================ 
    public async saveInsuredLossPayeeForAllUnits() {
        if ((this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer == 'Yes' && this.dao.editAllUnits.interestList.length>0) || this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer == 'No') {
            await this.serviceLayer.saveInsuredLossPayeeForAllUnits(this.dao.editAllUnits, 'bulkEdit');
            this.dao.VehicleBulkEditInsuredLossPayeeCheckbox = true;
            this.isEditAllVisible = false;
        } else {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
        }
    }
    
    ClearCustomDetails(type) {
        if (type == 'No') {
            this.dao.vehicleModel.vehicleItem.vehicleCustomAmount = new emptyModal();
            this.dao.vehicleModel.vehicleItem.vehicleCustomDesc = new emptyModal();
        }
    }

    // ===================saving vehicle details in DB without validating fields while save btn is clicked
    public async VehicleSaveDraft() {
        await this.serviceLayer.setUnitData();
        let getTemplate = this.serviceLayer.saveDatas.filter(s => s.template == this.dao.PolicyTemplate);
        let getFinalTemplate = this.serviceLayer.saveDatas.filter(s => s.template == this.dao.finalSubmitPage);
        let getTemplateOrder = (getTemplate && getTemplate.length > 0) ? getTemplate[0].order : 0;
        let getFinalTemplateOrder = (getFinalTemplate && getFinalTemplate.length > 0) ? getFinalTemplate[0].order : 0;
        let DotExists: boolean = false;
        if (getTemplateOrder <= getFinalTemplateOrder) {
            DotExists = true;
        }
        let payload = { "urlData": [this.dao.userMail, DotExists, this.dao.PolicyTemplate, 'dot', false], "payloadData": this.dao.DOTList }

        await this.PostVehicleDraft(payload).then((data: any) => { 
            //save the temp result in order to check any further changes hasPageDataChanged()
            this.savedAsVehicleModel = _.cloneDeep(this.dao.vehicleModel);
            this.dao.initialvehicleModel = _.cloneDeep(this.dao.vehicleModel);
            this.validateVehicleListAndDups();
            this.ValidateFleetAgeAndPopUp();            
        });

        if (this.dao.finalSubmitPage == 'losshistory') {
            this.dao.finalSubmitPage = 'vehicles';
            this.dao.navigatePage = 'vehicles';
            this.dao.DOTList.finalSubmitPage = 'vehicles';
        }
    }
    // ========= Save Vehicle Draft =====================
    public async PostVehicleDraft(payload) {
        return await this.commonService.post('vehicle', 'saveVehicleDraft', payload).toPromise();
    }

    public async CollisionOnChange(type) {
        if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.answer) {
            this.isShowCollisionCoverageCheckBox = true;
            if (this.dao.vehicleModel.vehicleItem?.vehicleCollCoverage?.answer) {
                if (type == 'vehicleCollCoverage') {
                    this.dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer = '';
                    this.dao.vehicleModel.vehicleItem.Q_limitedCollision.answer = '';
                    if (this.dao.isTrailer) {
                        this.setFloorTrailerValue();
                    }
                }
                if (this.dao.vehicleModel.vehicleItem?.Q_WhichCollCoverage.answer == 'Yes' && this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                    if (this.dao.vehicleModel.vehicleItem.vehicleColl?.questionDefault) {
                        this.dao.vehicleModel.vehicleItem.vehicleColl.answer = this.dao.vehicleModel.vehicleItem.vehicleColl.questionDefault[0].code;
                    }
                } else if (this.dao.vehicleModel.vehicleItem?.Q_WhichCollCoverage.answer == 'No' && this.dao.vehicleModel.vehicleItem.broadenedCollision.answer) {
                    if (this.dao.vehicleModel.vehicleItem.broadenedCollision?.questionDefault) {
                        this.dao.vehicleModel.vehicleItem.broadenedCollision.answer = this.dao.vehicleModel.vehicleItem.broadenedCollision.questionDefault[0].code;
                    }
                }
            } else if (this.dao.vehicleModel.vehicleItem?.Q_limitedCollision.answer) {
                if (this.dao.vehicleModel.vehicleItem?.Q_limitedCollision.answer == 'No' && this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                    if (this.dao.vehicleModel.vehicleItem.limitedCollision?.questionDefault) {
                        this.dao.vehicleModel.vehicleItem.limitedCollision.answer = this.dao.vehicleModel.vehicleItem.limitedCollision.questionDefault[0].code;
                    }
                }
            }
        }
        else if (type == 'vehicleCollCoverage' && this.dao.isTrailer) {
            this.setFloorTrailerValue();
        }
    }
    private setFloorTrailerValue() {
        if (this.dao.vehicleModel.vehicleItem?.vehicleCollCoverage?.answer == 'Yes') {
            if (this.dao.vehicleModel.vehicleItem.floorTrailer.answer == '') {
                this.dao.vehicleModel.vehicleItem.floorTrailer.answer = 'No';
            }
        }
    }

    public async EditCollisionOnChange(type) {
        if (type == 'vehicleCollCoverage') {
            this.dao.editAllUnits.vehicleCollCoverage.answer == 'Yes' ? this.dao.editAllUnits.Q_limitedCollision.answer = '' : this.dao.editAllUnits.Q_WhichCollCoverage.answer = '';
        }
        if (type == 'Q_WhichCollCoverage') {
            if (this.dao.editAllUnits.Q_WhichCollCoverage.answer == 'No') {
                if (this.dao.vehicleModel.vehicleItem.broadenedCollision?.questionDefault) {
                    this.dao.editAllUnits.broadenedCollision.answer = this.dao.vehicleModel.vehicleItem.broadenedCollision.questionDefault[0].code;
                }
            }
        }
        if (type == 'Q_limitedCollision') {
            if (this.dao.editAllUnits.Q_limitedCollision.answer == 'Yes') {
                if (this.dao.vehicleModel.vehicleItem.limitedCollision?.questionDefault) {
                    this.dao.editAllUnits.limitedCollision.answer = this.dao.vehicleModel.vehicleItem.limitedCollision.questionDefault[0].code;
                }
            }
        }
    }

    removeSelectedVehicles(unitType: string) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: `Do you want to delete the selected ${unitType}(s)? ` });
    }

    async confirmRemoveSelectedVehicles() {
        for (let i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
            if (this.dao.vehicleModel.VehiclesList[i].isChecked) {
                this.dao.deletedItem = this.dao.vehicleModel.VehiclesList[i];
                await this.removeVehicle(this.dao.deletedItem);
                await this.VehicleSaveDraft();
                i = i - 1;
            }
        }
        this.checkDuplicateVinNumberExists('delete');
        this.masterDeleteCheckBoxValue = false;
    }
    async confirmRemoveSelectedTrailers() {
        for (let i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
            if (this.dao.vehicleModel.trailerList[i].isChecked) {
                this.dao.deletedItem = this.dao.vehicleModel.trailerList[i];
                await this.removeTrailer(this.dao.deletedItem);
                await this.VehicleSaveDraft();
                i = i - 1;
            }
        }
        this.checkDuplicateVinNumberExists('delete');
        this.masterTrailerDeleteCheckBoxValue = false;
    }
    public openToast(body) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({
            key: 'ok', sticky: true, severity: 'warn',
            summary: body
        });
        this.dao.isClickFromMenu = false;
    }

    vehicleCompOTCCoverageAnswerChanged() {
        setTimeout(() => {
            this.thirdForm.controls.vehicleStatedAmount.updateValueAndValidity();
        }, 200);
    }
    trackByFn(i: number) {
        return i
    }
    public async downloadSampleVehicleTemplate(id, fileName) {
        this.serviceLayer.downloadSampleTemplate(id, fileName);
    }
    uploadVehicleList(event, fileupload) {
        if (event?.files?.length > 0) {
            this.excelEvent = event?.files[0];
            this.actionType = 'confirmExcel';
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: 'Do you want to upload this vehicle information?' });
        }
        fileupload.clear();
    }
    ensureTrailerDetails() {
        this.actionType = 'ensureTrailerDetails';
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Please confirm that Trailer information is updated in Trailer tab?' });
    }
    private validateTemplateVersion(): void {
        let file = this.excelEvent;
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            this.uploadedExcelWorkBook = XLSX.read(fileReader.result, { type: 'binary', cellText: false, cellDates: true });
            if (!!!this.uploadedExcelWorkBook.Custprops['version'] || (!!this.uploadedExcelWorkBook.Custprops['version'] && this.uploadedExcelWorkBook.Custprops['version'] != Constants.CurrentVehicleExcelTemplateVersion)) {
                this.openToast('A new version of the vehicle template is now available.  You may continue with the old version, but the new version will provide a superior experience.');
                this.actionType = "validateTemplateVersion";
                return;
            }
            this.validateAndUploadExcel();
        }
    }
    private validateAndUploadExcel(): boolean {
        this.actionType = '';
        if (!this.validateTemplateHeaders()) {
            this.openToast('The file you uploaded is not in the correct format.  Please upload the vehicle information using the BSB template only.');
            return false;
        }
        this.excelPowerUnits = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['PowerUnit'], { header: 0, raw: false, dateNF: 'MM/dd/yyyy' });
        this.excelTrailers = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['Trailer'], { header: 0, raw: false, dateNF: 'MM/dd/yyyy' });
        if (this.excelPowerUnits.length <= 2 && this.excelTrailers.length <= 1) {
            this.openToast('Vehicle list is empty, cannot be uploaded');
            return false;
        }
        let powerunitSpliceCount = 0;
        let powerunitSpliceIndex = 0;
        if (this.dao.vehicleModel.VehiclesList.length == 1) {
            if (this.dao.vehicleModel.VehiclesList[0].vehicleYear.answer == ""
                && this.dao.vehicleModel.VehiclesList[0].vehicleMake.answer == ""
                && this.dao.vehicleModel.VehiclesList[0].vehicleModel.answer == ""
                && this.dao.vehicleModel.VehiclesList[0].vehicleVin.answer == "") {
                powerunitSpliceIndex = 0;
                powerunitSpliceCount = 1;
                this.powerUnitsLengthBeforeUpload = this.dao.vehicleModel.VehiclesList.length - 1;
            }
            else {
                this.powerUnitsLengthBeforeUpload = this.dao.vehicleModel.VehiclesList.length;
            }
        }
        else if (this.dao.vehicleModel.VehiclesList.length > 1) {
            for (var x = 0; x < this.dao.vehicleModel.VehiclesList.length; x++) {
                if (this.dao.vehicleModel.VehiclesList[x].vehicleYear.answer == ""
                    && this.dao.vehicleModel.VehiclesList[x].vehicleMake.answer == ""
                    && this.dao.vehicleModel.VehiclesList[x].vehicleModel.answer == ""
                    && this.dao.vehicleModel.VehiclesList[x].vehicleVin.answer == "") {
                    powerunitSpliceIndex = x;
                    powerunitSpliceCount = this.dao.vehicleModel.VehiclesList.length - x;
                    this.powerUnitsLengthBeforeUpload = x;
                    break;
                }
                else {
                    this.powerUnitsLengthBeforeUpload = x + 1;
                }
            }
        }
        if (this.powerUnitsLengthBeforeUpload + this.excelPowerUnits.length - 2 > this.dao.MaximumVehicle) {
            this.openToast('You cannot exceed ' + this.dao.MaximumVehicle + ' Power units');
            return false;
        }
        if (this.dao.vehicleModel.trailerList.length > 0
            && this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerList.length - 1].vehicleYear.answer == ""
            && this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerList.length - 1].vehicleMake.answer == "") {
            this.trailerLengthBeforeUpload = this.dao.vehicleModel.trailerList.length - 1;
        }
        else {
            this.trailerLengthBeforeUpload = this.dao.vehicleModel.trailerList.length;
        }
        if (this.trailerLengthBeforeUpload + this.excelTrailers.length - 1 > this.dao.MaximumTrailer) {
            this.openToast('You cannot exceed ' + this.dao.MaximumTrailer + ' trailers');
            return false;
        }
        if (this.excelPowerUnits.length - 2 > 0)
            this.dao.vehicleModel.VehiclesList.splice(powerunitSpliceIndex, powerunitSpliceCount);
        if (this.excelTrailers.length - 1 > 0)
            this.dao.vehicleModel.trailerList.splice(this.trailerLengthBeforeUpload, 1);
        let isPowerUnitsUploadedSucessfully;
        let isTrailersUploadedSucessfully;
        isPowerUnitsUploadedSucessfully = this.excelPowerUnits.length - 1 <= 1 ? true : this.uploadingPowerUnits();
        if (isPowerUnitsUploadedSucessfully) {
            isTrailersUploadedSucessfully = this.excelTrailers.length - 1 <= 0 ? true : this.uploadingTrailers();
        }
        if (isPowerUnitsUploadedSucessfully && isTrailersUploadedSucessfully) {
            if (this.excelPowerUnits.length - 1 <= 1) {
                this.dao.vehicleModel.trailerIndex = this.trailerLengthBeforeUpload == 0 ? 0 : this.trailerLengthBeforeUpload;
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.trailerLengthBeforeUpload == 0 ? 0 : this.trailerLengthBeforeUpload];
                this.dao.isTrailer = false;
            }
            else {
                this.dao.vehicleModel.vehicleIndex = this.powerUnitsLengthBeforeUpload == 0 ? 0 : this.powerUnitsLengthBeforeUpload;
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.powerUnitsLengthBeforeUpload == 0 ? 0 : this.powerUnitsLengthBeforeUpload];
                this.dao.isTrailer = false;
            }
          this.openToast('Power Unit(s)/Trailer(s) list have been uploaded successfully');
          this.validateVehicleListAndDups();
            return true;
        }
        return false;
    }
    validateTemplateHeaders(): boolean {
        if(!!!this.uploadedExcelWorkBook.Sheets['PowerUnit'] || !!!this.uploadedExcelWorkBook.Sheets['Trailer']) {
            return false;
        }
        var powerUnitsTemplateHeaders1 = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['PowerUnit'], { header: 1 })[0];
        var powerUnitsTemplateHeaders2 = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['PowerUnit'], { header: 1 })[1];
        var trailersTemplateHeaders = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['Trailer'], { header: 1 })[0];
        if (!powerUnitsTemplateHeaders1[0] || powerUnitsTemplateHeaders1[0] != 'Please use Trailer tab to upload Trailer details') {
            return false;
        }
        if (!powerUnitsTemplateHeaders2[0] || powerUnitsTemplateHeaders2[0] != 'Year'
            || (!powerUnitsTemplateHeaders2[1] || powerUnitsTemplateHeaders2[1] != 'Make')
            || (!powerUnitsTemplateHeaders2[2] || powerUnitsTemplateHeaders2[2] != 'Model')
            || (!powerUnitsTemplateHeaders2[3] || powerUnitsTemplateHeaders2[3] != 'VIN')
            || (!powerUnitsTemplateHeaders2[4] || powerUnitsTemplateHeaders2[4] != 'Is this the correct garaging address?')
            || (!powerUnitsTemplateHeaders2[5] || powerUnitsTemplateHeaders2[5] != 'Vehicle Weight Class')
            || (!powerUnitsTemplateHeaders2[6] || powerUnitsTemplateHeaders2[6] != 'Vehicle Body Type')
            || (!powerUnitsTemplateHeaders2[7] || powerUnitsTemplateHeaders2[7] != 'Secondary Classification')
            || (!powerUnitsTemplateHeaders2[8] || powerUnitsTemplateHeaders2[8] != 'Local - Annual miles <= 50 miles (in %)')
            || (!powerUnitsTemplateHeaders2[9] || powerUnitsTemplateHeaders2[9] != 'Intermediate - 50 miles > Annual miles <= 200 (in %)')
            || (!powerUnitsTemplateHeaders2[10] || powerUnitsTemplateHeaders2[10] != 'Comprehensive Deductible')
            || (!powerUnitsTemplateHeaders2[12] || powerUnitsTemplateHeaders2[12] != 'Collision Deductible')
            || (!powerUnitsTemplateHeaders2[14] || powerUnitsTemplateHeaders2[14] != 'Stated Amount per Unit (Including custom parts & equipment)')
            || (!powerUnitsTemplateHeaders2[15] || powerUnitsTemplateHeaders2[15] != 'Are there custom parts and equipment on this unit?')
            || (!powerUnitsTemplateHeaders2[18] || powerUnitsTemplateHeaders2[18] != 'Does this unit have an additional insured or loss payee?')) {
            return false;
        }
        if (!trailersTemplateHeaders[0] && trailersTemplateHeaders[0] != 'Year'
            || (!trailersTemplateHeaders[1] || trailersTemplateHeaders[1] != 'Make')
            || (!trailersTemplateHeaders[2] || trailersTemplateHeaders[2] != 'Model')
            || (!trailersTemplateHeaders[3] || trailersTemplateHeaders[3] != 'VIN')
            || (!trailersTemplateHeaders[4] || trailersTemplateHeaders[4] != 'Is this the correct garaging address?')
            || (!trailersTemplateHeaders[5] || trailersTemplateHeaders[5] != 'Trailer Weight Class')
            || (!trailersTemplateHeaders[6] || trailersTemplateHeaders[6] != 'Trailer Body Type')
            || (!trailersTemplateHeaders[7] || trailersTemplateHeaders[7] != 'Secondary Classification')
            || (!trailersTemplateHeaders[8] || trailersTemplateHeaders[8] != 'Local - Annual miles <= 50 miles (in %)')
            || (!trailersTemplateHeaders[9] || trailersTemplateHeaders[9] != 'Intermediate - 50 miles > Annual miles <= 200 (in %)')
            || (!trailersTemplateHeaders[10] || trailersTemplateHeaders[10] != 'Comprehensive Deductible')
            || (!trailersTemplateHeaders[12] || trailersTemplateHeaders[12] != 'Collision Deductible')
            || (!trailersTemplateHeaders[14] || trailersTemplateHeaders[14] != 'Stated Amount per Unit (Including custom parts & equipment)')
            || (!trailersTemplateHeaders[15] || trailersTemplateHeaders[15] != 'Are there custom parts and equipment on this unit?')
            || (!trailersTemplateHeaders[18] || trailersTemplateHeaders[18] != 'Does this unit have an additional insured or loss payee?')) {
            return false;
        }
        return true;
    }
    uploadingPowerUnits(): boolean {
        for (var key = 2; key <= this.excelPowerUnits.length - 1; key++) {
            var lastVehicleIndex = 0
            this.dao.vehicleModel.VehiclesList.forEach((vehicle, index) => {
                Object.keys(vehicle).forEach(tempKey => {
                    if (typeof (vehicle[tempKey]) == 'object') {
                        vehicle[tempKey].group = ('VEH-' + (index + 1));
                    }
                });
                lastVehicleIndex = index + 1;
            });
            let tempVehicle = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);

            // 1st form
            tempVehicle.vehicleYear.answer = this.getAndSetValidYear(this.excelPowerUnits[key]["Please use Trailer tab to upload Trailer details"]);
            tempVehicle.vehicleMake.answer = this.getAndSetValidString(this.excelPowerUnits[key]["__EMPTY"]);
            tempVehicle.vehicleModel.answer = this.getAndSetValidString(this.excelPowerUnits[key]["__EMPTY_1"]);
            tempVehicle.vehicleVin.answer = !!this.excelPowerUnits[key]["__EMPTY_2"] ? this.excelPowerUnits[key]["__EMPTY_2"].trim() : "";
            // 2nd form 
            tempVehicle.vehicleIsCorrectGaragingAddress.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_3"]);
            tempVehicle.vehicleSize.answer = this.getValidDropdownAnswerFromExcel('vehicleSize', this.excelPowerUnits[key]["__EMPTY_4"]);
            tempVehicle.vehicleBodyType.answer = this.getValidDropdownAnswerFromExcel('vehicleBodyType', this.excelPowerUnits[key]["__EMPTY_5"]);
            tempVehicle.vehicleSecondaryClass.answer = this.getValidDropdownAnswerFromExcel('vehicleSecondaryClass', this.excelPowerUnits[key]["__EMPTY_6"]);
            tempVehicle.vehicleTotalMilesUpto50.answer = isNaN(this.excelPowerUnits[key]["__EMPTY_7"]) ? "" : this.excelPowerUnits[key]["__EMPTY_7"];
            tempVehicle.vehicleTotalMilesUpto200.answer = isNaN(this.excelPowerUnits[key]["__EMPTY_8"]) ? "" : this.excelPowerUnits[key]["__EMPTY_8"];
            // 3rd form 
            const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
            if (accounState != 'MI') {
                tempVehicle.vehicleCompOTCCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_9"]);
                tempVehicle.vehicleComp.answer = tempVehicle.vehicleCompOTCCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleComp', this.excelPowerUnits[key]["__EMPTY_10"]) : "";
                tempVehicle.vehicleCollCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_11"]);
                tempVehicle.vehicleColl.answer = tempVehicle.vehicleCollCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleColl', this.excelPowerUnits[key]["__EMPTY_12"]) : "";
            }
            else {
                tempVehicle.vehicleCompOTCCoverage.answer = "";
                tempVehicle.vehicleCollCoverage.answer = "";
            }
            tempVehicle.vehicleStatedAmount.answer = isNaN(this.excelPowerUnits[key]["__EMPTY_13"]) ? "" : Math.round(this.excelPowerUnits[key]["__EMPTY_13"].trim());
            tempVehicle.vehicleCustomPartsParent.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_14"]);
            tempVehicle.vehicleCustomAmount.answer = tempVehicle.vehicleCustomPartsParent.answer == 'Yes' ? (isNaN(this.excelPowerUnits[key]["__EMPTY_15"]) ? "" : Math.round(this.excelPowerUnits[key]["__EMPTY_15"].trim())) : "";
            tempVehicle.vehicleCustomDesc.answer = tempVehicle.vehicleCustomPartsParent.answer == 'Yes' ? this.excelPowerUnits[key]["__EMPTY_16"] : "";
            tempVehicle.vehicleAddInsuredOrLossPayee.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_17"]);

            if (this.dao.vehicleModel.VehiclesList.length > 0 && this.dao.vehicleModel.VehiclesList.every(item => item.isChecked)) {
                tempVehicle.isChecked = true;
            }
            Object.keys(tempVehicle).forEach(tempKey => {
                if (typeof (tempVehicle[tempKey]) == 'object') {
                    tempVehicle[tempKey].group = 'VEH-' + (lastVehicleIndex + 1);
                    if (tempVehicle[tempKey].questionOptions != null && tempVehicle[tempKey].questionOptions.length > 0) {
                        tempVehicle[tempKey].questionOptions = []; //empty question options to reduce payload size
                    }
                }
            });
            tempVehicle.interestList = [];
            if (tempVehicle.vehicleAddInsuredOrLossPayee.answer == 'Yes') {
                var vehicleInsuredOrLossPayee = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
                tempVehicle.interestList.push(vehicleInsuredOrLossPayee);
            }
            tempVehicle.vehicleType.answer = '';
            this.dao.vehicleModel.VehiclesList.push(tempVehicle);
            this.dao.isTrailer = false;
        }
        return true;
    }
    uploadingTrailers(): boolean {
        for (var key = 1; key <= this.excelTrailers.length - 1; key++) {
            var lastTrailerIndex = 0
            this.dao.vehicleModel.trailerList.forEach((trailer, index) => {
                Object.keys(trailer).forEach(tempKey => {
                    if (typeof (trailer[tempKey]) == 'object') {
                        trailer[tempKey].group = ('TRA-' + (index + 1));
                    }
                });
                lastTrailerIndex = index + 1;
            });
            let tempTrailer = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);

            // 1st form
            tempTrailer.vehicleYear.answer = this.getAndSetValidYear(this.excelTrailers[key]["Year"]);
            tempTrailer.vehicleMake.answer = this.getAndSetValidString(this.excelTrailers[key]["Make"]);
            tempTrailer.vehicleModel.answer = this.getAndSetValidString(this.excelTrailers[key]["Model"]);
            tempTrailer.vehicleVin.answer = !!this.excelTrailers[key]["VIN"] ? this.excelTrailers[key]["VIN"].trim() : "";
            // 2nd form 
            tempTrailer.vehicleIsCorrectGaragingAddress.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Is this the correct garaging address?"]);
            tempTrailer.trailerSize.answer = this.getValidDropdownAnswerFromExcel('trailerSize', this.excelTrailers[key]["Trailer Weight Class"]);
            tempTrailer.trailerBodyType.answer = this.getValidDropdownAnswerFromExcel('trailerBodyType', this.excelTrailers[key]["Trailer Body Type"]);
            tempTrailer.vehicleSecondaryClass.answer = this.getValidDropdownAnswerFromExcel('vehicleSecondaryClass', this.excelTrailers[key]["Secondary Classification"]);
            tempTrailer.vehicleTotalMilesUpto50.answer = isNaN(this.excelTrailers[key]["Local - Annual miles <= 50 miles (in %)"]) ? "" : this.excelTrailers[key]["Local - Annual miles <= 50 miles (in %)"];
            tempTrailer.vehicleTotalMilesUpto200.answer = isNaN(this.excelTrailers[key]["Intermediate - 50 miles > Annual miles <= 200 (in %)"]) ? "" : this.excelTrailers[key]["Intermediate - 50 miles > Annual miles <= 200 (in %)"];
            // 3rd form 
            const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
            if (accounState != 'MI') {
                tempTrailer.vehicleCompOTCCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Comprehensive Deductible"]);
                tempTrailer.vehicleComp.answer = tempTrailer.vehicleCompOTCCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleComp', this.excelTrailers[key]["__EMPTY"]) : "";
                tempTrailer.vehicleCollCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Collision Deductible"]);
                tempTrailer.vehicleColl.answer = tempTrailer.vehicleCollCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleColl', this.excelTrailers[key]["__EMPTY_1"]) : "";
                tempTrailer.floorTrailer.answer = tempTrailer.vehicleCollCoverage.answer == 'Yes' ? 'No' : "";
            }
            else {
                tempTrailer.vehicleCompOTCCoverage.answer = "";
                tempTrailer.vehicleCollCoverage.answer = "";
            }
            tempTrailer.vehicleStatedAmount.answer = isNaN(this.excelTrailers[key]["Stated Amount per Unit (Including custom parts & equipment)"]) ? "" : Math.round(this.excelTrailers[key]["Stated Amount per Unit (Including custom parts & equipment)"].trim());
            tempTrailer.vehicleCustomPartsParent.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Are there custom parts and equipment on this unit?"]);
            tempTrailer.vehicleCustomAmount.answer = tempTrailer.vehicleCustomPartsParent.answer == 'Yes' ? (isNaN(this.excelTrailers[key]["__EMPTY_2"]) ? "" : Math.round(this.excelTrailers[key]["__EMPTY_2"].trim())) : "";
            tempTrailer.vehicleCustomDesc.answer = tempTrailer.vehicleCustomPartsParent.answer == 'Yes' ? this.excelTrailers[key]["__EMPTY_3"] : "";
            tempTrailer.vehicleAddInsuredOrLossPayee.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Does this unit have an additional insured or loss payee?"]);

            if (this.dao.vehicleModel.trailerList.length > 0 && this.dao.vehicleModel.trailerList.every(item => item.isChecked)) {
                tempTrailer.isChecked = true;
            }
            Object.keys(tempTrailer).forEach(tempKey => {
                if (typeof (tempTrailer[tempKey]) == 'object') {
                    tempTrailer[tempKey].group = 'TRA-' + (lastTrailerIndex + 1);
                    if (tempTrailer[tempKey].questionOptions != null && tempTrailer[tempKey].questionOptions.length > 0) {
                        tempTrailer[tempKey].questionOptions = []; //empty question options to reduce payload size
                    }
                }
            });
            tempTrailer.interestList = [];
            if (tempTrailer.vehicleAddInsuredOrLossPayee.answer == 'Yes') {
                var vehicleInsuredOrLossPayee = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
                tempTrailer.interestList.push(vehicleInsuredOrLossPayee);
            }
            tempTrailer.vehicleType.answer = 'Unknown';
            this.dao.vehicleModel.trailerList.push(tempTrailer);
            this.dao.isTrailer = true;
        }
        return true;
    }
    getValidDropdownAnswerFromExcel(key: string, value: string): string {
        if (!!!value) {
            return "";
        }
        value = value.toUpperCase().trim().replace(/ /g, "").replace(",", "");
        if (this.dao.vehicleModel.vehicleDefault[key].questionOptions.some(x => x.label.toUpperCase().trim().replace(/ /g, "").replace(",", "") == value)) {
            return this.dao.vehicleModel.vehicleDefault[key].questionOptions.find(x => x.label.toUpperCase().trim().replace(/ /g, "").replace(",", "") == value).code;
        }
        return "";
    }
    getValidRadiobuttonAnswerFromExcel(value: string): string {
        if (!!!value) {
            return "";
        }
        value = value.toUpperCase().trim();
        if (value == 'YES') {
            return 'Yes';
        }
        if (value == 'NO') {
            return 'No';
        }
        return "";
    }
    getAndSetValidYear(year: string): string {
        if (!!!year) {
            return "";
        }
        year = year.trim();
        const isValidYear = /^\d{4}$/.test(year);
        if (isValidYear) {
            return year;
        }
        return "";
    }
    getAndSetValidString(value: string): string {
        if (!!!value) {
            return "";
        }
        if (!this.userInputService.valueContainsNonAlphaNumericCharacters(value)) {
            return value.trim();
        }
        return "";
    }

    clickedItem(type, item, index) {        
        this.dao.vehicleModel.vehicleItem = item;
        this.dao.vehicleModel.vehiclePage = 1;
        if (type == 'powerunit') {
            this.dao.vehicleModel.vehicleIndex = index;
            this.dao.vehicleModel.trailerIndex = 0;
            this.dao.isTrailer = false;
        }
        else {
            this.dao.vehicleModel.trailerIndex = index;
            this.dao.vehicleModel.vehicleIndex = 0;
            this.dao.isTrailer = true;
      }
      this.validateVehicleListAndDups();
    }

  validateionOneVehicle (aVeh: vehicleDetails)
  {
      if (!!!aVeh.vehicleYear.answer || !!!aVeh.vehicleMake.answer
          || ((!!!aVeh.vehicleModel.answer || !!!aVeh.vehicleVin.answer) && aVeh.vehicleBodyType.group.includes("VEH"))
          || !!!aVeh.vehicleIsCorrectGaragingAddress.answer
          || ((!!!aVeh.vehicleSize.answer || !!!aVeh.vehicleBodyType.answer) && aVeh.vehicleBodyType.group.includes("VEH"))
          || ((!!!aVeh.trailerSize.answer || !!!aVeh.trailerBodyType.answer) && aVeh.vehicleBodyType.group.includes("TRA"))
          || !!!aVeh.vehicleSecondaryClass.answer || (!!!aVeh.vehicleTravelOver200.answer && aVeh.vehicleTravelOver200.enabled)
          || (aVeh.farthestApplicableZonePercent.enabled && aVeh.farthestToZone.enabled && aVeh.farthestToZone.answer !== '' && !!!aVeh.farthestApplicableZonePercent.answer)
          || !!!aVeh.vehicleCompOTCCoverage.answer || (!!aVeh.vehicleCollCoverage.enabled && !!!aVeh.vehicleCollCoverage.answer)
          || !!!aVeh.vehicleCustomPartsParent.answer || !!!aVeh.vehicleAddInsuredOrLossPayee.answer) {
   
          return false;
        }
        if (!!aVeh.vehicleModel.answer && aVeh.vehicleModel.answer.length > 25) {
          return false;
        }
        if (!!aVeh.vehicleVin.required && aVeh.vehicleVin.answer) {
          if (aVeh.vehicleType.group.indexOf('VEH') > -1
            && aVeh.vehicleVin.answer.length > 0) {
            if (aVeh.vehicleYear.answer < 1981
              && (aVeh.vehicleVin.answer.length > 13 || aVeh.vehicleVin.answer.length < 5)) {
              return false;
            }
            else if (aVeh.vehicleYear.answer >= 1981 && aVeh.vehicleVin.answer.length != 17) {
              return false;
            }
          }
        }
        if (aVeh.vehicleBodyType.group.includes("VEH") && (!!!aVeh.vehicleBodyType.answer || !!!aVeh.vehicleSize.answer)) {
          return false;
        }
        if (aVeh.trailerBodyType.group.includes("TRA") && (!!!aVeh.trailerBodyType.answer || !!!aVeh.trailerSize.answer)) {
          return false;
        }
        if (aVeh.vehicleIsCorrectGaragingAddress.answer == 'No') {
          if (!!!aVeh.vehicleGarageStreetAddress.answer
            || !!!aVeh.vehicleGarageCity.answer
            || !!!aVeh.vehicleGaragePostalCode.answer) {
              return false;
          }
        }
        if (!this.getTotalPercent(aVeh.vehicleTotalMilesUpto50.answer, aVeh.vehicleTotalMilesUpto200.answer, aVeh.zoneList, aVeh.farthestApplicableZonePercent.answer)) {
          return false;
        }
        if (aVeh.vehicleTravelOver200.answer == 'Yes') {
          let isSameZoneExists = this.checkSameZone(aVeh.zoneList);
          if (isSameZoneExists != ''
            || (aVeh.vehicleApplicableZonePercent.required && aVeh.zoneList.some((zone) => !!!zone.percentage))
            || (aVeh.vehicleToZone.required && aVeh.zoneList.some((zone) => !!!zone.zone))) {
              return false;
          }
        }
        if (aVeh.vehicleCompOTCCoverage.answer == 'Yes') {
          if (!!!aVeh.vehicleComp.answer || !!!aVeh.vehicleStatedAmount.answer || aVeh.vehicleStatedAmount.answer == '0') {
              return false;
          }
          if (aVeh.vinEtched.enabled && !!!aVeh.vinEtched.answer) {
              return false;
          }
          if (!!!aVeh.vehicleCollCoverage.enabled && !!!aVeh.vehicleColl.answer) {
              return false;
          }
        }
        if (!!aVeh.vehicleStatedAmount.answer) {
          if (aVeh.vehicleStatedAmount.answer == '0.00')
            aVeh.vehicleStatedAmount.answer = '0';
          let statedAmountValue = aVeh.vehicleStatedAmount.answer;
          if (typeof (statedAmountValue) != 'string') {
            statedAmountValue = statedAmountValue.toString();
          }
          if (statedAmountValue.length > 0) {
            for (var k = 0; k < statedAmountValue.length; k++) {
              var charCode = statedAmountValue.charCodeAt(k);
              if (charCode < 47 || charCode > 58) {
                  return false;
              }
            }
          }
        }
        const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
        if (!!accounState) {
          if (accounState != 'MI') {
            if (!!aVeh.vehicleCollCoverage.enabled && aVeh.vehicleCollCoverage.answer == 'Yes') {
              if (!!!aVeh.vehicleColl.answer) {
                  return false;
              }
            }
          }
          else {
            if (!!aVeh.vehicleCollCoverage.enabled && !!!aVeh.vehicleCollCoverage.answer) {
              return false;
            }
            else {
              if (!!aVeh.vehicleCollCoverage.enabled && aVeh.vehicleCollCoverage.answer == 'Yes') {
                if (aVeh.Q_WhichCollCoverage.answer == 'Yes') {
                  if (!!!aVeh.vehicleColl.answer) {
                      return false;
                  }
                }
                else {
                  if (!!!aVeh.broadenedCollision.answer) {
                      return false;
                  }
                }
                if (this.dao.DOTList.questionnaire.filter(x => x.key == 'vehicle')[0].questionAnswer.some(z => z.key == 'floorTrailer')
                  && aVeh.vehicleVin.group.includes("TRA")
                  && !!!this.dao.vehicleModel.vehicleItem.floorTrailer.answer) {
                      return false;
                }
              }
              else {
                if (!!!aVeh.Q_limitedCollision.answer) {
                  return false;
                }
                if (aVeh.Q_limitedCollision.answer == 'Yes') {
                  if (!!!aVeh.limitedCollision.answer) {
                      return false;
                  }
                }
              }
            }
          }
        }
        if (aVeh.vehicleCustomPartsParent.answer == 'Yes') {
          if (!!!aVeh.vehicleCustomAmount.answer || aVeh.vehicleCustomAmount.answer == '0' || !!!aVeh.vehicleCustomDesc.answer) {
              return false;
          }
          if (!!aVeh.vehicleCustomAmount.answer) {
            let customAmountValue = aVeh.vehicleCustomAmount.answer;
            if (typeof (customAmountValue) != 'string') {
              customAmountValue = customAmountValue.toString();
            }
            if (customAmountValue.length > 0) {
              for (var k = 0; k < customAmountValue.length; k++) {
                var charCode = customAmountValue.charCodeAt(k);
                if (charCode < 47 || charCode > 58) {
                  return false;
                }
              }
            }
          }
        }
        if (aVeh.vehicleAddInsuredOrLossPayee.answer == 'Yes') {
          if (aVeh.interestList.length > 0) {
            for (var j = 0; j < aVeh.interestList.length; j++) {
              if (!!!aVeh.interestList[j].Name || !!!aVeh.interestList[j].Type
                || !!!aVeh.interestList[j].city || !!!aVeh.interestList[j].state
                || !!!aVeh.interestList[j].streetAddress || !!!aVeh.interestList[j].zip) {
                  return false;
              }
            }
          }
        }      
  }

  validateVehicleListAndDups():{
        invalidPowerUnitList:Set<number>,
        invalidTrailerList:Set<number>, 
        duplicatePowerUnit: boolean,
        duplicatePowerUnitList:Array<number>,
        duplicateTrailer: boolean,
        duplicateTrailerList:Array<number>} {
    let result1 = this.validatingVehicleList();
    let errorsObj = {
        invalidPowerUnitList: new Set<number>(),
        invalidTrailerList:  new Set<number>(),
        duplicatePowerUnit: false,
        duplicatePowerUnitList: [],
        duplicateTrailer: false,
        duplicateTrailerList: [],
        };

    if (result1.invalidPowerUnitIndex.size > 0 ) {     
        errorsObj.invalidPowerUnitList = result1.invalidPowerUnitIndex
    }
    if (result1.invalidTrailerIndex.size > 0) {      
        errorsObj.invalidTrailerList = result1.invalidTrailerIndex
    }

    let results2 = this.checkDuplicateVinNumberExists('save');
    if (results2.duplicatePowerUnitIndex.length > 0) {        
            errorsObj.duplicatePowerUnit =  true;
            errorsObj.duplicatePowerUnitList = results2.duplicatePowerUnitIndex;
    }
    if (results2.duplicateTrailerIndex.length > 0) {
            errorsObj.duplicateTrailer =  true;
            errorsObj.duplicateTrailerList = results2.duplicateTrailerIndex;
    }
    this.vehicleForm?.form.setErrors(errorsObj);
    return errorsObj;
  }

  validatingVehicleList(): {invalidPowerUnitIndex:Set<number>, invalidTrailerIndex:Set<number>} {
    let invalidPowerUnitIndex = new Set<number>();
    let invalidTrailerIndex = new Set<number>();
    for (var i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
        if(this.validateionOneVehicle(this.dao.vehicleModel.VehiclesList[i]) == false)
        {
            invalidPowerUnitIndex.add(i);
        }
    }
    for (var i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
        if(this.validateionOneVehicle(this.dao.vehicleModel.trailerList[i]) == false)
        {
            invalidTrailerIndex.add(i);
        }
    } 
    return {invalidPowerUnitIndex, invalidTrailerIndex };
  }

  checkDuplicateVinNumberExists(actionType: string) {
        this.areVinNumbersDuplicated = false;
        let duplicatePowerUnitIndex = [];
        let duplicateTrailerIndex = [];
        let tempVehicleList = [];
        tempVehicleList = this.dao.vehicleModel.VehiclesList;
        tempVehicleList = tempVehicleList.concat(this.dao.vehicleModel.trailerList);
        for (let i = 0; i < tempVehicleList.length; i++) {
            if (!!tempVehicleList[i].vehicleVin.answer) {
                for (let j = i + 1; j < tempVehicleList.length; j++) {
                    if (_.isEqual(tempVehicleList[i].vehicleVin.answer, tempVehicleList[j].vehicleVin.answer)) {
                        this.areVinNumbersDuplicated = actionType == 'save';
                        tempVehicleList[i].vehicleVin.group.includes("VEH") ?
                            duplicatePowerUnitIndex.push(i) :
                            duplicateTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                        tempVehicleList[j].vehicleVin.group.includes("VEH") ?
                            duplicatePowerUnitIndex.push(j) :
                            duplicateTrailerIndex.push(j - this.dao.vehicleModel.VehiclesList.length);
                    }
                }
            }
        }

        return { duplicatePowerUnitIndex, duplicateTrailerIndex }
    }

    public async vehicleContinue(step, key = 'Next') {        
        let zoneValidation = '';
        zoneValidation = this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer) ? '' : 'Total radius percentage must equal 100%';
        zoneValidation = zoneValidation != '' ? zoneValidation : this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
        if ((step != 2 || (zoneValidation == '' && this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer)))) {
            if (this.dao.vehicleModel.vehiclePage == 3) {
                if (key == 'Next') {
                    this.dao.vehicleModel.vehiclePage = 1;
                    this.dao.vehicleModel.vehicleItem.isReviewed = true;
                    if (!this.dao.isTrailer) {
                        this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
                        if (this.dao.vehicleModel.VehiclesList.length - 1 == this.dao.vehicleModel.vehicleIndex) {
                            this.dao.isTrailer = true;
                            this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] ? this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] : this.dao.vehicleModel.vehicleItem;
                            this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex + 1;
                        }
                        else {
                            this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex + 1;
                            this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] ? this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] : this.dao.vehicleModel.vehicleItem;
                        }
                    }
                    else {
                        this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
                        this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex + 1;
                        this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] ? this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] : this.dao.vehicleModel.vehicleItem;
                    }
                }
                else {
                    this.dao.vehicleModel.vehiclePage = this.dao.vehicleModel.vehiclePage - 1;
                }
            }
            else {
                if (key == 'Next') {
                    this.dao.vehicleModel.vehiclePage = this.dao.vehicleModel.vehiclePage + 1;
                }
                else {
                    this.dao.vehicleModel.vehiclePage = this.dao.vehicleModel.vehiclePage - 1;
                }

            }
            if (step == 1) {
                const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
                this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer = (this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer && this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer != '') ? this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer : accounState;
                this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress = (this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress.answer && this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress.answer != '') ? this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress : this.serviceLayer.createNewGaragingAddressFromAccountAddress(this.dao?.vehicleModel?.vehicleItem?.vehicleGaragingAddress?.group);
                this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer = (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0 && this.dao.vehicleModel.VehiclesList[0].vehicleSecondaryClass && this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer == '') ? this.dao.vehicleModel.VehiclesList[0].vehicleSecondaryClass.answer : this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer;
            }
      }
      this.validateVehicleListAndDups();
    }

    // ============= Remove Vehicle
    public async removeVehicle(item) {
        this.dao.IsDriverBreadcrumpClicked = false;
        this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.VehiclesList.indexOf(item);
        this.dao.vehicleModel.VehiclesList.splice(this.dao.vehicleModel.vehicleIndex, 1);
        this.dao.vehicleModel.VehiclesList.forEach((vehicle, index) => {
            Object.keys(vehicle).forEach(tempKey => {
                if (typeof (vehicle[tempKey]) == 'object') {
                    vehicle[tempKey].group = ('VEH-' + (index + 1));
                }
            });
        });

        if (!this.dao.isTrailer) {
            this.dao.vehicleModel.vehiclePage = 1;
            if (this.dao.vehicleModel.VehiclesList.length == 0) {
                this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                this.serviceLayer.addVehicle('vehicle');
            }
            else {
                if (!!this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex]) {
                    this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex]);
                }
                else {
                    if (this.dao.vehicleModel.trailerList.length > 0) {
                        if (this.dao.vehicleModel.VehiclesList.length > 0 && !!!this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex]) {
                            this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex - 1;
                        }
                        this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex])
                        this.dao.isTrailer = true;
                    }
                    else if (!!this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex - 1]) {
                        this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex - 1;
                        this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex]);
                    }
                    else {
                        this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                    }
                }
            }
        }
    }
    // ============= Remove Trailer
    public async removeTrailer(item) {
        this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerList.indexOf(item);
        this.dao.vehicleModel.trailerList.splice(this.dao.vehicleModel.trailerIndex, 1);
        this.dao.vehicleModel.trailerList.forEach((trailer, index) => {
            Object.keys(trailer).forEach(tempKey => {
                if (typeof (trailer[tempKey]) == 'object') {
                    trailer[tempKey].group = ('TRA-' + (index + 1));
                }
            });
        });
        if (this.dao.isTrailer) {
            if (this.dao.vehicleModel.trailerList.length > 0 && !!!this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex]) {
                this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex - 1;
            }
            this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] ? this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] : this.dao.vehicleModel.vehicleItem);
            this.dao.vehicleModel.vehiclePage = 1;
            if (this.dao.vehicleModel.trailerList.length == 0) {
                this.dao.isTrailer = false;
                if (this.dao.vehicleModel.VehiclesList.length > 0) {
                    this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.VehiclesList.length - 1];
                    this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.VehiclesList.indexOf(this.dao.vehicleModel.vehicleItem);
                }
                else {
                    this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                    this.serviceLayer.addVehicle('vehicle');
                }
            }
        }
    }
    
    public async radiusBulkEditCheckBoxChecked(e: InputEvent){
        if (this.dao.VehicleBulkEditRadiusCheckbox){
            await this.serviceLayer.saveRadiusForAllUnits(this.dao.vehicleModel.VehiclesList[0], 'BulkEditCheckBox');
        }        
    }

    public async compBulkEditCheckBoxChecked(e: InputEvent){
        if (this.dao.VehicleBulkEditCompCoverageCheckbox) {
            await this.serviceLayer.saveCompDeductibleForAllUnits(this.dao.vehicleModel.VehiclesList[0], 'BulkEditCheckBox');
        }
    }
    public async collisionBulkEditCheckBoxChecked(e: InputEvent) {
        if (this.dao.VehicleBulkEditCollCoverageCheckbox) {
            await this.serviceLayer.saveCollDeductibleForAllUnits(this.dao.vehicleModel.VehiclesList[0], 'BulkEditCheckBox');
        }
        if (this.dao.VehicleBulkEditCollCoverageCheckbox) {
            if (this.dao.vehicleModel.trailerList.length > 0) {
                this.dao.vehicleModel.trailerList.forEach(trailer => {
                    if (trailer.floorTrailer.answer == '') {
                        trailer.floorTrailer.answer = 'No';
                    }
                })
            }
        }
    }

    public async garagingBulkEditCheckboxChecked(e: InputEvent){
        if (this.dao.VehicleBulkEditGaragingCheckbox) {            
            await this.serviceLayer.saveGaragingInformationForAllUnits(this.dao.vehicleModel.VehiclesList[0], 'BulkEditCheckBox');
        }
    }

    public async customPartsBulkEditCheckBoxChecked(e: InputEvent){
        if (this.dao.VehicleBulkEditCustomPartsCheckbox) {            
            await this.serviceLayer.saveCustomPartsForAllUnits(this.dao.vehicleModel.VehiclesList[0], 'BulkEditCheckBox');
        }
    }

    public async additionalInsuredBulkEditCheckBoxChecked(e: InputEvent){
        if (this.dao.VehicleBulkEditInsuredLossPayeeCheckbox) {            
            await this.serviceLayer.saveInsuredLossPayeeForAllUnits(this.dao.vehicleModel.VehiclesList[0], 'BulkEditCheckBox');
        } 
    }
      
    public get disableRadiusBulkEditCheckbox(): boolean
    {
        return !this.dao.vehicleModel.VehiclesList.some(x => x.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 == false)
            && !this.dao.vehicleModel.trailerList.some(x => x.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 == false );
    }

    public get disableCompBulkEditCheckbox(): boolean
    {
        return !this.dao.vehicleModel.VehiclesList.some(x => x.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false)
        && !this.dao.vehicleModel.trailerList.some(x => x.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false );
    }

    public get disableCollBulkEditCheckbox(): boolean
    {
        return !this.dao.vehicleModel.VehiclesList.some(x => x.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false)
            && !this.dao.vehicleModel.trailerList.some(x => x.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false );
    }

    public get disableCustomPartsBulkEditCheckbox(): boolean
    {
        return !this.dao.vehicleModel.VehiclesList.some(x => x.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false)
        && !this.dao.vehicleModel.trailerList.some(x => x.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false );
    }

    public get disableGagragingAddressBulkEditCheckbox(): boolean
    {
        return !this.dao.vehicleModel.VehiclesList.some(x => x.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false)
        && !this.dao.vehicleModel.trailerList.some(x => x.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false );
    }

    public get disableAddInsuredBulkEditCheckbox(): boolean
    {
        return !this.dao.vehicleModel.VehiclesList.some(x => x.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false)
        && !this.dao.vehicleModel.trailerList.some(x => x.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false);
    }



    // ========= Cancel =====================
    public cancel(): void {
        this.messageService.clear();
        document.body.style.pointerEvents = "visible";
    }

    public skipVehicleAgeValidation(): void {
        this.vehicleAgeWarning = true;
        this.messageService.clear();
        document.getElementById('btnsaveContinueID').click();
        document.body.style.pointerEvents = "visible";
    }
    private checkSameZone(vehicleItem) {
        let validationStr = '';
        if (vehicleItem.length > 0) {
            vehicleItem.forEach(z => {
                if (vehicleItem.filter(zone => zone.zone == z.zone).length > 1) {
                    validationStr = "This vehicle cannot have the same zone, please select a different zone.";
                }
            });
        }
        return validationStr;
    }
    public EditAddZone() {
        var tempArray = { 'zone': '', 'percentage': '', 'helpText': '' };
        let validationStr = this.EditcheckSameZone();
        if (validationStr == '') {
            var tempObj = _.cloneDeep(this.dao.editAllUnits.zoneList);
            tempObj.push(tempArray);
            this.dao.editAllUnits.zoneList = _.cloneDeep(tempObj);
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', validationStr);
        }
    }
    private EditcheckSameZone() {
        let validationStr = '';
        if (this.dao.editAllUnits && this.dao.editAllUnits.zoneList && this.dao.editAllUnits.zoneList.length > 0) {
            this.dao.editAllUnits.zoneList.forEach(z => {
                if (this.dao.editAllUnits.zoneList.filter(zone => zone.zone == z.zone).length > 1) {
                    validationStr = "This vehicle cannot have the same zone, please select a different zone.";
                }
            });
        }
        return validationStr;
    }

    // =============vehicle Oninit====
    public initializeData(apiResponseContainer: ResponseContainer) {
        this.buildVehicleDataModel(apiResponseContainer);
        this.dao.isTrailer = false;
        if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0) {
            if (this.dao.finalSubmitPage != 'driver' && this.dao.finalSubmitPage != 'vehicles' && this.dao.finalSubmitPage != 'product' && this.dao.finalSubmitPage != 'application') {
                this.dao.vehicleModel.VehiclesList.forEach(v => {
                    v.isReviewed = false;
                });
            }
        }
        if (this.dao.vehicleModel.trailerList && this.dao.vehicleModel.trailerList.length > 0) {
            if (this.dao.finalSubmitPage != 'driver' && this.dao.finalSubmitPage != 'vehicles' && this.dao.finalSubmitPage != 'product' && this.dao.finalSubmitPage != 'application') {
                this.dao.vehicleModel.trailerList.forEach(v => {
                    v.isReviewed = false;
                });
            }
        }
        this.dao.vehicleModel.vehiclePage = 1;
        this.dao.initialvehicleModel = _.cloneDeep(this.dao.vehicleModel);
        this.savedAsVehicleModel = _.cloneDeep(this.dao.vehicleModel);
    }


    // ============= Change Vehicles and Trailers data
    public async buildVehicleDataModel(responseContainer: ResponseContainer) {

            let newVehicleModel: VehicleModel = new VehicleModel();
            this.vehicleModel = newVehicleModel;
            this.dao.isTrailer = false;
    
            let getVehicleQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="vehicleType" && @.answer != "Unknown")]';
            let powerUnitTypesList: Array<QuestionAnswer> = this.serviceLayer.getObjUsingJpath(responseContainer, getVehicleQuery);
            let getTrailerQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="vehicleType" && @.answer == "Unknown")]';
            let trailerTypesList: Array<QuestionAnswer> = this.serviceLayer.getObjUsingJpath(responseContainer, getTrailerQuery);
            let vehicleModelQuery = '$..questionnaire[?(@.key =="vehicle")].questionAnswer';
            let templatevehicleQsnrQAs = this.serviceLayer.getObjUsingJpath(responseContainer, vehicleModelQuery);
            
            //Copy templated vehicle QAs to newVehicleModel.vehicleDefault
            if (templatevehicleQsnrQAs && templatevehicleQsnrQAs.length > 0 && templatevehicleQsnrQAs[0] && templatevehicleQsnrQAs[0].length > 0) {
                templatevehicleQsnrQAs[0].forEach(templateQA => {
                    newVehicleModel.vehicleDefault[templateQA.key] = templateQA;
                    if ((newVehicleModel.vehicleDefault[templateQA.key].answer == undefined || newVehicleModel.vehicleDefault[templateQA.key].answer == '') && newVehicleModel.vehicleDefault[templateQA.key].questionDefault && newVehicleModel.vehicleDefault[templateQA.key].questionDefault.length > 0) {
                        newVehicleModel.vehicleDefault[templateQA.key].answer = newVehicleModel.vehicleDefault[templateQA.key].questionDefault[0].code;
                    }
                });
            }
              
            let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
            let accountAddress: Address = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;

            newVehicleModel.VehiclesList = this.BuildVehicleDetailsList(powerUnitTypesList, newVehicleModel.vehicleDefault, accountAddress, "Vehicles");
            newVehicleModel.trailerList = this.BuildVehicleDetailsList(trailerTypesList, newVehicleModel.vehicleDefault, accountAddress, "trailer");
            //Submission reaches to vehilces page first time
            if (powerUnitTypesList && powerUnitTypesList.length == 0 && templatevehicleQsnrQAs && templatevehicleQsnrQAs.length > 0) {
                
                let fleetsizeData = responseContainer.dotQsnr.findQuestion("fleetSize")?.answer;
                let powerUnitsSize = !!fleetsizeData && Number(fleetsizeData) > 0 ? fleetsizeData : "1";
                for (var i = 0; i < Number(powerUnitsSize); i++) {
                    let newVehicle: vehicleDetails = _.cloneDeep(newVehicleModel.vehicleDefault);
                    newVehicle.vehicleGaragingAddress =  this.createNewGaragingAddressFromAccountAddress("", accountAddress);                
                    Object.keys(newVehicle).forEach(tempKey => {
                        if (typeof (newVehicle[tempKey]) == 'object') {
                            newVehicle[tempKey].group = ('VEH-' + (i + 1));
                            if(tempKey == 'vehicleToZone')
                            {
                                newVehicle[tempKey].questionOptions = [];
                            }
                        }
                    })
                    newVehicleModel.VehiclesList.push(newVehicle);
                }
            }
    
            newVehicleModel.vehicleIndex = 0;
            newVehicleModel.trailerIndex = 0;
            newVehicleModel.vehicleItem = newVehicleModel.VehiclesList[newVehicleModel.vehicleIndex];
            if (newVehicleModel.vehicleItem && newVehicleModel.vehicleItem.zoneList && newVehicleModel.vehicleItem.zoneList.length == 0)
            {
              newVehicleModel.vehicleItem.zoneList.push({ 'zone': '', 'percentage': '', 'helpText': '' });
              if (this.dao.editAllUnits.zoneList.length == 0)
              {
                 this.dao.editAllUnits.zoneList.push({ 'zone': '', 'percentage': '', 'helpText': '' });
              }                
            }
            if (newVehicleModel.vehicleItem && newVehicleModel.vehicleItem.interestList == undefined) {
                newVehicleModel.vehicleItem.interestList = [];
                this.dao.editAllUnits.interestList = [];
            }
            if (newVehicleModel.vehicleItem && newVehicleModel.vehicleItem.interestList && newVehicleModel.vehicleItem.interestList.length == 0) {
                newVehicleModel.vehicleItem.interestList.push({ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' });
                if (this.dao.editAllUnits.interestList.length == 0)
                {
                    this.dao.editAllUnits.interestList.push({ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' });
                }                
            }
            let getBulkDataQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="bulkEditRadiusCheckbox" || @.key =="bulkEditCompCoverageCheckbox" || @.key =="bulkEditCollCoverageCheckbox"|| @.key =="bulkEditGaragingCheckbox" || @.key =="bulkEditCustomPartsCheckbox" || @.key =="bulkEditInsuredLossPayeeCheckbox"  )]';
            let BulkEditCheckBoxes = this.serviceLayer.getObjUsingJpath(responseContainer, getBulkDataQuery);
            let BulkEditCheckBoxes2 =responseContainer.vehiclePrefillQsnr.questionAnswer.filter
            (x => x.key =="bulkEditRadiusCheckbox" 
                || x.key =="bulkEditCompCoverageCheckbox" 
                || x.key =="bulkEditCollCoverageCheckbox"
                || x.key =="bulkEditGaragingCheckbox" 
                || x.key =="bulkEditCustomPartsCheckbox" 
                || x.key =="bulkEditInsuredLossPayeeCheckbox");
            let moreThanOnePW = newVehicleModel.VehiclesList.length > 1 
                                || (newVehicleModel.VehiclesList.length == 1 && newVehicleModel.trailerList.length > 0);
            if (moreThanOnePW && BulkEditCheckBoxes.filter(x => x.key == 'bulkEditRadiusCheckbox')[0]?.answer == "true") {
                this.dao.VehicleBulkEditRadiusCheckbox = true;                                
                this.dao.editAllUnits.vehicleTotalMilesUpto50.answer = newVehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer;
                this.dao.editAllUnits.vehicleTotalMilesUpto200.answer = newVehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer;
                this.dao.editAllUnits.vehicleTravelOver200.answer = newVehicleModel.vehicleItem.vehicleTravelOver200.answer;
                this.dao.editAllUnits.vehicleToZone.answer = newVehicleModel.vehicleItem.vehicleToZone.answer;
                this.dao.editAllUnits.vehicleApplicableZonePercent.answer = newVehicleModel.vehicleItem.vehicleApplicableZonePercent.answer;
                this.dao.editAllUnits.farthestApplicableZonePercent.answer = newVehicleModel.vehicleItem.farthestApplicableZonePercent.answer;
                this.dao.editAllUnits.farthestToZone.answer = newVehicleModel.vehicleItem.farthestToZone.answer;
                this.dao.editAllUnits.zoneList = _.cloneDeep(newVehicleModel.vehicleItem.zoneList);                
            }
            else
            {
                this.dao.VehicleBulkEditRadiusCheckbox = false;
                this.dao.editAllUnits.vehicleTotalMilesUpto50.answer = '';
                this.dao.editAllUnits.vehicleTotalMilesUpto200.answer = '';
                this.dao.editAllUnits.vehicleTravelOver200.answer = '';
                this.dao.editAllUnits.vehicleToZone.answer = '';
                this.dao.editAllUnits.vehicleApplicableZonePercent.answer = '';
                this.dao.editAllUnits.farthestApplicableZonePercent.answer = '';
                this.dao.editAllUnits.farthestToZone.answer = '';
                this.dao.editAllUnits.zoneList = [{ 'zone': '', 'percentage': '', 'helpText': '' }];
            }
            if (moreThanOnePW && BulkEditCheckBoxes.filter(x => x.key == 'bulkEditCompCoverageCheckbox')[0]?.answer == "true") {
                this.dao.VehicleBulkEditCompCoverageCheckbox = true;                
                this.dao.editAllUnits.vehicleCompOTCCoverage.answer = newVehicleModel.vehicleItem.vehicleCompOTCCoverage.answer;
                this.dao.editAllUnits.vehicleComp.answer = newVehicleModel.vehicleItem.vehicleComp.answer;
                if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
                    this.dao.editAllUnits.vehicleColl.answer = newVehicleModel.vehicleItem.vehicleColl.answer;
                }                
            } else {
                this.dao.VehicleBulkEditCompCoverageCheckbox = false;
                this.dao.editAllUnits.vehicleComp.answer = '';
                if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
                    this.dao.editAllUnits.vehicleColl.answer = '';
                }
            }

            if (moreThanOnePW && BulkEditCheckBoxes.filter(x => x.key == 'bulkEditCollCoverageCheckbox')[0]?.answer == "true") {
                this.dao.VehicleBulkEditCollCoverageCheckbox = true;                                
                this.dao.editAllUnits.vehicleCollCoverage.answer = newVehicleModel.vehicleItem.vehicleCollCoverage.answer;
                this.dao.editAllUnits.vehicleColl.answer = newVehicleModel.vehicleItem.vehicleColl.answer;
                if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled) {
                    this.dao.editAllUnits.Q_limitedCollision.answer = newVehicleModel.VehiclesList[0].Q_limitedCollision.answer;
                    this.dao.editAllUnits.limitedCollision.answer = newVehicleModel.VehiclesList[0].limitedCollision.answer;
                    this.dao.editAllUnits.Q_WhichCollCoverage.answer = newVehicleModel.VehiclesList[0].Q_WhichCollCoverage.answer;
                    this.dao.editAllUnits.broadenedCollision.answer = newVehicleModel.VehiclesList[0].broadenedCollision.answer;
                }
            } else {
                this.dao.VehicleBulkEditCollCoverageCheckbox = false;
                this.dao.editAllUnits.vehicleCollCoverage.answer = '';
                this.dao.editAllUnits.vehicleColl.answer = '';
                if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled) {
                    this.dao.editAllUnits.Q_limitedCollision.answer = '';
                    this.dao.editAllUnits.limitedCollision.answer = '';
                    this.dao.editAllUnits.Q_WhichCollCoverage.answer = '';
                    this.dao.editAllUnits.broadenedCollision.answer = '';
                }
            }
    
            if (moreThanOnePW && BulkEditCheckBoxes.filter(x => x.key == 'bulkEditGaragingCheckbox')[0]?.answer == "true") {
                this.dao.VehicleBulkEditGaragingCheckbox = true;                
                this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer = newVehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer;
                this.dao.editAllUnits.vehicleGarageStreetAddress.answer = newVehicleModel.vehicleItem.vehicleGarageStreetAddress.answer;
                this.dao.editAllUnits.vehicleGarageState.answer = newVehicleModel.vehicleItem.vehicleGarageState.answer;
                this.dao.editAllUnits.vehicleGarageCity.answer = newVehicleModel.vehicleItem.vehicleGarageCity.answer;
                this.dao.editAllUnits.vehicleGaragePostalCode.answer = newVehicleModel.vehicleItem.vehicleGaragePostalCode.answer;
                this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = newVehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1;                
            }
            else {
                this.dao.VehicleBulkEditGaragingCheckbox = false;
                this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer = accountAddress.toString();
                this.dao.editAllUnits.vehicleGarageStreetAddress.answer = accountAddress.street;
                this.dao.editAllUnits.vehicleGarageState.answer = accountAddress.state;
                this.dao.editAllUnits.vehicleGarageCity.answer = accountAddress.city;
                this.dao.editAllUnits.vehicleGaragePostalCode.answer = accountAddress.postalCode;                
            }

            if (moreThanOnePW && BulkEditCheckBoxes.filter(x => x.key == 'bulkEditCustomPartsCheckbox')[0]?.answer == "true") {
                this.dao.VehicleBulkEditCustomPartsCheckbox = true;                
                this.dao.editAllUnits.vehicleCustomPartsParent.answer = newVehicleModel.vehicleItem.vehicleCustomPartsParent.answer;
            }
            else
            {
                this.dao.editAllUnits.vehicleCustomPartsParent.answer = '';
                this.dao.VehicleBulkEditCustomPartsCheckbox = false;
            }

            if (moreThanOnePW && BulkEditCheckBoxes.filter(x => x.key == 'bulkEditInsuredLossPayeeCheckbox')[0]?.answer == "true") {
                this.dao.VehicleBulkEditInsuredLossPayeeCheckbox = true;
                this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer = newVehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer;
                this.dao.editAllUnits.interestList = _.cloneDeep(newVehicleModel.vehicleItem.interestList);
            }else{
                this.dao.VehicleBulkEditInsuredLossPayeeCheckbox = false;
                this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer = '';
                this.dao.editAllUnits.interestList = [{ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' }];
            }
    }
    
    // ============= Get Build Vehicle Json ===============
    public BuildVehicleDetailsList(vehilceTypesList: readonly QuestionAnswer[], vehicleDefault: vehicleDetails, accountAddress: Address, type: string): Array<vehicleDetails> {
            let vehicleDetailsList: Array<vehicleDetails> = [];
            if (vehilceTypesList) {
               vehilceTypesList.forEach(async (qa: QuestionAnswer) => {
                    let existingVehicleQAs: Array<QuestionAnswer> = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.group =="' + qa.group + '")]');
                    let curVehicleDataModel: vehicleDetails = _.cloneDeep(vehicleDefault);
                    let TempGroup = '';
                    let zoneIndexMax = _.max(this.serviceLayer.getObjUsingJpath(existingVehicleQAs, '$..[?(@.key =="vehicleToZone" || @.key =="vehicleApplicableZonePercent")].rowIndex'));
                    let zoneQAsArray: Array<QuestionAnswer> = this.serviceLayer.getObjUsingJpath(existingVehicleQAs, '$..[?(@.key =="vehicleToZone" || @.key =="vehicleApplicableZonePercent")]');
                    let interestIndex = this.serviceLayer.getObjUsingJpath(existingVehicleQAs, '$..[?(@.key =="addInsuredInterestType" || @.key =="addInsuredName" || @.key =="addInsuredStreetAddress" || @.key =="addInsuredCity" || @.key =="addInsuredState" || @.key =="addInsuredPostalCode" || @.key =="addInsuredLoanNumber")].rowIndex');
                    var interestIndexSorted = [...new Set(interestIndex)]?.sort();
                    let interestQAsArray: Array<QuestionAnswer> = this.serviceLayer.getObjUsingJpath(existingVehicleQAs, '$..[?(@.key =="addInsuredInterestType" || @.key =="addInsuredName" || @.key =="addInsuredStreetAddress" || @.key =="addInsuredCity" || @.key =="addInsuredState" || @.key =="addInsuredPostalCode" || @.key =="addInsuredLoanNumber")]');
                    //Assign current vehilce's Question Answers to curVehicleDataModel
                    
                    existingVehicleQAs.forEach(qa => {
                        curVehicleDataModel[qa.key] =  _.cloneDeep(qa);
                        TempGroup = qa.group;
                        if ((curVehicleDataModel[qa.key].answer == undefined || curVehicleDataModel[qa.key].answer == '') && curVehicleDataModel[qa.key].questionDefault && curVehicleDataModel[qa.key].questionDefault.length > 0) {
                            curVehicleDataModel[qa.key].answer = curVehicleDataModel[qa.key].questionDefault[0].code;
                        }
                        if ((curVehicleDataModel[qa.key].answer != '') && curVehicleDataModel[qa.key].key == 'vehicleBodyType' && vehicleDefault.vehicleBodyType.questionDefault && vehicleDefault.vehicleBodyType.questionDefault.length > 0) {
                            curVehicleDataModel[qa.key].answer = vehicleDefault.vehicleBodyType.questionDefault[0].code;
                        }
                    });
    
                    //Assign curVehicleDataModel's Group
                    Object.keys(curVehicleDataModel).forEach(tempKey => {
                        if (typeof (curVehicleDataModel[tempKey]) == 'object') {
                            curVehicleDataModel[tempKey].group = TempGroup;
                            if (curVehicleDataModel[tempKey].questionOptions != null && curVehicleDataModel[tempKey].questionOptions.length > 0) {
                                curVehicleDataModel[tempKey].questionOptions = []; //empty question options to reduce payload size
                            }
                        }
                    });
                    curVehicleDataModel.zoneList = [];
                    //Added vehicleToZone question answers to zoneList
                    if (zoneIndexMax >= 0) {
                        
                        for (var j = 1; j <= zoneIndexMax; j++) {
                            if (zoneQAsArray && zoneQAsArray.filter(z => z.rowIndex == j && z.key == 'vehicleToZone') 
                                && zoneQAsArray.filter(z => z.rowIndex == j && z.key == 'vehicleToZone')[0] 
                                && zoneQAsArray.filter(z => z.rowIndex == j && z.key == 'vehicleToZone')[0].answer != '') {
                                curVehicleDataModel.zoneList.push(
                                    {
                                      'zone': zoneQAsArray.filter(z => z.rowIndex == j && z.key == 'vehicleToZone')[0].answer, 
                                      'percentage': zoneQAsArray.filter(z => z.rowIndex == j && z.key == 'vehicleApplicableZonePercent')[0].answer,
                                      'helpText': vehicleDefault.vehicleToZone.questionOptions.filter(z => z.value == zoneQAsArray.filter(z => z.rowIndex == j && z.key == 'vehicleToZone')[0].answer)[0].helpText
                                    });
                            }
                            if (j == zoneIndexMax && curVehicleDataModel.zoneList.length == 0) {
                                curVehicleDataModel.zoneList.push({ 'zone': '', 'percentage': '', 'helpText': '' });
                            }
                        }
                    }
                    else {
                        curVehicleDataModel.zoneList.push({ 'zone': '', 'percentage': '', 'helpText': '' });
                    }
    
                    //Added interestList question answers to zoneList
                    curVehicleDataModel.interestList = [];
                    if (interestIndexSorted !== undefined) {
                        interestIndexSorted.forEach((j, i) => {
                            if (interestQAsArray && interestQAsArray?.length > 0 && interestQAsArray.filter(z => z.rowIndex == j).length > 0) {
                                curVehicleDataModel.interestList.push({
                                    'Type': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredInterestType')[0].answer,
                                    'Name': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredName')[0].answer,
                                    'streetAddress': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredStreetAddress')[0].answer,
                                    'city': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredCity')[0].answer,
                                    'state': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredState')[0].answer,
                                    'zip': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredPostalCode')[0].answer,
                                    'loanNo': interestQAsArray.filter(z => z.rowIndex == j && z.key == 'addInsuredLoanNumber')[0].answer
                                });
                            }
                        });
                    }
                    else {
                        curVehicleDataModel.interestList.push({ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' });
                    }

                    //Rest vehicle garage address
                    curVehicleDataModel.vehicleGaragingAddress = curVehicleDataModel.vehicleGaragingAddress.answer != '' ? curVehicleDataModel.vehicleGaragingAddress : this.createNewGaragingAddressFromAccountAddress(curVehicleDataModel?.vehicleGaragingAddress?.group, accountAddress);
                    let garagingAddressChecked = curVehicleDataModel.vehicleIsCorrectGaragingAddress.answer;

                    if (curVehicleDataModel.vehicleGarageStreetAddress.answer != accountAddress.street && garagingAddressChecked != 'No') {
                        curVehicleDataModel.vehicleGarageStreetAddress.answer = accountAddress.street;
                    }
                    else if (curVehicleDataModel.vehicleGarageCity.answer != accountAddress.city && garagingAddressChecked != 'No') {
                        curVehicleDataModel.vehicleGarageCity.answer = accountAddress.city;
                    }
                    else if (curVehicleDataModel.vehicleGarageState.answer != accountAddress.state && garagingAddressChecked != 'No') {
                        curVehicleDataModel.vehicleGarageState.answer = accountAddress.state;
                    }
                    else if (curVehicleDataModel.vehicleGaragePostalCode.answer != accountAddress.postalCode && garagingAddressChecked != 'No') {
                        curVehicleDataModel.vehicleGaragePostalCode.answer = accountAddress.postalCode;
                    }

                    curVehicleDataModel.isReviewed = (this.dao.finalSubmitPage == 'driver' || this.dao.finalSubmitPage == 'product' || this.dao.finalSubmitPage == 'application') ? true : false;
                    curVehicleDataModel[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].helpText = vehicleDefault[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].questionOptions.filter(c => c.value == curVehicleDataModel[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].answer)[0]?.helpText;
    
                    if (this.dao.finalSubmitPage == 'vehicles') {
                        curVehicleDataModel.isReviewed = curVehicleDataModel.isReviewedModal.answer == 'false' ? false : true;
                    }
                    vehicleDetailsList.push(curVehicleDataModel);
               });
            }
            return vehicleDetailsList;
    }

    public shouldLongDistanceNewTruckingDisplay(): boolean {
        const policyEffectiveDate = this.dao.DOTList?.accountInfoQsnr.findQuestion('policyEffectiveDate').answer;
        const accountState = this.dao.DOTList?.accountInfoQsnr.findQuestion('accountState').answer;
        if(this.serviceLayer.getPolicyHolderVersionNTM(policyEffectiveDate, accountState)) {
            return true;
        }

        return false;
    }

    saveAndContinue(evt: any, ...rest: any[]) {
        throw new Error('Method not implemented.');
    }

    public ngOnDestroy(): void {
        this.apiCallSubscription?.unsubscribe();
        this.menuClickSubscription?.unsubscribe();
    }
   
      // ============= Get Garaging Address ==========
    public createNewGaragingAddressFromAccountAddress(group: string = '', accountAddress: Address): emptyModal {      
      let garagingAddress: emptyModal = new emptyModal();
      garagingAddress.key = 'vehicleGaragingAddress';
      garagingAddress.answer = accountAddress.toString();
      garagingAddress.group = group != '' ? group : 'vehicle';
      return garagingAddress;
    }

    public get vehicleModel(): VehicleModel
    {
        return this.dao.vehicleModel
    }

    public set vehicleModel(value: VehicleModel)
    {
        this.dao.vehicleModel = value;
    }

    public get savedAsItIs(): boolean
    {
        if(this.vehicleModel.VehiclesList != null)
        {
            return _.isEqual(this.vehicleModel.VehiclesList, this.savedAsVehicleModel.VehiclesList) &&
            _.isEqual(this.vehicleModel.trailerList, this.savedAsVehicleModel.trailerList);
        }
        else
            return true;
        
    }
    public get fleetAged():boolean{        
        return this.CheckIsFleetAged(this.dao.vehicleModel.VehiclesList);        
    }

    public CheckIsFleetAged(vehicleList): boolean {        
        if (this.dao.DOTList?.vehicleQsnr?.findQuestion("vehicleAge") == null)
        {
            return false;
        }
        let fleetAged: boolean = false;
        if (!!vehicleList) {
            let numberOfVehicles = vehicleList.length;
            let numberOfVehicleWithAgeGreaterThan25 = vehicleList.filter(
                    x => Number(x.vehicleYear?.answer) > 0
                     && (new Date().getFullYear() - Number(x.vehicleYear?.answer)) > 25)?.length;
            if (numberOfVehicles > 0) {
                let vehicleAge = (numberOfVehicleWithAgeGreaterThan25 / numberOfVehicles) * 100;
                fleetAged = vehicleAge > 25;
            }
        }        
        return fleetAged
    }
}
