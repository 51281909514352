import { Injectable, Injector } from '@angular/core';
import { DAO } from '../modal/DAO'
import _ from 'lodash';

@Injectable({
  providedIn: 'any'
})
export class ChangeDetectionService {
  constructor(public dao: DAO,) { }

  public HasCurrentPolicyTemplateDataChanged(): boolean {
    return true;
  }
}
