import { Injectable } from "@angular/core";
import { DAO, ProductModel } from '../modal/DAO';
import { StateSettings } from '../modal/state.models';
import { QuestionAnswer, Questionnaire } from "../modal/questionnaire.models";
import _ from 'lodash';

@Injectable({
    providedIn: 'any'
})
export class ProductService {

    constructor(private dao: DAO, private state: StateSettings) {

    }

    public productModel: ProductModel = new ProductModel(); //Product Scren
    
    public getQuestionnaire(): Questionnaire {
        return this.dao.DOTList?.questionnaire?.find(q => q.key === 'product') as Questionnaire;
    }
    public AddNamedIndividual() {
        var nameOfNamedIndividual = { 'Name': "", 'isDeleted': false };
        var tempNamedIndividual = _.cloneDeep(this.productModel.AddAnotherNamedIndividual);
        tempNamedIndividual.push(nameOfNamedIndividual);
        this.productModel.AddAnotherNamedIndividual = _.cloneDeep(tempNamedIndividual);
    }
    public getValidationMessage(key: string): string {
        let accountState = this.getAccountStateAnswer();
        let validationMessage: string = this.state.getStateSpecificValidationMessage.find(x => x.key == key && x.state == accountState)?.errorMessage;
        if (!validationMessage) {
            validationMessage = this.state.commonValidationMessages.find(x => x.key === key)?.errorMessage;
        }
        return validationMessage;
    }   
    
    public getAccountStateAnswer() {
        return this.dao.DOTList?.questionnaire?.find(x => x.key == 'AccountInfo').questionAnswer?.find(x => x.key == 'accountState').answer;
    }
    public getAccountLegalEntityAnswer() {
        return this.dao.DOTList?.questionnaire?.find(x => x.key == 'AccountInfo').questionAnswer.find(x => x.key == 'legalEntity').answer;
    }

      // ========== Set Product Json===============
      public setProductData(productModel: ProductModel) {
        let productJSON = new Array<QuestionAnswer>();
        let tempProductDetails = _.cloneDeep(productModel.productDetails);
        let temppremiseStreet = tempProductDetails.premiseStreet;
        let temppremiseCity = tempProductDetails.premiseCity;
        let temppremiseState = tempProductDetails.premiseState;
        let temppremisePostalCode = tempProductDetails.premisePostalCode;
        let temppayrollAmount = tempProductDetails.payrollAmount;
        let tempadditionalInsuredName = tempProductDetails.additionalInsuredName;
        let tempwavierOfSubrogationName = tempProductDetails.glWavierOfSubrogationName;
        let temppremiseLocation = tempProductDetails.premiseLocation;
        let tempadditionalInsuredNameForCommercialAuto = tempProductDetails.insurableInterestName;
        let tempadditionalStreetForCommercialAuto = tempProductDetails.insurableInterestStreet;
        let tempadditionalCityForCommercialAuto = tempProductDetails.insurableInterestCity;
        let tempadditionalStateForCommercialAuto = tempProductDetails.insurableInterestState;
        let tempadditionalPostalCodeForCommercialAuto = tempProductDetails.insurableInterestPostalCode;
        let tempwavierOfSubrogationNameForCommercialAuto = tempProductDetails.autoWavierOfSubrogationName;
        let tempadditionalInsuredNameForCargo = tempProductDetails.cargoInsuredName;
        let tempadditionalStreetForCargo = tempProductDetails.cargoInsuredStreet;
        let tempadditionalCityForCargo = tempProductDetails.cargoInsuredCity;
        let tempadditionalStateForCargo = tempProductDetails.cargoInsuredState;
        let tempadditionalPostalCodeForCargo = tempProductDetails.cargoInsuredPostalCode;
        let tempNamedIndividual = tempProductDetails.namedIndividual;
        // Including multiple properties in product
        if (!!productModel?.productDetails?.glIncluded?.answer && productModel?.productDetails?.glIncluded?.answer != 'No') {
          if (productModel.AddProductAnotherLocation.length > 0) {
            productModel.AddProductAnotherLocation.forEach((loc, index) => {
              if (index == 0) {
                let product = productModel.productDetails;
                product.premiseStreet.answer = loc.premiseStreet;
                product.premiseCity.answer = loc.premiseCity;
                product.premiseState.answer = loc.premiseState;
                product.premisePostalCode.answer = loc.premisePostalCode;
                product.payrollAmount.answer = loc.payrollAmount;
                product.premiseLocation.answer = loc.premiseLocation;
    
    
                Object.keys(product).forEach(key => {
                  if (key != 'group' && typeof (product[key]) == 'object') {
                    if (key == 'premiseStreet' || key == 'premiseCity' || key == 'premiseState'
                      || key == 'premisePostalCode' || key == 'payrollAmount' || key == 'premiseLocation') {
                      product[key].rowIndex = 1;
                    }
                    productJSON.push(product[key]);
                  }
                });
    
              }
              else {
                let maxindex = index + 1;
                temppremiseStreet.answer = loc.premiseStreet;
                temppremiseStreet.rowIndex = maxindex;
                temppremiseCity.answer = loc.premiseCity;
                temppremiseCity.rowIndex = maxindex;
                temppremiseState.answer = loc.premiseState;
                temppremiseState.rowIndex = maxindex;
                temppremisePostalCode.answer = loc.premisePostalCode;
                temppremisePostalCode.rowIndex = maxindex;
                temppayrollAmount.answer = loc.payrollAmount;
                temppayrollAmount.rowIndex = maxindex;
                temppremiseLocation.answer = loc.premiseLocation;
                temppremiseLocation.rowIndex = maxindex;
                // clone deep add for duplictes
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(temppremiseStreet);
                tempArray.push(temppremiseCity);
                tempArray.push(temppremiseState);
                tempArray.push(temppremisePostalCode);
                tempArray.push(temppayrollAmount);
                tempArray.push(temppremiseLocation);
                productJSON = _.cloneDeep(tempArray);
              }
            });
          }
    
        }
        else {
          let product = productModel.productDetails;
          Object.keys(product).forEach(key => {
            productJSON.push(product[key]);
          });
        }
    
        if (productModel.AddAnotherInsured.length > 0) {
          productModel.AddAnotherInsured.forEach((ins, index) => {
            let existsInProductDetails = false;
            if (index == 0) {
              productModel.productDetails.additionalInsuredName.answer = ins.Name;
              let q = productJSON.filter(x => x.key == tempadditionalInsuredName.key
                && x.group == tempadditionalInsuredName.group
                && x.rowIndex == 1
              );
              if (q.length == 1) {
                q[0].answer = ins.Name;
                existsInProductDetails = true;
              }
            }
            if (index > 0 || !existsInProductDetails) {
              let maxindex = index + 1;
              tempadditionalInsuredName.answer = ins.Name;
              tempadditionalInsuredName.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalInsuredName);
              productJSON = _.cloneDeep(tempArray);
            }
          });
        }
    
        if (productModel.AddAnotherWaiver.length > 0) {
          productModel.AddAnotherWaiver.forEach((ins, index) => {
            let existsInProductDetails = false;
            if (index == 0) {
              productModel.productDetails.glWavierOfSubrogationName.answer = ins.Name;
              let q = productJSON.filter(x => x.key == tempwavierOfSubrogationName.key
                && x.group == tempwavierOfSubrogationName.group
                && x.rowIndex == 1
              );
              if (q.length == 1) {
                q[0].answer = ins.Name;
                existsInProductDetails = true;
              }
            }
            if (index > 0 || !existsInProductDetails) {
              let maxindex = index + 1;
              tempwavierOfSubrogationName.answer = ins.Name;
              tempwavierOfSubrogationName.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempwavierOfSubrogationName);
              productJSON = _.cloneDeep(tempArray);
            }
          });
        }
        // Commercial Auto
        if (productModel.AddAnotherInsuredForCommercialAuto.length > 0) {
          productModel.AddAnotherInsuredForCommercialAuto.forEach((ins, index) => {
            let existsInProductDetails = false;
            if (index == 0) {
              productModel.productDetails.insurableInterestName.answer = ins.Name;
              productModel.productDetails.insurableInterestStreet.answer = ins.Street;
              productModel.productDetails.insurableInterestCity.answer = ins.City;
              productModel.productDetails.insurableInterestState.answer = ins.State;
              productModel.productDetails.insurableInterestPostalCode.answer = ins.PostalCode;
              let q1 = productJSON.filter(x => x.key == tempadditionalInsuredNameForCommercialAuto.key
                && x.group == tempadditionalInsuredNameForCommercialAuto.group
                && x.rowIndex == 1
              );
              if (q1.length >= 1) {
                q1[0].answer = ins.Name;
              }
              else {
                tempadditionalInsuredNameForCommercialAuto.answer = ins.Name;
                tempadditionalInsuredNameForCommercialAuto.rowIndex = 1;
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(tempadditionalInsuredNameForCommercialAuto);
                productJSON = _.cloneDeep(tempArray);
              }
    
              let q2 = productJSON.filter(x => x.key == tempadditionalStreetForCommercialAuto.key
                && x.group == tempadditionalStreetForCommercialAuto.group
                && x.rowIndex == 1
              );
              if (q2.length >= 1) {
                q2[0].answer = ins.Street;
              }
              else {
                tempadditionalStreetForCommercialAuto.answer = ins.Street;
                tempadditionalStreetForCommercialAuto.rowIndex = 1;
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(tempadditionalStreetForCommercialAuto);
                productJSON = _.cloneDeep(tempArray);
              }
    
              let q3 = productJSON.filter(x => x.key == tempadditionalCityForCommercialAuto.key
                && x.group == tempadditionalCityForCommercialAuto.group
                && x.rowIndex == 1
              );
              if (q3.length >= 1) {
                q3[0].answer = ins.City;
              }
              else {
                tempadditionalCityForCommercialAuto.answer = ins.City;
                tempadditionalCityForCommercialAuto.rowIndex = 1;
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(tempadditionalCityForCommercialAuto);
                productJSON = _.cloneDeep(tempArray);
              }
    
              let q4 = productJSON.filter(x => x.key == tempadditionalStateForCommercialAuto.key
                && x.group == tempadditionalStateForCommercialAuto.group
                && x.rowIndex == 1
              );
              if (q4.length >= 1) {
                q4[0].answer = ins.State;
              }
              else {
                tempadditionalStateForCommercialAuto.answer = ins.State;
                tempadditionalStateForCommercialAuto.rowIndex = 1;
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(tempadditionalStateForCommercialAuto);
                productJSON = _.cloneDeep(tempArray);
              }
    
              let q5 = productJSON.filter(x => x.key == tempadditionalPostalCodeForCommercialAuto.key
                && x.group == tempadditionalPostalCodeForCommercialAuto.group
                && x.rowIndex == 1
              );
              if (q5.length >= 1) {
                q5[0].answer = ins.PostalCode;
              }
              else {
                tempadditionalPostalCodeForCommercialAuto.answer = ins.PostalCode;
                tempadditionalPostalCodeForCommercialAuto.rowIndex = 1;
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(tempadditionalPostalCodeForCommercialAuto);
                productJSON = _.cloneDeep(tempArray);
              }
            }
            else {
              let maxindex = index + 1;
              tempadditionalInsuredNameForCommercialAuto.answer = ins.Name;
              tempadditionalInsuredNameForCommercialAuto.rowIndex = maxindex;
              tempadditionalStreetForCommercialAuto.answer = ins.Street;
              tempadditionalStreetForCommercialAuto.rowIndex = maxindex;
              tempadditionalCityForCommercialAuto.answer = ins.City;
              tempadditionalCityForCommercialAuto.rowIndex = maxindex;
              tempadditionalStateForCommercialAuto.answer = ins.State;
              tempadditionalStateForCommercialAuto.rowIndex = maxindex;
              tempadditionalPostalCodeForCommercialAuto.answer = ins.PostalCode;
              tempadditionalPostalCodeForCommercialAuto.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalInsuredNameForCommercialAuto);
              tempArray.push(tempadditionalStreetForCommercialAuto);
              tempArray.push(tempadditionalCityForCommercialAuto);
              tempArray.push(tempadditionalStateForCommercialAuto);
              tempArray.push(tempadditionalPostalCodeForCommercialAuto);
              productJSON = _.cloneDeep(tempArray);
            }
          });
        }
    
        if (productModel.AddAnotherWaiverForCommercialAuto.length > 0) {
            productModel.AddAnotherWaiverForCommercialAuto.forEach((ins, index) => {
            let existsInProductDetails = false;
            if (index == 0) {
              productModel.productDetails.autoWavierOfSubrogationName.answer = ins.Name;
              let q = productJSON.filter(x => x.key == tempwavierOfSubrogationNameForCommercialAuto.key
                && x.group == tempwavierOfSubrogationNameForCommercialAuto.group
                && x.rowIndex == 1
              );
              if (q.length >= 1) {
                q[0].answer = ins.Name;
                existsInProductDetails = true;
              }
            }
            if (index > 0 || !existsInProductDetails) {
              let maxindex = index + 1;
              tempwavierOfSubrogationNameForCommercialAuto.answer = ins.Name;
              tempwavierOfSubrogationNameForCommercialAuto.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempwavierOfSubrogationNameForCommercialAuto);
              productJSON = _.cloneDeep(tempArray);
            }
          });
        }
    
        if (productModel.productDetails.cargoIncluded.answer == 'Yes') {
          if (productModel.AddAnotherInsuredForCargo.length > 0) {
              productModel.AddAnotherInsuredForCargo.forEach((ins, index) => {
              let existsInProductDetails = false;
              if (index == 0) {
                productModel.productDetails.cargoInsuredName.answer = ins.Name;
                productModel.productDetails.cargoInsuredStreet.answer = ins.Street;
                productModel.productDetails.cargoInsuredCity.answer = ins.City;
                productModel.productDetails.cargoInsuredState.answer = ins.State;
                productModel.productDetails.cargoInsuredPostalCode.answer = ins.PostalCode;
                let q1 = productJSON.filter(x => x.key == tempadditionalInsuredNameForCargo.key
                  && x.group == tempadditionalInsuredNameForCargo.group
                  && x.rowIndex == 1
                );
                if (q1.length >= 1) {
                  q1[0].answer = ins.Name;
                }
                else {
                  tempadditionalInsuredNameForCargo.answer = ins.Name;
                  tempadditionalInsuredNameForCargo.rowIndex = 1;
                  let tempArray = _.cloneDeep(productJSON);
                  tempArray.push(tempadditionalInsuredNameForCargo);
                  productJSON = _.cloneDeep(tempArray);
                }
    
                let q2 = productJSON.filter(x => x.key == tempadditionalStreetForCargo.key
                  && x.group == tempadditionalStreetForCargo.group
                  && x.rowIndex == 1
                );
                if (q2.length >= 1) {
                  q2[0].answer = ins.Street;
                }
                else {
                  tempadditionalStreetForCargo.answer = ins.Street;
                  tempadditionalStreetForCargo.rowIndex = 1;
                  let tempArray = _.cloneDeep(productJSON);
                  tempArray.push(tempadditionalStreetForCargo);
                  productJSON = _.cloneDeep(tempArray);
                }
    
                let q3 = productJSON.filter(x => x.key == tempadditionalCityForCargo.key
                  && x.group == tempadditionalCityForCargo.group
                  && x.rowIndex == 1
                );
                if (q3.length >= 1) {
                  q3[0].answer = ins.City;
                }
                else {
                  tempadditionalCityForCargo.answer = ins.City;
                  tempadditionalCityForCargo.rowIndex = 1;
                  let tempArray = _.cloneDeep(productJSON);
                  tempArray.push(tempadditionalCityForCargo);
                  productJSON = _.cloneDeep(tempArray);
                }
    
                let q4 = productJSON.filter(x => x.key == tempadditionalStateForCargo.key
                  && x.group == tempadditionalStateForCargo.group
                  && x.rowIndex == 1
                );
                if (q4.length >= 1) {
                  q4[0].answer = ins.State;
                }
                else {
                  tempadditionalStateForCargo.answer = ins.State;
                  tempadditionalStateForCargo.rowIndex = 1;
                  let tempArray = _.cloneDeep(productJSON);
                  tempArray.push(tempadditionalStateForCargo);
                  productJSON = _.cloneDeep(tempArray);
                }
    
                let q5 = productJSON.filter(x => x.key == tempadditionalPostalCodeForCargo.key
                  && x.group == tempadditionalPostalCodeForCargo.group
                  && x.rowIndex == 1
                );
                if (q5.length >= 1) {
                  q5[0].answer = ins.PostalCode;
                }
                else {
                  tempadditionalPostalCodeForCargo.answer = ins.PostalCode;
                  tempadditionalPostalCodeForCargo.rowIndex = 1;
                  let tempArray = _.cloneDeep(productJSON);
                  tempArray.push(tempadditionalPostalCodeForCargo);
                  productJSON = _.cloneDeep(tempArray);
                }
              }
              else {
                let maxindex = index + 1;
                tempadditionalInsuredNameForCargo.answer = ins.Name;
                tempadditionalInsuredNameForCargo.rowIndex = maxindex;
                tempadditionalStreetForCargo.answer = ins.Street;
                tempadditionalStreetForCargo.rowIndex = maxindex;
                tempadditionalCityForCargo.answer = ins.City;
                tempadditionalCityForCargo.rowIndex = maxindex;
                tempadditionalStateForCargo.answer = ins.State;
                tempadditionalStateForCargo.rowIndex = maxindex;
                tempadditionalPostalCodeForCargo.answer = ins.PostalCode;
                tempadditionalPostalCodeForCargo.rowIndex = maxindex;
                // clone deep add for duplictes
                let tempArray = _.cloneDeep(productJSON);
                tempArray.push(tempadditionalInsuredNameForCargo);
                tempArray.push(tempadditionalStreetForCargo);
                tempArray.push(tempadditionalCityForCargo);
                tempArray.push(tempadditionalStateForCargo);
                tempArray.push(tempadditionalPostalCodeForCargo);
                productJSON = _.cloneDeep(tempArray);
              }
            });
          }
        }
    
        if (this.productModel.AddAnotherNamedIndividual.length > 0) {
            this.productModel.AddAnotherNamedIndividual.forEach((ins, index) => {
            let existsInProductDetails = false;
            if (index == 0) {
              productModel.productDetails.namedIndividual.answer = ins.Name;
              let q = productJSON.filter(x => x.key == tempNamedIndividual.key
                && x.group == tempNamedIndividual.group
                && x.rowIndex == 1
              );
              if (q.length >= 1) {
                q[0].answer = ins.Name;
                existsInProductDetails = true;
              }
            }
            if (index > 0 || !existsInProductDetails) {
              let maxindex = index + 1;
              tempNamedIndividual.answer = ins.Name;
              tempNamedIndividual.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempNamedIndividual);
              productJSON = _.cloneDeep(tempArray);
            }
          });
        }
    
        const productQuestionnaire = this.dao.DOTList.questionnaire.find(q => q.key === 'product') as Questionnaire;
    
        this.mapQuestionAnswerByKey('uimBiPrd', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('uimBi', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('uimBiPrdDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('uimPrd', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('uimPrdDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('tortLimitSelect', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('tortLimits', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('Q_nfBrd', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nfBrd', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nfBrdDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('sgEmployeresliab', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('sgPayroll', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('sgBIAccidentEach', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('sgBIDiseaseLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('sgBIDiseaseEach', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('atFaultLiab', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umBiDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umBiLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umPdLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umPdDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umPdPrd', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umPdPrdDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('insured_wc', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('lossOfIncomeBenefits', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('Q_addedPIP', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('addedPIP', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('addedPIPDed', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('hiredAutoLiability', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('annualCostOfHire', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('hiredAutoUimBi', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umReject', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umStacked', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('liabilityLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('mpLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('medExpenseBenefits', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('incomeLoss', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('extendedPip', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('excludeCoverageForWorkLoss', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('pipWorkLoss', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('hiredAutoUmBi', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('umEconomicLoss', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('fireSurcharge', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nfRateBasis', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('isExcludeUimBi', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('isExcludeUimBiPrd', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerDamageLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerCompDeductible', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerCollDeductible', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerRadius', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerZoneFrom', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerZoneTo', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nonOwnedTrailerPerDay', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nonOwnedTrailerPerYear', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('trailerShipments', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('pipDedAppliesTo', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('propertyLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('cargoShipperLimit', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nonOwnedAutoLiability', productQuestionnaire, productJSON);
        this.mapQuestionAnswerByKey('nonOwnedAutoLiabEmployeesCount', productQuestionnaire, productJSON);    
        productQuestionnaire.questionAnswer = _.cloneDeep(productJSON);
      }
    
      private mapQuestionAnswerByKey(key: string, questionnaire: Questionnaire, questionAnswers: Array<QuestionAnswer>): void {
        const questionAnswer = questionnaire?.questionAnswer.find(qa => qa.key === key);
    
        if (questionAnswer && questionAnswers) {
          questionAnswers.push(questionAnswer);
        }
      }    
}
