import { dropDownOptions } from "./DAO";
import { LineOfBusinessTypes } from "./Endorsements/AdditionalInsured.Model";
import { EndorsementTypes } from "./Endorsements/Endorsement.Model";

export class Constants {
  public static FleetSizeOptions: Array<dropDownOptions> = [
    { label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" },
    { label: "4", value: "4" }, { label: "5", value: "5" }, { label: "6", value: "6" },
    { label: "7", value: "7" }, { label: "8", value: "8" }, { label: "9", value: "9" },
    { label: "10", value: "10" }, { label: "11", value: "11" }, { label: "12", value: "12" },
    { label: "13", value: "13" }, { label: "14", value: "14" }
  ];
  public static BannerTypes: Array<dropDownOptions> = [
    { label: "Major/Critical", value: "critical" },
    { label: "Warning", value: "warning" },
    { label: "Informational", value: "info" }
  ];

  public static EndorsementTypes: Array<dropDownOptions> = [
    { label: "Add Driver", value: EndorsementTypes.AddDriver },
    { label: "Delete Driver", value: EndorsementTypes.DeleteDriver },
    { label: "Add Vehicle", value: EndorsementTypes.AddVehicle },
    { label: "Delete Vehicle", value: EndorsementTypes.DeleteVehicle },
    { label: "Add Additional Insured", value: EndorsementTypes.AddAdditionalInsured },
    { label: "Delete Additional Insured", value: EndorsementTypes.DeleteAdditionalInsured },
    { label: "Other", value: EndorsementTypes.otherEndorsements }
  ];

  public static EndorsementStatusDropDrown: Array<dropDownOptions> = [
    { label: "Submitted", value: "Submitted" },
    { label: "Incomplete", value: "Incomplete" }
  ];

  public static EndorsementBaseRoute: string = '/endorsements/';

  public static VehicleTypesDropDown: Array<dropDownOptions> = [
    { label: "Power Unit", value: "Power Unit" },
    { label: "Trailer", value: "Trailer" }
  ]

  public static InterestTypesDropDown: Array<dropDownOptions> = [
    { label: "Additional Insured", value: "Additional Insured" },
    { label: "Loss Payee", value: "Loss Payee" }
  ]
  
  public static ClearanceStatus: Array<string> =
    ["Additional Info Needed", "Quoted", "Close Quote", "Declined", "Decline Override"];

  public static InternalAdminUserActions: Array<dropDownOptions> = [
    { label: "Update Banner", value: "updateBanner" }
  ];
  public static FileNetAllowedFileExtensions: Array<string> = [
    '.mp3', '.mp4', '.wma', '.rar', '.zip', '.msg', '.eml', '.tiff', '.tif', '.jpg', '.jpeg', '.gif', '.png', '.bmp', '.psd', '.ai', '.svg', '.doc', '.dot', '.docx',
    '.dotx', '.docm', '.dotm', '.rtf', '.txt', '.xls', '.xlsx', '.xlsm', '.csv', '.one', '.ppt', '.pps', '.pptx', '.pptm', '.ppsx', '.ppsm', '.mpp', '.vsd', '.vsdx',
    '.vsdm', '.pdf', '.avi', '.mov', '.wmv', '.vcf', '.htm', '.html', '.xhtml', '.mhtml', '.mht', '.xml'
  ];
  public static readonly CurrentDriverExcelTemplateVersion: string = '1.0';
  public static readonly CurrentVehicleExcelTemplateVersion: string = '1.0';

  public static LineOfBusinessOptions: Array<dropDownOptions> = [
    { label: "Commercial Auto", value: LineOfBusinessTypes.CommercialAuto },
    { label: "GeneralLiability", value: LineOfBusinessTypes.GeneralLiability },
    { label: "Motor Truck Cargo", value: LineOfBusinessTypes.MotorTruckCargo }
  ];

  public static VehicleType: Array<dropDownOptions> = [
    { label: "Power Unit", value: "PowerUnit" },
    { label: "Trailer", value: "Trailer" }
  ];
  public static readonly SAVE_AND_SUBMIT_CLICKED: string = "saveAndSubmitClicked";
  public static readonly SUBMISSION_DECLINED: string = "submissionDeclined";
  public static readonly SUBMISSION_CANCELLED: string = "submissionCancelled";
}

