import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import { Directive, } from '@angular/core';

@Directive({
  selector: '[MailAddressValidator]', 
  providers: [
    { provide: NG_VALIDATORS, useExisting: MailAddressValidatorDirective, multi: true }
  ]
})

export class MailAddressValidatorDirective {
  private abstractControl: AbstractControl;

  validate(control: AbstractControl): ValidationErrors | null {
  this.abstractControl = control;

   if(!!!this.abstractControl) {
       return null; 
   }

   let mailAddressIndicator : string = control.get('mailingAddressYesRB')?.value;

   // Skip validation if user selects 'Yes' for mailing address same as business address
   if (!!!mailAddressIndicator) {
      return null;
   }

  let isValidAddress : boolean = 
    (
	  this.GetValue('MailingAddress') !== this.GetValue('BusinessAddress') ||
      this.GetValue('MaillingCity') !== this.GetValue('BusinessCity') ||
      this.GetValue('MaillingState') !== this.GetValue('BusinessState') ||
      this.GetValue('MaillingZip') !== this.GetValue('BusinessZip')
	);

    return isValidAddress ? null : { 'invalidMailingAddress': true, 'message': 'Mailing address cannot be the same as business address' };
}

  //Get value from a Abstract control (remove spaces and convert to lowercase)
  private GetValue(key: string): string {
    let value : string = this.abstractControl.get(key)?.value?.toLowerCase().replace(/\s/g, '') || '';
    return value;
  }
}