<!-- Inner Content Start-->
<div class="container ">
    <div class="row m-0">

        <form #f="ngForm" style="width:100%" (ngSubmit)="saveAndContinue(f, con, com)" *ngIf="policyHolderData?.DAODetailList" MailAddressValidator>
            <div class="main_content mt-2">
                <span class="text-lg-right float-lg-right">
                    <button type="button" class="btnCancelSub" name="btncancelmySubmissionName"
                        id="btncancelmySubmissionID" title="Cancel my Submission" (click)="cancelMySubmissionPopUp()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Cancel my Submission
                    </button>
                </span>
                <h3>Policy Holder</h3>
                <h5 class="mt-4 ml-lg-3  ">DOT</h5>
                <div class="clearfix"></div>
                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="wid_100">
                            <div class="wid_15 mt- ipad_28 text-right">
                                <label name="lbldotModal" id="lbldotModalID">
                                    DOT #: 
                                    <span style="color: red;">*</span>
                                </label>
                            </div>
                            <div class="wid_28 ipad_65">
                                <input class="form-control" disabled
                                    [(ngModel)]="dotNumber" type="text" name="txtdotModal"
                                    id="txtdotModalID" #dotModal="ngModel">
                            </div>
                            <div class="wid_15 ipad_28 text-right" *ngIf="!policyHolderData.DAODetailList.selectedFleetSizeModal.IsHidden">
                                <label style="margin: 0%;" name="lblFleetSize" id="lblFleetSizeID">
                                    Selected total number of power units #: 
                                    <span style="color: red;">{{policyHolderData.DAODetailList.selectedFleetSizeModal.IsRequired ? '*' : ''}}</span>
                                </label>
                            </div>
                            <div class="wid_31 ipad_65" *ngIf="!policyHolderData.DAODetailList.selectedFleetSizeModal.IsHidden">
                                <p-dropdown
                                    [class.validateDrodown]="((f.submitted || dao?.isSubmitForm) && ddFleetSize.invalid)"
                                    [options]="fleetSizeOptions"
                                    [(ngModel)]="policyHolderData.DAODetailList.selectedFleetSizeModal.modal" name="ddFleetSize"
                                    id="ddFleetSizeID" #ddFleetSize="ngModel" placeholder="Select" required
                                    [disabled]="isFleetSizeDisabled">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="additionalDotData?.IsAddDOT">
                    <div class="col-lg-12 mb-3" *ngFor="let DOT of additionalDotData.AddAdditionalDOTArray; let i= index;">
                        <div class="wid_100">
                            <div class="wid_15 ipad_28 text-right">
                                <label name="lblIsAddDOT_{{i}}" id="lblIsAddDOTID_{{i}}">DOT #: </label>
                            </div>
                            <div class="wid_28 ipad_65">
                                <input class="form-control"
                                    [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && additionalDOT.invalid"
                                    type="text" [(ngModel)]="DOT.answer" #additionalDOT="ngModel" required
                                    name="txtadditionalDOT_{{i}}" id="txtIsAddDOTID_{{i}}">
                            </div>
                            <div class="wid_20">
                                <a>
                                    <i class="fa fa-trash delete_icon" title="delete"
                                        (click)="removeAdditionalDOTPopup(i)" name="deleteDOT_{{i}}"
                                        id="deleteDOTID_{{i}}"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3" *ngIf="additionalDotData.AddAdditionalDOTArray?.length < 3">
                    <div class="col-lg-12">
                        <div class="wid_55">
                            <div class="wid_30 text-right">
                                <label></label>
                            </div>
                            <div class="wid_55 text-lg-right">
                                <a class="addDOT" role="button" title="Add Additional DOT" (click)="AddDOT()"
                                    name="AdditionalDOT" id="AdditionalDOTID">Add Additional DOT</a>
                            </div>
                            <div class="wid_20">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row mb-2">
                    <div class="col-lg-12 mt-3">
                        <div class="row">
                            <div class="ml-5 wid_16-4 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.policyEffectiveDateModal?.IsHidden">
                                <label name="lblPolicyEffectiveDate" id="lblPolicyEffectiveDateID">Policy Effective
                                    Date:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.policyEffectiveDateModal?.IsRequired
                                        ? '*' : ''}}</span></label>
                                <span class="business_owner_icon"><i class="fa fa-question-circle"
                                        pTooltip="The effective date must be within 05-60 calendar days from todays date."
                                        aria-hidden="true"></i></span>
                            </div>
                            <div 
                                class="wid_18 ipad_65"
                                [class.validate_error_cls_calendar]="((f.submitted || dao?.isSubmitForm) && EffectiveDate.invalid) || !isValidPolicyDate"
                                *ngIf="!policyHolderData.DAODetailList?.policyEffectiveDateModal?.IsHidden">
                                <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon1" id="dotID"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"
                                    [class.datepicker_override]="policyHolderData.DAODetailList?.policyExpirationDateModal?.IsOverride"
                                    placeholder="MM / DD / YYYY"
                                    [minDate]="policyHolderData.DAODetailList.policyEffectiveDateModal.minDate"
                                    [maxDate]="policyHolderData.DAODetailList.policyEffectiveDateModal.maxDate"
                                    [(ngModel)]="policyHolderData.DAODetailList.policyEffectiveDateModal.modal" name="EffectiveDate"
                                    #EffectiveDate="ngModel"
                                    (onSelect)="onPolicyEffectiveDateModalChange()"
                                    (onBlur)="onPolicyEffectiveDateModalChange()"
                                    [required]="policyHolderData.DAODetailList?.policyEffectiveDateModal?.IsRequired">
                                </p-calendar>
                            </div>

                            <div class="wid_15 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.policyExpirationDateModal?.IsHidden">
                                <label name="lblExpirationDate" id="lblExpirationDateID">Expiration Date:
                                    <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.policyExpirationDateModal?.IsRequired
                                        ? '*' : ''}}</span></label>
                            </div>
                            <div
                                class="wid_18 ipad_65"
                                [class.validate_error_cls_calendar]="((f.submitted || dao?.isSubmitForm) && ExpirationDate.invalid)"
                                *ngIf="!policyHolderData.DAODetailList?.policyExpirationDateModal?.IsHidden">
                                <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon2"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"
                                    placeholder="MM / DD / YYYY"
                                    [(ngModel)]="policyHolderData.DAODetailList.policyExpirationDateModal.modal"
                                    name="ExpirationDate" id="ExpirationDateID" #ExpirationDate="ngModel"
                                    [required]="policyHolderData.DAODetailList.policyExpirationDateModal.IsRequired" disabled>
                                </p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-9 ml-4">
                            <div class="wid_100">
                                <label class="error_msg" *ngIf="!isValidPolicyDate"
                                    name="lblerror3" id="lblerror3ID">
                                    The effective date does not meet our submission guidelines at this time. Please be
                                    sure to submit business with an effective date that is within 05-60 calendar days
                                    from the current date.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row m-0">
                    <h5 class="mt-4 pt-3 ml-lg-3  ">Insured</h5>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="wid_100">
                            <div class="wid_14 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.accountNameModal?.IsHidden">
                                <label name="lblName" id="lblNameID">Name:<span
                                        style="color: red;">{{policyHolderData.DAODetailList?.accountNameModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && (!Name?.valid || policyHolderData.DAODetailList.accountNameModal.modal == null) && policyHolderData.DAODetailList?.accountNameModal?.IsRequired ? 'wid_32 ipad_65 validate_error_cls' : 'wid_32 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.accountNameModal?.IsHidden">
                                <input
                                    class="{{!policyHolderData.DAODetailList?.accountNameModal?.IsOverride ? 'form-control' :  'form-control form-control_Overide' }}"
                                    [(ngModel)]="policyHolderData.DAODetailList.accountNameModal.modal" type="text" name="Name"
                                    id="txtNameID" #Name="ngModel"
                                    [required]="policyHolderData.DAODetailList?.accountNameModal?.IsRequired"
                                    (input)="validateOverride('accountNameModal')">
                            </div>
                            <div class="wid_5 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.accountName2Modal?.IsHidden">
                                <label name="lblExtendedName" id="lblExtendedNameID">DBA:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.accountName2Modal?.IsRequired ? '*'
                                        : ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && (!Name2?.valid || policyHolderData.DAODetailList.accountName2Modal.modal == null) && policyHolderData.DAODetailList?.accountName2Modal?.IsRequired ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.accountName2Modal?.IsHidden">
                                <input
                                    class="{{!policyHolderData.DAODetailList?.accountName2Modal?.IsOverride ? 'form-control' :  'form-control form-control_Overide' }}"
                                    [(ngModel)]="policyHolderData.DAODetailList.accountName2Modal.modal" type="text" name="Name2"
                                    id="txtExtendedNameID"
                                    [required]="policyHolderData.DAODetailList?.accountName2Modal?.IsRequired"
                                    (input)="validateOverride('accountName2Modal')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="!policyHolderData.DAODetailList?.accountStreetModal?.IsHidden">
                    <div class="col-lg-12">
                        <div class="wid_100">
                            <div class="wid_14 ipad_28 text-right">
                                <label name="lblBusinessAddress" id="lblBusinessAddressID">Business Address:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.accountStreetModal?.IsRequired ? '*'
                                        : ''}}</span></label>
                            </div>
                            <div
                                class="{{(f.submitted || dao?.isSubmitForm) && (!BusinessAddress?.valid || policyHolderData.DAODetailList.accountStreetModal.modal == null) ? 'wid_32 ipad_65 validate_error_cls' : 'wid_32 ipad_65'}}">
                                <input class="form-control"
                                    [class.form-control_Overide]="policyHolderData.DAODetailList?.accountStreetModal?.IsOverride"
                                    [(ngModel)]="policyHolderData.DAODetailList.accountStreetModal.modal" type="text"
                                    name="BusinessAddress" id="txtBusinessAddressID" #BusinessAddress="ngModel"
                                    [required]="policyHolderData.DAODetailList?.accountStreetModal?.IsRequired" [maxlength]="40"
                                    (input)="validateOverride('accountStreetModal')"
                                    (keypress)="ValidateAddress($event,policyHolderData.DAODetailList.accountStreetModal.modal,'BusinessAddress')">
                            </div>
                            <div *ngIf="f.submitted && BusinessAddress.errors?.maxlength">
                                <span class="validationred">
                                    Business Address cannot be greater than 40 characters
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="wid_100">
                            <div class="wid_14 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.accountCityModal?.IsHidden">
                                <label name="lblCity" id="lblCityID">City: <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.accountCityModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div *ngIf="!policyHolderData.DAODetailList?.accountCityModal?.IsHidden"
                                class="{{(f.submitted || dao?.isSubmitForm) && (!BusinessCity?.valid || policyHolderData.DAODetailList.accountCityModal.modal == null) ? 'wid_16 ipad_65 validate_error_cls' : 'wid_16 ipad_65'}}">
                                <input
                                    class="{{!policyHolderData.DAODetailList?.accountCityModal?.IsOverride ? 'form-control' :  'form-control form-control_Overide' }}"
                                    [(ngModel)]="policyHolderData.DAODetailList.accountCityModal.modal" type="text"
                                    name="BusinessCity" id="txtCityID" #BusinessCity="ngModel"
                                    [required]="policyHolderData.DAODetailList?.accountCityModal?.IsRequired"
                                    (input)="validateOverride('accountCityModal')">
                            </div>
                            <div class="wid_5 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.accountStateModal?.IsHidden">
                                <label name="lblState" id="lblStateID">State: <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.accountStateModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div *ngIf="!policyHolderData.DAODetailList?.accountStateModal?.IsHidden"
                                class="{{(f.submitted || dao?.isSubmitForm) && BusinessState?.invalid ? 'wid_8 ipad_65 validate_error_cls' : 'wid_8 ipad_65'}}">
                                <p-dropdown [options]="policyHolderData.DAODetailList?.accountStateModal?.dropdown"
                                    [(ngModel)]="policyHolderData.DAODetailList.accountStateModal.modal" name="BusinessState"
                                    id="ddStateID" #BusinessState="ngModel"
                                    placeholder="{{policyHolderData.DAODetailList?.accountStateModal?.placeHolder}}"
                                    [required]="policyHolderData.DAODetailList?.accountStateModal?.IsRequired"
                                    (onChange)="onStateChanged($event)"
                                    class="{{!policyHolderData.DAODetailList?.accountStateModal?.IsOverride ? '' :  'dropdown_overide' }}"
                                    [disabled]="dao.IsAccountStateFreezed">
                                </p-dropdown>
                            </div>
                            <div class="wid_5 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.accountPostalCodeModal?.IsHidden">
                                <label name="lblZip" id="lblZipID">Zip:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.accountPostalCodeModal?.IsRequired ?
                                        '*' : ''}}</span></label>
                            </div>
                            <div *ngIf="!policyHolderData.DAODetailList?.accountPostalCodeModal?.IsHidden"
                                class="{{(f.submitted || dao?.isSubmitForm) && !BusinessZip?.valid ? 'wid_8 ipad_65 validate_error_cls' : 'wid_8 ipad_65'}}">
                                <p-inputMask [(ngModel)]="policyHolderData.DAODetailList.accountPostalCodeModal.modal" mask="99999"
                                    name="BusinessZip" id="txtBusinessZipID" #BusinessZip="ngModel"
                                    [required]="policyHolderData.DAODetailList?.accountPostalCodeModal?.IsRequired"
                                    (onBlur)="validateOverride('accountPostalCodeModal')"
                                    class="{{!policyHolderData.DAODetailList?.accountPostalCodeModal?.IsOverride ? '' :  'inputmask_overide' }}">
                                </p-inputMask>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 ml-3">
                    <div class="col-lg-12">
                        <div *ngIf="!policyHolderData.DAODetailList?.mailAddressIndicatorModal?.IsHidden" class="wid_55">
                            <label class="float-left mr-3" name="lblmailAddress" id="lblmailAddressID"
                                [class.validationred]="f.submitted && (policyHolderData.DAODetailList.mailAddressIndicatorModal.modal === '' || policyHolderData.DAODetailList.mailAddressIndicatorModal.modal === undefined)">
                                {{policyHolderData.DAODetailList?.mailAddressIndicatorModal?.placeHolder}}
                                <span style="color: red;">{{policyHolderData.DAODetailList?.mailAddressIndicatorModal?.IsRequired ? '*' :
                                    ''}}</span>
                            </label>
                            <div class="d-flex">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="mailingAddressYesRB" id="mailingAddressYesRBID"
                                        (onClick)="bindSameAddress();"
                                        [(ngModel)]="policyHolderData.DAODetailList.mailAddressIndicatorModal.modal"
                                        value="true" class="radio_button" inputId="Yes"
                                        [required]="policyHolderData.DAODetailList?.mailAddressIndicatorModal?.IsRequired">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="mailingAddressYesLbl" id="mailingAddressYesLblID">Yes</label>
                                    <p-radioButton name="mailingAddressNoRB" id="mailingAddressNoRBID"
                                        (onClick)="bindSameAddress();"
                                        [(ngModel)]="policyHolderData.DAODetailList.mailAddressIndicatorModal.modal"
                                        class="radio_button" value="false" inputId="No"
                                        [required]="policyHolderData.DAODetailList?.mailAddressIndicatorModal?.IsRequired">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="mailingAddressNoLbl" id="mailingAddressNoLblID">No</label>
                                </div>
                            </div>
                        </div>
                        <div class="wid_44">
                            <label class="float-left mr-3" name="lblvehicleGarageStateIndicator"
                                id="lblvehicleGarageStateIndicatorID">
                                Are all vehicles garaged in {{policyHolderData.DAODetailList?.accountStateModal?.modal}}?
                            </label>
                            <div class="d-flex">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="Yes" id="radvehicleGarageStateIndicatorYesID"
                                        (onClick)="validateOverride('vehicleGarageStateIndicatorModal')"
                                        (onBlur)="validateOverride('vehicleGarageStateIndicatorModal')"
                                        [(ngModel)]="policyHolderData.DAODetailList.vehicleGarageStateIndicatorModal.modal"
                                        value="Yes" class="radio_button" inputId="Yes">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblYes" id="lblYesID">Yes</label>
                                    <p-radioButton name="NO" id="radvehicleGarageStateIndicatorNoID"
                                        (onClick)="validateOverride('vehicleGarageStateIndicatorModal')"
                                        (onBlur)="validateOverride('vehicleGarageStateIndicatorModal')"
                                        [(ngModel)]="policyHolderData.DAODetailList.vehicleGarageStateIndicatorModal.modal"
                                        class="radio_button" value="No" inputId="No"></p-radioButton>
                                    <label class="radio_button_lbl" name="lblNo" id="lblNoID">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3" *ngIf="policyHolderData.DAODetailList?.mailAddressIndicatorModal?.modal == 'false'
                        && !policyHolderData.DAODetailList?.mailStreetModal?.IsHidden">
                    <div class="col-lg-12">
                        <div class="wid_100">
                            <div class="wid_14 ipad_28 text-right">
                                <label name="lblMailingAddress" id="lblMailingAddressID">Mailing Address:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.mailStreetModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div
                                class="{{(f.submitted || dao?.isSubmitForm) && (!MailingAddress?.valid || policyHolderData.DAODetailList.mailStreetModal.modal == null) ? 'wid_32 ipad_65 validate_error_cls' : 'wid_32 ipad_65'}}">
                                <input class="form-control"
                                    [(ngModel)]="policyHolderData.DAODetailList.mailStreetModal.modal" type="text"
                                    name="MailingAddress" id="txtMailingAddressID" #MailingAddress="ngModel"
                                    [required]="policyHolderData.DAODetailList?.mailStreetModal?.IsRequired" [maxlength]="40"
                                    (input)="validateOverride('mailStreetModal')"
                                    (keypress)="ValidateAddress($event,policyHolderData.DAODetailList.mailStreetModal.modal,'MailingAddress')"
                                    [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && f.errors?.invalidMailingAddress">
                            </div>
                            <div *ngIf="f.submitted && MailingAddress.errors?.maxlength">
                                <span class="validationred">
                                    Mailing Address cannot be greater than 40 characters
                                </span>
                            </div>
                            <div *ngIf="(f.submitted || dao?.isSubmitForm) && f.errors?.invalidMailingAddress">
                                <span class="validationred">
                                    {{f.errors?.message}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="policyHolderData.DAODetailList?.mailAddressIndicatorModal?.modal == 'false'">
                    <div class="col-lg-12">
                        <div class="wid_100">
                            <div class="wid_14 ipad_28 text-right" *ngIf="!policyHolderData.DAODetailList?.mailCityModal?.IsHidden">
                                <label name="lblMailingCity" id="lblMailingCityID">City: <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.mailCityModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div *ngIf="!policyHolderData.DAODetailList?.mailCityModal?.IsHidden"
                                class="{{(f.submitted || dao?.isSubmitForm) && (!MaillingCity?.valid ||(policyHolderData.DAODetailList.mailCityModal.modal == null && policyHolderData.DAODetailList?.mailCityModal?.IsRequired)) ? 'wid_16 ipad_65 validate_error_cls' : 'wid_16 ipad_65'}}">
                                <input class="form-control" [(ngModel)]="policyHolderData.DAODetailList.mailCityModal.modal"
                                    type="text" name="MaillingCity" id="txtMailingCityID" #MaillingCity="ngModel"
                                    [required]="policyHolderData.DAODetailList?.mailCityModal?.IsRequired"
                                    (input)="validateOverride('mailCityModal')"
                                    [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && f.errors?.invalidMailingAddress">
                            </div>
                            <div class="wid_5 ipad_28 text-right" *ngIf="!policyHolderData.DAODetailList?.mailStateModal?.IsHidden">
                                <label name="lblMailingState" id="lblMailingStateID">State: <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.mailStateModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div *ngIf="policyHolderData.DAODetailList?.mailAddressIndicatorModal?.modal == 'false' && !policyHolderData.DAODetailList?.mailStateModal?.IsHidden"
                                class="{{(f.submitted || dao?.isSubmitForm) && (MaillingState?.invalid  ||(policyHolderData.DAODetailList.mailStateModal.modal == null && policyHolderData.DAODetailList?.mailStateModal?.IsRequired))? 'wid_8 ipad_65 validate_error_cls' : 'wid_8 ipad_65'}}">
                                <p-dropdown [options]="stateSettings.StateQuestionOptions"
                                    [(ngModel)]="policyHolderData.DAODetailList.mailStateModal.modal" name="MaillingState"
                                    id="MaillingState" #MaillingState="ngModel"
                                    placeholder="{{policyHolderData.DAODetailList?.mailStateModal?.placeHolder}}"
                                    [required]="policyHolderData.DAODetailList?.mailStateModal?.IsRequired"
                                    (onChange)="validateOverride('mailStateModal')"
                                    class="{{!policyHolderData.DAODetailList?.mailStateModal?.IsOverride ? '' :  'dropdown_overide' }}"
                                    [class.validateDrodown]="(f.submitted || dao?.isSubmitForm) && f.errors?.invalidMailingAddress">
                                </p-dropdown>
                            </div>
                            <div class="wid_5 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.mailPostalCodeModal?.IsHidden">
                                <label name="lblMailingZip" id="lblMailingZipID">Zip: <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.mailPostalCodeModal?.IsRequired ? '*'
                                        : ''}}</span></label>
                            </div>
                            <div *ngIf="policyHolderData.DAODetailList?.mailAddressIndicatorModal?.modal == 'false' && !policyHolderData.DAODetailList?.mailPostalCodeModal?.IsHidden"
                                class="wid_8 ipad_65"
                                 [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && (f.errors?.invalidMailingAddress || !MaillingZip?.valid)">
                                <p-inputMask [(ngModel)]="policyHolderData.DAODetailList.mailPostalCodeModal.modal" mask="99999"
                                    name="MaillingZip" #MaillingZip="ngModel"
                                    [required]="policyHolderData.DAODetailList?.mailPostalCodeModal?.IsRequired"
                                    (onBlur)="validateOverride('mailPostalCodeModal')" id="txtMailingZipID">
                                </p-inputMask>
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="wid_80">
                            <div class="wid_18 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.legalEntityModal?.IsHidden">
                                <label name="lblLegalEntity" id="lblLegalEntityID">Legal Entity:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.legalEntityModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div class=" {{(f.submitted || dao?.isSubmitForm) && !legal?.valid ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.legalEntityModal?.IsHidden">
                                <p-dropdown placeholder={{policyHolderData.DAODetailList?.legalEntityModal?.placeHolder}}
                                    [options]="policyHolderData.DAODetailList?.legalEntityModal?.dropdown"
                                    [(ngModel)]="policyHolderData.DAODetailList.legalEntityModal.modal" name="legal"
                                    id="ddLegalEntityID" #legal="ngModel"
                                    [required]="policyHolderData.DAODetailList?.legalEntityModal?.IsRequired"
                                    (onChange)="validateOverride('legalEntityModal')"
                                    class="{{!policyHolderData.DAODetailList?.legalEntityModal?.IsOverride ? '' :  'dropdown_overide' }}">
                                </p-dropdown>
                            </div>

                            <div class="wid_15 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.businessOwnerFeinModal?.IsHidden && policyHolderData.DAODetailList.legalEntityModal.modal != 'INDIVIDUAL'">
                                <label name="lblFEIN" id="lblFEINID">FEIN #:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.businessOwnerFeinModal?.IsRequired ?
                                        '*' : ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && !txtFein?.valid ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.businessOwnerFeinModal?.IsHidden && policyHolderData.DAODetailList.legalEntityModal.modal != 'INDIVIDUAL'">
                                <p-inputMask [(ngModel)]="policyHolderData.DAODetailList.businessOwnerFeinModal.modal"
                                    mask="99-9999999" name="txtFein" #txtFein="ngModel"
                                    [required]="policyHolderData.DAODetailList?.businessOwnerFeinModal?.IsRequired"
                                    (onBlur)="validateOverride('businessOwnerFeinModal')" id="txtFEINID"
                                    class="{{!policyHolderData.DAODetailList?.businessOwnerFeinModal?.IsOverride ? '' :  'inputmask_overide' }}">
                                </p-inputMask>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="wid_80 ">
                            <div class="wid_18 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.naicsCodeModal?.IsHidden">
                                <label name="lblNAICS" id="lblNAICSID">NAICS: <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.naicsCodeModal?.IsRequired ? '*' :
                                        ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && !naics?.valid ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.naicsCodeModal?.IsHidden">
                                <p-dropdown placeholder={{policyHolderData.DAODetailList?.naicsCodeModal?.placeHolder}}
                                    [options]="policyHolderData.DAODetailList?.naicsCodeModal?.dropdown"
                                    [(ngModel)]="policyHolderData.DAODetailList.naicsCodeModal.modal" name="naics" id="ddNAICSID"
                                    #naics="ngModel" [required]="policyHolderData.DAODetailList?.naicsCodeModal?.IsRequired"
                                    (onChange)="validateOverride('naicsCodeModal')"
                                    class="{{!policyHolderData.DAODetailList?.naicsCodeModal?.IsOverride ? '' :  'dropdown_overide' }}">
                                </p-dropdown>
                            </div>
                            <div class="wid_15 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.businessClassModal?.IsHidden">
                                <label name="lblBusinessClass" id="lblBusinessClassID">Business Class:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.businessClassModal?.IsRequired ? '*'
                                        : ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && !BusinessClass?.valid ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.businessClassModal?.IsHidden">
                                <p-dropdown [options]="policyHolderData.DAODetailList?.businessClassModal?.dropdown"
                                    [(ngModel)]="policyHolderData.DAODetailList.businessClassModal.modal"
                                    placeholder={{policyHolderData.DAODetailList?.businessClassModal?.placeHolder}}
                                    name="BusinessClass" #BusinessClass="ngModel" id="ddBusinessClassID"
                                    [required]="policyHolderData.DAODetailList?.businessClassModal?.IsRequired"
                                    (onChange)="validateOverride('businessClassModal')"
                                    class="{{!policyHolderData.DAODetailList?.businessClassModal?.IsOverride ? '' :  'dropdown_overide' }}">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isPolicyEffectiveJuly">
                    <div class="row m-0">
                        <h5 class="mt-4 pt-3 ml-lg-3 ">Vehicle Travel Information</h5>
                    </div>
                </div>
                <div *ngIf="!!dao.DOTList.accountInfoQsnr.findQuestion('iftaVehiclesMileage')">
                    <div *ngIf="isPolicyEffectiveJuly">
                        <div class="col-lg-12" style="margin-left: 52px;" *ngIf="isPolicyEffectiveJuly">
                            <div class="row mt-3">
                                <div>
                                    <label class="float-left mr-3" name="vehicleTravel" id="vehicleTravel"
                                           [class.validationred]="f.submitted && (policyHolderData.DAODetailList.vehicleTravelModal.modal == '' || policyHolderData.DAODetailList.vehicleTravelModal.modal == undefined)">
                                            Will any of the vehicles on this account travel internationally during the
                                            requested
                                            policy term?
                                            <span style="color: red;">{{policyHolderData.DAODetailList.vehicleTravelModal?.IsRequired ? '*' : ''}}</span>
                                    </label>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p-radioButton name="vehicleTravelYes" id="vehicleTravelYes" label="Yes"
                                            value="Yes" class="radio_button" required
                                            [(ngModel)]="policyHolderData.DAODetailList.vehicleTravelModal.modal"
                                            (onClick)="validateOverride('vehicleTravelModal')">
                                        </p-radioButton>
                                        <p-radioButton name="vehicleTravelNo" id="vehicleTravelNo" label="No" value="No"
                                            class="radio_button" required
                                            [(ngModel)]="policyHolderData.DAODetailList.vehicleTravelModal.modal">
                                        </p-radioButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="margin-left: 52px;" *ngIf="isNewTrucking">
                        <div class="row mt-3">
                            <div>
                                <label class="float-left mr-3" name="lblFileIfta" id="lblFileIfta"
                                    [class.validationred]="f.submitted && (policyHolderData.DAODetailList.doesFileIftaModal.modal === '' || policyHolderData.DAODetailList.doesFileIftaModal.modal === undefined)">
                                    {{ policyHolderData.DAODetailList?.doesFileIftaModal.placeHolder }}
                                    <span style="color: red;">{{ policyHolderData.DAODetailList.doesFileIftaModal?.IsRequired ? '*' : ''}}</span>
                                </label>
                            </div>
                            <div class="d-flex">
                                <div>
                                    <p-radioButton name="doesFileIfta" id="doesFileIftaYes" label="Yes"
                                        value="Yes" class="radio_button" required
                                        [(ngModel)]="policyHolderData.DAODetailList.doesFileIftaModal.modal"
                                        (onClick)="updateIftaValues()">
                                    </p-radioButton>
                                    <p-radioButton name="doesFileIfta" id="doesFileIftaNo" label="No"
                                        value="No" class="radio_button" required
                                        [(ngModel)]="policyHolderData.DAODetailList.doesFileIftaModal.modal"
                                        (onClick)="updateIftaValues()">
                                    </p-radioButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="displayIftaQuestions">
                        <div class="col-lg-12">
                            <div [ngClass]="isNewTrucking ? 'sub-ifta-questions' : 'wid_55'"
                                [class.text-right]="!isPolicyEffectiveJuly" 
                                [style.margin-left.px]="isPolicyEffectiveJuly && !isNewTrucking ? 52 : 0">
                                <label name="lblMilesForVehicles" id="lblMilesForVehicles">How many total miles for all
                                    vehicles on applicant's last 12 months IFTA filing?:
                                    <span
                                        style="color: red;">{{policyHolderData.DAODetailList.iftaVehiclesMileageModal?.IsRequired
                                        ? '*' : ''}}</span>
                                </label>
                            </div>
                            <div  class="{{isPolicyEffectiveJuly ? 'ml-5' : 'ml-3'}}" class="wid_24">
                                <p-inputNumber (ngModelChange)="IftaFleetMilesChanged($event)"
                                    [(ngModel)]="policyHolderData.DAODetailList.iftaVehiclesMileageModal.modal"
                                    [required]="policyHolderData.DAODetailList.iftaVehiclesMileageModal.IsRequired"
                                    inputId="integeronly" [min]="0" positiveIntegersOnly
                                    [class.invalidInputNumber]="(iftaVehiclesMileage.dirty || f.submitted || dao?.isSubmitForm) && iftaVehiclesMileage.invalid"
                                    name="iftaVehiclesMileage" id="iftaVehiclesMileage" #iftaVehiclesMileage="ngModel">
                                </p-inputNumber>
                                <span
                                    *ngIf="(iftaVehiclesMileage.dirty || f.submitted || dao?.isSubmitForm) && iftaVehiclesMileage.invalid"
                                    style="color: red;">Please enter whole numbers only</span>
                            </div>
                        </div>
                        <div class="col-lg-12 wid_80 text-center" *ngIf="averageMilageWarning">
                            <span style="color: red;">Your submission will be declined due to high average miles per power unit, please validate.</span>
                        </div>
                    </div>          
                    <div  class="row mt-3" *ngIf="displayIftaQuestions">
                        <div class="col-lg-12">
                            <div [ngClass]="isNewTrucking ? 'sub-ifta-questions' : 'wid_55'"
                                [class.text-right]="!isPolicyEffectiveJuly" 
                                [style.margin-left.px]="isPolicyEffectiveJuly && !isNewTrucking ? 52 : 0">
                                <label name="PowerUnits" id="PowerUnitsID">Total Number of power units corresponding to the last 12 months IFTA filing?
                                <span style="color: red;">{{policyHolderData.DAODetailList.iftaFleetSizeModal?.IsRequired ? '*'  : ''}}</span>
                                </label>
                            </div> 
                            <div class="{{isPolicyEffectiveJuly ? 'ml-5' : 'ml-3'}}" class="wid_24 ipad_24" [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && iftaFleetSize.invalid" >
                                <p-inputNumber (ngModelChange)="IftaFleetMilesChanged($event)"
                                    [(ngModel)]="policyHolderData.DAODetailList.iftaFleetSizeModal.modal"
                                    [class.invalidInputNumber]="(iftaFleetSize.dirty || f.submitted || dao?.isSubmitForm) && iftaFleetSize.invalid"
                                    name="iftaFleetSize" id="iftaFleetSize" #iftaFleetSize="ngModel"
                                    [required]="policyHolderData.DAODetailList.iftaFleetSizeModal?.IsRequired" 
                                    inputId="integeronly" [minAmt]="1" [maxAmt]="999" positiveIntegersOnly
                                    >
                                </p-inputNumber>
                            </div>                 
                        </div>
                    </div>
                    <div *ngIf="isPolicyEffectiveJuly">
                        <div class="row mt-3" *ngIf="isPolicyEffectiveJuly">
                            <div class="col-lg-12">
                                <div class="wid_55 ipad_55" style="margin-left: 52px;">
                                    <label name="vehicleMile" id="vehicleMile"> What are the total miles
                                        traveled in the garaging state during last 12 months?
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList.vehicleMileModal?.IsRequired
                                            ? '*' : ''}}</span>
                                    </label>
                                </div>
                                <div class="wid_24 ipad_24 ml-5">
                                    <p-inputNumber name="vehicleMile" id="vehicleMile" inputId="integeronly"
                                        [(ngModel)]="policyHolderData.DAODetailList.vehicleMileModal.modal"
                                        [required]="policyHolderData.DAODetailList.vehicleMileModal?.IsRequired"
                                        [min]="0" positiveIntegersOnly
                                        [class.invalidInputNumber]="(vehicleMile.dirty || f.submitted || dao?.isSubmitForm) && vehicleMile.invalid"
                                        #vehicleMile="ngModel">
                                    </p-inputNumber>
                                    <span
                                        *ngIf="(vehicleMile.dirty || f.submitted || dao?.isSubmitForm) && vehicleMile.invalid"
                                        style="color: red;">Please enter whole numbers only</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <h5 class="mt-4 pt-3 ml-lg-3  ">{{ isPolicyEffectiveJuly ? 'Business Owner Contact Information' : 'Business Owner' }}<span
                            class="business_owner_icon"><i class="fa fa-question-circle"
                                pTooltip="Who is the principal stockholder of the corp, partner in partnership or owner in sole proprietor. "
                                aria-hidden="true"></i></span></h5>
                </div>

                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="wid_80">
                            <div class="wid_18 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.businessOwnerNameModal?.IsHidden">
                                <label name="lblBusinessOwnerName" id="lblBusinessOwnerNameID">Name:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.businessOwnerNameModal?.IsRequired ?
                                        '*' : ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && (!Businessname?.valid || policyHolderData.DAODetailList.businessOwnerNameModal.modal == null) ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.businessOwnerNameModal?.IsHidden">
                                <input class="form-control" type="text"
                                    [(ngModel)]="policyHolderData.DAODetailList.businessOwnerNameModal.modal" name="Businessname"
                                    #Businessname="ngModel" id="txtBusinessOwnerID"
                                    [required]="policyHolderData.DAODetailList?.businessOwnerNameModal?.IsRequired"
                                    (input)="validateOverride('businessOwnerNameModal')"
                                    [class.form-control_Overide]="policyHolderData.DAODetailList.businessOwnerNameModal?.IsOverride">
                            </div>
                            <div class="wid_15 ipad_28 text-right"
                                *ngIf="!policyHolderData.DAODetailList?.businessOwnerBirthDateModal?.IsHidden">
                                <label name="lblBusinessOwnerdob" id="lblBusinessOwnerdobID">Date of Birth:
                                    <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.businessOwnerBirthDateModal?.IsRequired
                                        ? '*' : ''}}</span></label>
                            </div>
                            <div class="{{(f.submitted || dao?.isSubmitForm) && !BusinessDOB?.valid ? 'wid_20 ipad_65 validate_error_cls_calendar' : 'wid_20 ipad_65'}}"
                                *ngIf="!policyHolderData.DAODetailList?.businessOwnerBirthDateModal?.IsHidden">
                                <p-calendar [showIcon]="true" inputId="calendarIcon3" placeholder="MM / DD / YYYY"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"
                                    [(ngModel)]="policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal"
                                    name="cldBusinessDOB" #BusinessDOB="ngModel" id="cldBusinessOwnerdobID"
                                    [required]="policyHolderData.DAODetailList?.businessOwnerBirthDateModal?.IsRequired"
                                    (onSelect)="selectDateofBirth()"
                                    (onBlur)="selectDateofBirth()"
                                    class="{{!policyHolderData.DAODetailList?.businessOwnerBirthDateModal?.IsOverride ? '' :  'datepicker_override' }}"
                                    [maxDate]="yesterday">
                                </p-calendar>

                            </div>
                        </div>
                    </div>
                </div>

                    <div class="row m-0" *ngIf="!isPolicyEffectiveJuly">
                        <h5 class="mt-4 pt-3 ml-lg-3  ">Contact Information</h5>
                    </div>

                <form #con="ngForm" style="width:100%" (ngSubmit)="con.valid && AddContact()">
                    <div>
                        <div class="row ">
                            <div class="col-lg-12 mt-3" *ngFor="let item of contactData.AddContactInformation; let i= index;">
                                <div class="wid_80">
                                    <div class="wid_18 ipad_28 text-right">
                                        <label name="lblContactType_{{i}}" id="lblContactTypeID_{{i}}">Contact Type:
                                            <span style="color: red;">{{policyHolderData.DAODetailList?.contactTypeModal?.IsRequired
                                                ? '*' : ''}}</span></label>
                                    </div>
                                    <div *ngIf="!policyHolderData.DAODetailList?.contactTypeModal?.IsHidden"
                                        class="{{((contactData.IsValidContactInfo == dao.businessAddressValidation && dao.businessAddressValidation != '' && item.type=='EMAIL_ADDR') || ((con.submitted || dao?.isSubmitForm) || (f.submitted || dao?.isSubmitForm)) && policyHolderData.DAODetailList?.contactTypeModal?.IsRequired && contactType?.invalid) ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}">
                                        <p-dropdown placeholder="{{policyHolderData.DAODetailList?.contactTypeModal?.placeHolder}}"
                                            [options]="policyHolderData.DAODetailList?.contactTypeModal?.dropdown"
                                            name="ddCtype_{{i}}" id="ddContactType_{{i}}" [(ngModel)]="item.type"
                                            (onChange)="contactChange(item, i)"
                                            class="{{!item?.IsOverridecontactTypeModal || contactData.IsValidContactInfo == dao.businessAddressValidation ? '' :  'dropdown_overide' }}"
                                            #contactType="ngModel"
                                            [required]="policyHolderData.DAODetailList?.contactTypeModal?.IsRequired"
                                            [disabled]="shallBeReadOnly(con, item, i)" ngModel>
                                        </p-dropdown>
                                    </div>
                                    <div class="wid_15 ipad_28 text-right">
                                        <label name="lblContactDetail_{{i}}" id="lblContactDetailID_{{i}}">Contact
                                            Detail:
                                            <span
                                                style="color: red;">{{policyHolderData.DAODetailList?.contactDetailModal?.IsRequired
                                                ? '*' : ''}}</span></label>
                                    </div>
                                    <div *ngIf="!policyHolderData.DAODetailList.contactDetailModal.IsHidden"
                                        class="{{((con.submitted || dao?.isSubmitForm) || (f.submitted || dao?.isSubmitForm)) && ((!validateEmails(item.details) && item.type == 'EMAIL_ADDR') || ((item.details == '' || item.details == undefined) && item.type != 'EMAIL_ADDR')) && policyHolderData.DAODetailList?.contactDetailModal?.IsRequired ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}">
                                        <input class="form-control" type="text" name="Cdetail_inp{{i}}"
                                            id="CdetailID{{i}}" [(ngModel)]="item.details" #contactDetail="ngModel"
                                            *ngIf="item.type == 'EMAIL_ADDR'"
                                            [required]="policyHolderData.DAODetailList?.contactDetailModal?.IsRequired"
                                            (input)="validateOverride('contactDetailModal',i)"
                                            [class.form-control_Overide]="item?.IsOverridecontactDetailModal" ngModel>
                                        <p-inputMask *ngIf="item.type != 'EMAIL_ADDR'" [(ngModel)]="item.details"
                                            mask="{{(item.type == 'CELL_NUM' || item.type == 'CTWORKPHN' || item.type == 'PHONE_NUM') ? '(999) 999-9999' : item.type == 'FAX' ? '(999) 999.9999' : ''}}"
                                            name="Cdetail_{{i}}" id="txtCdetail_{{i}}" #contactDetail="ngModel"
                                            [required]="policyHolderData.DAODetailList?.contactDetailModal?.IsRequired"
                                            (onBlur)="validateOverride('contactDetailModal',i)"
                                            [class.inputmask_overide]="item?.IsOverridecontactDetailModal">
                                        </p-inputMask>
                                    </div>
                                </div>
                                <div class="wid_18" *ngIf="i != 0">
                                    <a>
                                        <i class="fa fa-trash delete_icon" title="delete" name="dltContactDetail_{{i}}"
                                            id="dltContactDetailID_{{i}}" (click)="removeContactPopUp(i);"></i>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="contactData.IsValidContactInfo != ''" class="ml-5 col-lg-12 wid_15 ipad_28">
                                <label>
                                    <span style="color: red;">{{contactData.IsValidContactInfo}}</span>
                                </label>
                            </div>
                        </div>

                        <div class="row mb-3 mt-2">
                            <div class="col-lg-12">
                                <div class="wid_80">
                                    <div class="wid_18 ipad_28 text-right">
                                        <label></label>
                                    </div>
                                    <div class="wid_31 ipad_65">
                                        <label></label>
                                    </div>
                                    <div class="wid_15 ipad_28">
                                        <label></label>
                                    </div>
                                    <div class="wid_31 ipad_65 text-right">
                                        <button class="addcomm" name="AddContactInformation"
                                            id="AddContactInformationID" pButton type="submit"
                                            title="Add Contact Information" label="Add Contact Information"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row m-0" [class.mt-n5]="isPolicyEffectiveJuly">
                    <h5 class="mt-4 pt-3 ml-lg-3  ">Current Carrier</h5>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12" *ngIf="!policyHolderData.DAODetailList?.currentCarrierNameModal?.IsHidden">
                        <div class="wid_100 ml-2">
                            
                            <div class="wid_8 ipad_28 text-right">
                                <label name="lblCurrentCarrierName" id="lblCurrentCarrierNameID">Name:
                                    <span style="color: red;">{{policyHolderData.DAODetailList?.currentCarrierNameModal?.IsRequired
                                        ? '*' : ''}}</span></label>
                            </div>
                            <div
                                [ngClass]="'wid_30 ipad_65' + ((f.submitted || dao?.isSubmitForm) && policyHolderData.DAODetailList?.currentCarrierNameModal?.IsRequired && !policyHolderData.DAODetailList?.currentCarrierNameModal?.IsExistsData && !CarrierName?.valid ? ' validate_error_cls' : '')">
                                <input class="form-control" type="text"
                                    *ngIf="!policyHolderData.DAODetailList.currentCarrierNameModal.IsExistsData"
                                    [(ngModel)]="policyHolderData.DAODetailList.currentCarrierNameModal.modal" name="txtCarrierName"
                                    #CarrierName="ngModel" id="txtCurrentCarrierNameID"
                                    [required]="policyHolderData.DAODetailList?.currentCarrierNameModal?.IsRequired"
                                    (input)="validateOverride('currentCarrierNameModal')"
                                    [class.form-control_Overide]="policyHolderData.DAODetailList.currentCarrierNameModal?.IsOverride">
                                <label
                                    *ngIf="policyHolderData.DAODetailList?.currentCarrierNameModal?.IsExistsData">{{policyHolderData.DAODetailList?.currentCarrierNameModal?.modal}}</label>
                            </div>
                            <div class="wid_8 ipad_28 text-right" *ngIf="!policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal?.IsHidden && !!policyHolderData.DAODetailList?.currentCarrierMCNumberModal.modal">
                                <label name="lblPolicy" id="lblPolicyID">Policy #:
                                    <span
                                        style="color: red;">{{policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal?.IsRequired
                                        ? '*' : ''}}</span></label>
                            </div>
                            <div
                                class="{{(f.submitted || dao?.isSubmitForm) && policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal?.IsRequired && !policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal?.IsExistsData && !CarrierPolicy?.valid ? 'wid_10 ipad_65 validate_error_cls' : 'wid_10 ipad_65'}}">
                                <input class="form-control" type="text"
                                    *ngIf="!policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal.IsExistsData && !!policyHolderData.DAODetailList?.currentCarrierMCNumberModal.modal"
                                    [(ngModel)]="policyHolderData.DAODetailList.currentCarrierPolicyNumberModal.modal"
                                    name="CarrierPolicy" #CarrierPolicy="ngModel"
                                    [required]="policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal?.IsRequired"
                                    (input)="validateOverride('currentCarrierPolicyNumberModal')"
                                    [class.form-control_Overide]="policyHolderData.DAODetailList.currentCarrierPolicyNumberModal?.IsOverride"
                                    id="txtPolicyID">
                                <label
                                    *ngIf="policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal.IsExistsData">{{policyHolderData.DAODetailList?.currentCarrierPolicyNumberModal?.modal}}</label>
                            </div>
                           
                                <div class="wid_10 ipad_28 text-right" *ngIf="!policyHolderData?.DAODetailList?.currentCarrierMCNumberModal?.IsHidden && !(!policyHolderData?.DAODetailList?.currentCarrierMCNumberModal?.IsExistsData && isNewTrucking)">
                                    <label name="mCNumber" id="mCNumber">MC #:
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList?.currentCarrierMCNumberModal?.IsRequired
                                            ? '*' : ''}}</span>
                                    </label>
                                </div>
                                <div
                                    class="{{(f.submitted || dao?.isSubmitForm) && policyHolderData.DAODetailList?.currentCarrierMCNumberModal?.IsRequired && !policyHolderData.DAODetailList?.currentCarrierMCNumberModal?.IsExistsData && !mCNumber?.valid ? 'wid_10 ipad_65 validate_error_cls' : 'wid_10 ipad_65'}}">
                                    <input
                                        *ngIf="!policyHolderData.DAODetailList?.currentCarrierMCNumberModal.IsExistsData && !isNewTrucking"
                                        class="form-control" type="text" name="CarrierMC" id="CarrierMC"
                                        [(ngModel)]="policyHolderData.DAODetailList.currentCarrierMCNumberModal.modal"
                                        #mCNumber="ngModel"
                                        [required]="policyHolderData.DAODetailList?.currentCarrierMCNumberModal?.IsRequired"
                                        [class.form-control_Overide]="policyHolderData.DAODetailList.currentCarrierMCNumberModal?.IsOverride"
                                        (input)="validateOverride('currentCarrierMCNumberModal')">
                                    <label
                                        *ngIf="policyHolderData.DAODetailList?.currentCarrierMCNumberModal.IsExistsData">{{policyHolderData.DAODetailList?.currentCarrierMCNumberModal?.modal}}</label>
                                </div>
                                <div class="wid_100 carrier-detail-container" *ngIf="isNewTrucking && !!!policyHolderData.DAODetailList?.currentCarrierMCNumberModal.modal">
                                    <div class="policy-label-container">
                                        <label class="ml-auto">
                                            {{ policyHolderData.DAODetailList?.currentCarrierInsuredInYearsModal?.placeHolder }}?
                                            <span style="color: red;">
                                                {{ policyHolderData.DAODetailList?.currentCarrierInsuredInYearsModal?.IsRequired ? '*' : '' }}
                                            </span>
                                        </label>
                                        <span class="business_owner_icon">
                                            <i class="fa fa-question-circle"
                                                pTooltip="{{ policyHolderData.DAODetailList?.currentCarrierInsuredInMonthsModal?.placeHolder }}"
                                                aria-hidden="true">
                                            </i>
                                        </span>
                                    </div>
                                    <div class="wid_8 ipad_65" [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && (policyHolderData.DAODetailList?.currentCarrierInsuredInYearsModal?.IsRequired && policyHolderData.DAODetailList?.currentCarrierInsuredInYearsModal?.modal === '')">
                                        <input class="form-control"
                                            name="currentCarrierInsuredInYears"
                                            [(ngModel)]="policyHolderData.DAODetailList.currentCarrierInsuredInYearsModal.modal"
                                            [class.validate_error_cls]="(currentCarrierInsuredInYears.dirty || f.submitted || dao?.isSubmitForm) && currentCarrierInsuredInYears.invalid"
                                            [required]="policyHolderData.DAODetailList?.currentCarrierInsuredInYearsModal?.IsRequired"
                                            placeholder="Years" type="text"
                                            id="currentCarrierInsuredInYears"
                                            #currentCarrierInsuredInYears="ngModel"
                                            (paste)="(false)" (keypress)="numbersOnly($event)">
                                    </div>
                                    <div class="wid_8 ipad_65" [class.validate_error_cls]="(f.submitted || dao?.isSubmitForm) && (policyHolderData.DAODetailList?.currentCarrierInsuredInMonthsModal?.IsRequired && policyHolderData.DAODetailList?.currentCarrierInsuredInMonthsModal?.modal === '')">
                                        <input class="form-control"
                                            name="currentCarrierInsuredInMonths"
                                            [(ngModel)]="policyHolderData.DAODetailList.currentCarrierInsuredInMonthsModal.modal"
                                            [class.validate_error_cls]="(currentCarrierInsuredInMonths.dirty || f.submitted || dao?.isSubmitForm) && currentCarrierInsuredInMonths.invalid"
                                            [required]="policyHolderData.DAODetailList?.currentCarrierInsuredInMonthsModal?.IsRequired"
                                            placeholder="Months" type="text"
                                            id="currentCarrierInsuredInMonths"
                                            #currentCarrierInsuredInMonths="ngModel"
                                            (paste)="(false)" (keypress)="numbersOnly($event)">
                                    </div>
                                </div>
                                <div class="wid_100 d-flex align-items-center carrier-detail-container" *ngIf="isNewTrucking && !!!policyHolderData.DAODetailList?.currentCarrierMCNumberModal.modal">
                                    <div class="policy-label-container">
                                        <label class="ml-auto" label="lblHasContinuousAutoLiability"
                                            [class.validationred]="f.submitted && (policyHolderData.DAODetailList.hasContinuousAutoLiabilityModal.modal === '' || policyHolderData.DAODetailList.hasContinuousAutoLiabilityModal.modal === undefined)">
                                            {{ hasContinuousAutoLiabilityLabel }}
                                            <span style="color: red;">
                                                {{ policyHolderData.DAODetailList?.hasContinuousAutoLiabilityModal?.IsRequired ? '*' : '' }}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="p-field-radiobutton">
                                        <p-radioButton name="hasContinuousAutoLiability" id="hasContinuousAutoLiabilityYesID" 
                                            value="Yes" class="radio_button"
                                            [(ngModel)]="policyHolderData.DAODetailList.hasContinuousAutoLiabilityModal.modal"
                                            required="{{ policyHolderData.DAODetailList?.hasContinuousAutoLiabilityModal?.IsRequired }}">
                                        </p-radioButton>
                                        <label class="radio_button_lbl" for="hasContinuousAutoLiabilityYesID" id="lblhasContinuousAutoLiabilityYesID">Yes</label>
                                        <p-radioButton name="hasContinuousAutoLiability" id="hasContinuousAutoLiabilityNoID"
                                            value="No" class="radio_button"
                                            [(ngModel)]="policyHolderData.DAODetailList.hasContinuousAutoLiabilityModal.modal"
                                            required="{{ policyHolderData.DAODetailList?.hasContinuousAutoLiabilityModal?.IsRequired }}">
                                        </p-radioButton>
                                        <label class="radio_button_lbl" for="hasContinuousAutoLiabilityNoID" id="lblhasContinuousAutoLiabilityNoID">No</label>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="policyHolderData.DAODetailList?.cargoIndicatorModal?.IsRequired">
                    <div class="row m-0">
                        <h5 class="mt-4 pt-3 ml-lg-3  ">Optional Coverage
                        </h5>
                    </div>
                    <div class="col-lg-12" style="margin-left: 52px;">
                        <div class="row mt-3">
                            <label class="float-left mr-3" name="lblCargoIndicator" id="lblCargoIndicatorID"
                                [ngClass]="(f.submitted && (policyHolderData.DAODetailList.cargoIndicatorModal.modal == '' || policyHolderData.DAODetailList.cargoIndicatorModal.modal == undefined)) ? 'validationred' : ''">
                                {{policyHolderData.DAODetailList?.cargoIndicatorModal?.placeHolder}}
                                <span style="color: red;">{{policyHolderData.DAODetailList?.cargoIndicatorModal?.IsRequired
                                    ? '*' : ''}}</span>
                            </label>
                            <div class="d-flex">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="radCargoIndicatorYes" id="radCargoIndicatorYesID"
                                        [(ngModel)]="policyHolderData.DAODetailList.cargoIndicatorModal.modal" value="Yes"
                                        class="radio_button" inputId="Yes" required>
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblYes" id="lblYesID">Yes</label>
                                    <p-radioButton name="radCargoIndicatorNo" id="radCargoIndicatorNoID"
                                        [(ngModel)]="policyHolderData.DAODetailList.cargoIndicatorModal.modal" value="No"
                                        class="radio_button" inputId="No" required>
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblNo" id="lblNoID">No</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="isNewTrucking">
                            <label class="float-left mr-3" name="lblTrailerInterchangeIndicator" id="lblTrailerInterchangeIndicatorID"
                                [ngClass]="(f.submitted && (policyHolderData.DAODetailList.trailerInterchangeIndicatorModal.modal == '' || policyHolderData.DAODetailList.trailerInterchangeIndicatorModal.modal == undefined)) ? 'validationred' : ''">
                                {{policyHolderData.DAODetailList?.trailerInterchangeIndicatorModal?.placeHolder}}
                                <span style="color: red;">{{policyHolderData.DAODetailList?.trailerInterchangeIndicatorModal?.IsRequired
                                    ? '*' : ''}}</span>
                            </label>
                            <div class="d-flex">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="radTrailerInterchangeIndicator" id="radTrailerInterchangeIndicatorYesID"
                                        [(ngModel)]="policyHolderData.DAODetailList.trailerInterchangeIndicatorModal.modal" value="Yes"
                                        class="radio_button" inputId="Yes" required>
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblTrailerIntYes" id="lblTrailerIntYesID">Yes</label>
                                    <p-radioButton name="radTrailerInterchangeIndicator" id="radTrailerInterchangeParenNoID"
                                        [(ngModel)]="policyHolderData.DAODetailList.trailerInterchangeIndicatorModal.modal" value="No"
                                        class="radio_button" inputId="No" required>
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblTrailerIntNo" id="lblTrailerIntNoID">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row m-0">
                    <h5 class="mt-4 pt-3 ml-lg-3  ">Commodities</h5>
                </div>
                <form #com="ngForm" style="width:100%" (ngSubmit)="AddCommodities(com)">
                    <div class="row mt-3">
                        <div class="col-lg-12" *ngIf="policyHolderData.DAODetailList.commodityType1Modal.isShow">
                            <div class="wid_80">
                                <div class="wid_18 text-right"
                                    *ngIf="!policyHolderData.DAODetailList?.commodityType1Modal?.IsHidden">
                                    <label name="lblCommodities1" id="lblCommoditiesID1">Commodities:
                                        <span style="color: red;">{{policyHolderData.DAODetailList?.commodityType1Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div class="wid_31 ipad_65" *ngIf="!policyHolderData.DAODetailList?.commodityType1Modal?.IsHidden">
                                    <input class="form-control" disabled
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityType1Modal.modal" type="text"
                                        name="commodity1" id="commodity1ID" #commodity1="ngModel">
                                </div>
                                <div class="wid_15 text-right"
                                    *ngIf="!policyHolderData.DAODetailList?.commodityPercent1Modal?.IsHidden">
                                    <label name="lblCommodityPercent1" id="lblCommodityPercent1ID">Commodities %:
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList?.commodityPercent1Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div class="{{((f.submitted || dao?.isSubmitForm) && (!commodityPercent1?.valid || (policyHolderData.DAODetailList?.commodityPercent1Modal?.IsRequired && policyHolderData.DAODetailList.commodityPercent1Modal.modal == ''))) || ((com.submitted || dao?.isSubmitForm) && (!commodityPercent1?.valid || policyHolderData.DAODetailList.commodityPercent1Modal.modal == ''  )) || dao?.IsValidCommodityPercent || (policyHolderData.DAODetailList.commodityPercent1Modal.modal < MiscCommodityMinPercentage)  ? 'wid_31 ipad_65 validate_error_cls' : 'wid_31 ipad_65'}}"
                                    *ngIf="!policyHolderData.DAODetailList?.commodityPercent1Modal?.IsHidden">
                                    <input type="text" (keypress)="keydown($event,1)"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityPercent1Modal.modal"
                                        name="commodityPercent1" id="commodityPercent1ID" #commodityPercent1="ngModel"
                                        maxlength="3" [minAmt]="MiscCommodityMinPercentage"
                                        [required]="policyHolderData.DAODetailList?.commodityPercent1Modal?.IsRequired"
                                        class="form-control"
                                        [class.form-control_Overide]="policyHolderData.DAODetailList.commodityPercent1Modal?.IsOverride">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="policyHolderData.DAODetailList.commodityType2Modal.isShow">
                        <div class="col-lg-12">
                            <div class="wid_80">
                                <div class="wid_18 text-right">
                                    <label name="lblCommodityType2" id="lblCommodityType2ID">Commodities:
                                        <span style="color: red;">{{policyHolderData.DAODetailList?.commodityType2Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,2,'commodity')">
                                    <p-dropdown placeholder={{policyHolderData.DAODetailList?.commodityType2Modal?.placeHolder}}
                                        [options]="policyHolderData.DAODetailList?.commodityType1Modal?.dropdown"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityType2Modal.modal" name="commodity2"
                                        id="commodity2ID" #commodity2="ngModel" [filter]="true"
                                        [required]="policyHolderData.DAODetailList?.commodityType2Modal?.IsRequired"
                                        (onChange)="validationMsgForCommodity()">
                                    </p-dropdown>
                                </div>
                                <div class="wid_15 text-right">
                                    <label name="lblcommodityPercent2" id="lblcommodityPercent2ID">Commodities %:
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList?.commodityPercent2Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,2,'commodityPercent')">
                                    <input type="text" (keypress)="keydown($event,2)"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityPercent2Modal.modal"
                                        name="commodityPercent2" id="commodityPercent2ID" #commodityPercent2="ngModel"
                                        maxlength="3"
                                        [required]="policyHolderData.DAODetailList?.commodityPercent2Modal?.IsRequired"
                                        class="form-control"
                                        [class.form-control_Overide]="policyHolderData.DAODetailList.commodityPercent2Modal?.IsOverride">
                                </div>
                            </div>
                            <div class="wid_18">
                                <a>
                                    <i class="fa fa-trash delete_icon" title="delete"
                                        (click)="deleteCommodityPopup('2')"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="policyHolderData.DAODetailList.commodityType3Modal.isShow">
                        <div class="col-lg-12">
                            <div class="wid_80">
                                <div class="wid_18 text-right">
                                    <label name="lblcommodityType3" id="lblcommodityType3ID">Commodities:
                                        <span style="color: red;">{{policyHolderData.DAODetailList?.commodityType3Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,3,'commodity')">
                                    <p-dropdown placeholder={{policyHolderData.DAODetailList?.commodityType3Modal?.placeHolder}}
                                        [options]="policyHolderData.DAODetailList?.commodityType1Modal?.dropdown"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityType3Modal.modal" name="commodity3"
                                        id="commodity3ID" #commodity3="ngModel" [filter]="true"
                                        [required]="policyHolderData.DAODetailList?.commodityType3Modal?.IsRequired"
                                        (onChange)="validationMsgForCommodity()">
                                    </p-dropdown>
                                </div>
                                <div class="wid_15 text-right">
                                    <label name="lblcommodityPercent3" id="lblcommodityPercent3ID">Commodities %:
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList?.commodityPercent3Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,3,'commodityPercent')">
                                    <input type="text" (keypress)="keydown($event,3)"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityPercent3Modal.modal"
                                        name="commodityPercent3" id="commodityPercent3ID" #commodityPercent3="ngModel"
                                        maxlength="3"
                                        [required]="policyHolderData.DAODetailList?.commodityPercent3Modal?.IsRequired"
                                        class="form-control"
                                        [class.form-control_Overide]="policyHolderData.DAODetailList.commodityPercent3Modal?.IsOverride">
                                </div>
                            </div>
                            <div class="wid_18">
                                <a>
                                    <i class="fa fa-trash delete_icon" title="delete"
                                        (click)="deleteCommodityPopup('3')"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="policyHolderData.DAODetailList.commodityType4Modal.isShow">
                        <div class="col-lg-12">
                            <div class="wid_80">
                                <div class="wid_18 text-right">
                                    <label name="lblcommodityType4" id="lblcommodityType4ID">Commodities:
                                        <span style="color: red;">{{policyHolderData.DAODetailList?.commodityType4Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,4,'commodity')">
                                    <p-dropdown placeholder={{policyHolderData.DAODetailList?.commodityType4Modal?.placeHolder}}
                                        [options]="policyHolderData.DAODetailList?.commodityType1Modal?.dropdown"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityType4Modal.modal" name="commodity4"
                                        id="commodity4ID" #commodity4="ngModel" [filter]="true"
                                        [required]="policyHolderData.DAODetailList?.commodityType4Modal?.IsRequired"
                                        (onChange)="validationMsgForCommodity()">
                                    </p-dropdown>
                                </div>
                                <div class="wid_15 text-right">
                                    <label name="lblcommodityPercent4" id="lblcommodityPercent4ID">Commodities %:
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList?.commodityPercent4Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,4,'commodityPercent')">
                                    <input type="text" (keypress)="keydown($event,4)"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityPercent4Modal.modal"
                                        name="commodityPercent4" id="commodityPercent4ID" #commodityPercent4="ngModel"
                                        maxlength="3"
                                        [required]="policyHolderData.DAODetailList?.commodityPercent4Modal?.IsRequired"
                                        class="form-control"
                                        [class.form-control_Overide]="policyHolderData.DAODetailList.commodityPercent4Modal?.IsOverride">
                                </div>
                            </div>
                            <div class="wid_18">
                                <a>
                                    <i class="fa fa-trash delete_icon" title="delete"
                                        (click)="deleteCommodityPopup('4')"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="policyHolderData.DAODetailList.commodityType5Modal.isShow">
                        <div class="col-lg-12">
                            <div class="wid_80">
                                <div class="wid_18 ipad_28 text-right">
                                    <label name="lblcommodityType5" id="lblcommodityType5ID">Commodities:
                                        <span style="color: red;">{{policyHolderData.DAODetailList?.commodityType5Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,5,'commodity')">
                                    <p-dropdown placeholder={{policyHolderData.DAODetailList?.commodityType5Modal?.placeHolder}}
                                        [options]="policyHolderData.DAODetailList?.commodityType1Modal?.dropdown"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityType5Modal.modal" name="commodity5"
                                        id="commodity5ID" #commodity5="ngModel" [filter]="true"
                                        [required]="policyHolderData.DAODetailList?.commodityType5Modal?.IsRequired"
                                        (onChange)="validationMsgForCommodity()">
                                    </p-dropdown>
                                </div>
                                <div class="wid_15 ipad_28 text-right">
                                    <label name="lblcommodityPercent5" id="lblcommodityPercent5ID">Commodities %:
                                        <span
                                            style="color: red;">{{policyHolderData.DAODetailList?.commodityPercent5Modal?.IsRequired
                                            ? '*' : ''}}</span></label>
                                </div>
                                <div [ngClass]="commodityClassValidator(f,com,5,'commodityPercent')">
                                    <input type="text" (keypress)="keydown($event,5)"
                                        [(ngModel)]="policyHolderData.DAODetailList.commodityPercent5Modal.modal"
                                        name="commodityPercent5" id="commodityPercent5ID" #commodityPercent5="ngModel"
                                        maxlength="3"
                                        [required]="policyHolderData.DAODetailList?.commodityPercent5Modal?.IsRequired"
                                        class="form-control"
                                        [class.form-control_Overide]="policyHolderData.DAODetailList.commodityPercent5Modal?.IsOverride">
                                </div>                            
                            </div>
                            <div class="wid_18">
                                <a>
                                    <i class="fa fa-trash delete_icon" title="delete"
                                        (click)="deleteCommodityPopup('5')"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="wid_80">
                                <div class="wid_40 text-right" style="margin-top: 5px;" *ngIf="totalCommodityCount<5">
                                    <button class="addcomm" pButton type="submit" title="Add Additional Commodity"
                                        label="Add Additional Commodity" name="btnAddAdditionalCommodity"
                                        id="btnAddAdditionalCommodityID"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="wid_80">
                                <div class="wid_40 text-left"
                                    *ngIf="commodityValidationFlags.sumNotEquals100 && !commodityValidationFlags.SumNotMoreThan100">
                                    <label class="error_msg" name="lblerror1" id="lblerror1ID">
                                        Sum of commodities percent should be equal to 100
                                    </label>
                                </div>
                                <div class="wid_40 text-left"
                                    *ngIf="commodityValidationFlags.SumNotMoreThan100">
                                    <label class="error_msg" name="lblerror2" id="lblerror2ID">
                                        Sum of commodities percent should not be more than 100
                                    </label>
                                </div>
                                <div class="wid_40 text-left"
                                    *ngIf="(com.submitted || !com.controls.commodityPercent1?.pristine) && com.controls.commodityPercent1?.errors?.minAmt">
                                    <label class="error_msg" name="lblerror1" id="lblerror1ID">
                                        OTHER MISC. commodities % cannot be less than {{MiscCommodityMinPercentage}}%
                                    </label>
                                </div>
                                <div class="wid_40 text-left" *ngIf="commodityValidationFlags.hasDuplicate">
                                    <label class="error_msg" name="lblerror1" id="lblerror1ID">
                                        Duplicate commodities cannot be added
                                    </label>
                                </div>
                                <!--InEligible commodity label-->
                                <div class="wid_40 text-left" *ngIf="!commodityValidationFlags.isEligible">
                                    <label class="error_msg color_maroon" name="lblerror1" id="lblerror1ID">
                                        Ineligible commodities selected, UW review is required
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row m-0">
                    <div class="container d-flex">
                        <div class="col-lg-9"></div>
                        <div class="col-lg-3 p-0 mt-3">
                            <span class="text-lg-right float-lg-right">
                                <button type="submit" class="btnsuccess" title="Save & Continue" name="btnsaveContinue"
                                    id="btnsaveContinueID" data-toggle="modal">
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    Save & Continue
                                </button>  
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </form>

    </div>
    <p-toast key="myKey1" position="top-center" (onClose)="onReject('myKey1')"></p-toast>
    <p-toast position="center" key="removeContact" (onClose)="onReject('removeContact')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="removeContact()" label="Yes" class="p-button-success"></button> -->
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnremoveContactYes"
                            id="btnremoveContactYesID" (click)="removeContact()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="onReject('removeContact')" label="No" class="p-button-secondary"></button> -->
                        <button type="button" class="warnBtnerror" title="No" name="btnremoveContactNo"
                            id="btnremoveContactNoID" (click)="onReject('removeContact')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="CancelmySubmission" (onClose)="onReject('CancelmySubmission')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="CancelmySubmission()" label="Yes" class="p-button-success"></button> -->
                        <button type="button" class="warnBtnSuccess float-right" title="Yes"
                            name="btnCancelmySubmissionYes" id="btnCancelmySubmissionID" (click)="CancelmySubmission()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="onReject('CancelmySubmission')" label="No" class="p-button-secondary"></button> -->
                        <button type="button" class="warnBtnerror" title="No" name="btnCancelmySubmissionNo"
                            id="btnCancelmySubmissionNoID" (click)="onReject('CancelmySubmission')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="DeleteCommodity" (onClose)="onReject('DeleteCommodity')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="deleteCommodity()" label="Yes" class="p-button-success"></button> -->
                        <button type="button" class="warnBtnSuccess float-right" title="Yes"
                            name="btnDeleteCommodityYes" id="btnDeleteCommodityYesID" (click)="deleteCommodity()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <!-- <button type="button" pButton (click)="onReject('DeleteCommodity')" label="No" class="p-button-secondary"></button> -->
                        <button type="button" class="warnBtnerror" title="No" name="btnDeleteCommodityNo"
                            id="btnDeleteCommodityNoID" (click)="onReject('DeleteCommodity')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="DeleteDot" (onClose)="onReject('DeleteDot')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes"
                            name="btnremoveAdditionalDOTYes" id="btnremoveAdditionalDOTYesID"
                            (click)="removeAdditionalDOT()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnremoveAdditionalDOTNo"
                            id="btnremoveAdditionalDOTNoID" (click)="onReject('DeleteDot')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>